import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {UserFields} from "./UserFields";
import API from "../../utils/API";


var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class CreateUser extends React.Component {
    state = {
        userData:{
            success:'',
            error:''
        }
    };

    constructor(props) {
        super(props);
        this.saveData = this.saveData.bind(this);
    }

    onChange = () => {
        // this.setState({
        //     submitDisabled:false
        // });
    }

    saveData = function (data) {
        let userData = this.state.userData;
        userData[data.key]=data.value;
        this.setState({userData: userData});
    };

    getData = () => {
        return this.state.userData;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        var api = new BackofficeDansTousLesSens.UserApi()
        var user = new BackofficeDansTousLesSens.User(); // {User} User properties
        user.nom = this.state.userData.nom;
        user.prenom = this.state.userData.prenom;
        user.login = this.state.userData.login;
        user.email = this.state.userData.email;
        user.password = this.state.userData.password;
        user.programmateur = this.state.userData.programmateur;
        user.gestionnaire = this.state.userData.gestionnaire;


        console.debug("user:", user);

        const callbackSucess =  async (data) => {
            let userData = {
                success:'Utilisateur créé avec succès!',
                error:''
            }

            this.setState({userData: userData});
            console.debug('API called successfully. Returned data: ' , data);
        }

        const callbackError =  async (data) => {
            let userData = this.state.userData;
            userData.success='';
            userData.error='Impossible de créer l\'utilisateur';

            this.setState({userData: userData});
            console.error(data);
        }

        api.create(user).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    componentDidMount() {
        API.addConfSecurity();
    }

    render() {
        const {
           userData
        } = this.state;
        return (

                <Row>
                    <Col lg={12}>
                        <hr className="hr-1"></hr>
                        <Jumbotron>
                            <h1 className="display-4 font-weight-normal">Création d'utilisateur</h1>
                        </Jumbotron>
                            {userData.success.length > 0 &&
                            <Alert key={"danger"} variant={"success"}>
                                {userData.success}
                            </Alert>
                            }
                            {userData.error.length > 0 &&
                            <Alert key={"dangerError"} variant={"danger"}>
                                {userData.error}
                            </Alert>
                            }
                        <Form onSubmit={this.handleSubmit}>
                            <UserFields saveData={this.saveData} change={this.onChange} dataStore={this.getData} />
                            <Button variant="primary" type="submit"
                                    disabled={userData.error.length > 0 || userData.password_has_error}>
                                Créer l'utilisateur
                            </Button>
                        </Form>

                    </Col>
                </Row>

        );
    }
}