import React from "react";

import {Alert, Button, Form} from "react-bootstrap";
import API from "../../utils/API";
import App from "../../App";

export class Login extends React.Component {
    state = {
        identifiant: "",
        password: "",error:''
    };
    send = async () => {
        const {identifiant, password} = this.state;
        if (!identifiant || identifiant.length === 0) {
            return;
        }
        if (!password || password.length === 0) {
            return;
        }
        try {
            const data = await API.login(identifiant, password);
            localStorage.setItem("token", data.token);
            window.location = "/";
           //this.setState({redirect:"/"});
        } catch (error) {
            this.setState({error:"Utilisateur inconnu!"});
            console.error(error);
        }

    };
    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
            redirect:null
        });
    };

    render() {
        const {identifiant, password,redirect,error} = this.state;
        if (redirect) {
            return <App refreshTime={Date.now()}/>
        }
        return (
            <div className="Login">
                {error.length>0 &&
                <Alert key={"danger"} variant={"danger"}>
                    {error}
                </Alert>
                }
                <Form.Group controlId="identifiant">
                    <Form.Label>Identifiant</Form.Label>
                    <Form.Control
                        autoFocus
                        type="identifiant"
                        value={identifiant}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        value={password}
                        onChange={this.handleChange}
                        type="password"
                    />
                </Form.Group>
                <Button onClick={this.send} block type="submit">
                    Connexion
                </Button>
            </div>
        );
    }
}