import React from "react";
import {UserFields} from "../User/UserFields";
import Form from "react-bootstrap/Form";
import {FormLieu} from "../Lieux/FormLieu";
import {FormProg} from "./FormProg";
import {Alert} from "react-bootstrap";

export class Step2 extends React.Component {
    state ={
        error:""
    }
    constructor(props) {
        super(props);
        this.state = props.getStore();
        this._validateOnDemand = true;
        //this.refs.user.checkDatas=this.refs.user.checkDatas.bind(this);
        //this.goToNext = this.goToNext.bind(this);
       // this.checkDatas = this.checkDatas.bind(this);

        //On bind car appelé depuis un autre Component
        this.saveData=this.saveData.bind(this);
    }

    saveData = function (data){
        console.debug("Saving data...",data);
        let store = this.props.getStore();
        store[1]=data;
        this.props.updateStore({
            ...store,
            savedToCloud: false
        });
    };

    isValidated() {
        if (!this.props.getStore()[1] ){
            this.setState({error: "Veuillez remplir l'adresse "})
            return false;
        }
        if (!this.props.getStore()[1].adresse || this.props.getStore()[1].adresse.length == 0) {
            this.setState({error: "Veuillez saisir une adresse valide"})
            return false;
        }
        if (!this.props.getStore()[1].codePostal || this.props.getStore()[1].codePostal.length == 0) {
            this.setState({error: "Veuillez saisir un code postal valide"})
            return false;
        }
        if (!this.props.getStore()[1].ville || this.props.getStore()[1].ville.length == 0) {
            this.setState({error: "Veuillez saisir une ville valide"})
            return false;
        }
        if (!this.props.getStore()[1].tel || this.props.getStore()[1].tel.length == 0) {
            this.setState({error: "Veuillez saisir un tél valide"})
            return false;
        }
        if (!this.props.getStore()[1].email || this.props.getStore()[1].email.length == 0) {
            this.setState({error: "Veuillez saisir un mail valide"})
            return false;
        }

        return true;
    }

    render() {
        const {error}=this.state;
        return (
            <Form>
                {error && error.length > 0 &&
                <Alert key={"dangerError"} variant={"danger"}>
                    {error}
                </Alert>
                }
                <FormLieu as={"create"} onChange={this.saveData} data={this.props.getStore()[1]} key={"setp2-formprog"} hideFields={(!this.props.getStore()[0]||this.props.getStore()[0].typeLieu==undefined|| !this.props.getStore()[0].typeLieu)?["trajetDescription","presentation","personneContact"]:[]}/>
            </Form>
        )
    }
}