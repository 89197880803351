import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { CreateUser } from "./components/User/CreateUser.js";
import { CreateProg } from "./components/Prog/CreateProg.js";
import { PrivateRoute } from "./components/PrivateRoute.js";
import "./App.css";
import {Navigationbar} from "./components/Navbar/Navigationbar";
import {NavigationbarUser} from "./components/Navbar/NavigationbarUser";
import {Container,ProgressBar,Modal} from "react-bootstrap";
import {EditUser} from "./components/User/EditUser";
import {CreateLieu} from "./components/Lieux/CreateLieu";
import {EditLieu} from "./components/Lieux/EditLieu";
import {CatalogueLieux} from "./components/Catalogue/CatalogueLieux";
import {CatalogueSpecs} from "./components/Catalogue/CatalogueSpecs";
import {Catalogue} from "./components/Catalogue/Catalogue";
import {EditSpec} from "./components/Spec/EditSpec";
import {EditProg} from "./components/Prog/EditProg";
import API from "./utils/API";
import {Welcome} from "./components/Welcome/Welcome";
import {CreateSpec} from "./components/Spec/CreateSpec";
import {Footer} from "./components/Footer/Footer";
import {Assistant} from "./components/Prog/Assistant";
import {AssistantEvt} from "./components/evenement/Assistant";
import {EditEvenement} from "./components/evenement/EditEvenement";
import {CreateRessource} from "./components/Ressources/CreateRessource";
import {EditRessource} from "./components/Ressources/EditRessource";
import {ViewRessources} from "./components/Ressources/ViewRessources";
import {WelcomeContributeur} from "./components/Welcome/WelcomeContributeur";
import {Login} from "./components/Login/Login";
import logo from "./logo.png";

class App extends Component {
    state = {show:false,showNetworkError:false,messageError:''}

    UNSAFE_componentWillReceiveProps(props) {
        const { refreshTime } = this.props;
        if (props.refreshTime) {
            this.setState({refreshTime:refreshTime});
        }
    }

    progress = ()=>{

        let changeStateShow = (show,eventNetwork)=>{
            if(eventNetwork)console.debug("eventNetwork:",eventNetwork)
            if(!this.state.show && show || this.state.show && !show) this.setState({show:show})

            if(eventNetwork && eventNetwork.status!==200){
                console.debug("error:",eventNetwork)
                this.setState({showNetworkError:true,messageError:eventNetwork.statusText})
            }
        }

        let oldXHROpen = window.XMLHttpRequest.prototype.open;
        window.XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
            console.debug("method",method)
            console.debug("url",url)
            // do something with the method, url and etc.
            this.addEventListener("progress",function updateProgress (oEvent) {
                //changeStateShow( true )
            })
            this.addEventListener("loadstart",function updateProgress (oEvent) {
                console.debug("loadstart")
                changeStateShow( true )
            })
            this.addEventListener('loadend', function(oEvent) {
                console.debug("loadend")
                changeStateShow(false)
            });
            this.addEventListener('load', function(oEvent) {
                console.debug("load")
                changeStateShow(false)
            });
            this.addEventListener("error", function(oEvent) {
                console.debug("error")
                changeStateShow(false,oEvent)
            }, false);
            this.addEventListener("abort", function(oEvent) {
                console.debug("abort")
                changeStateShow(false)
            }, false);

            //Upload
            this.upload.addEventListener("progress", function updateProgress (oEvent) {
                console.debug("progress")
                changeStateShow( true )
            }, false);
            this.upload.addEventListener("load", function(oEvent) {
                changeStateShow(false)
            }, false);
            this.upload.addEventListener("error", function(oEvent) {
                changeStateShow(false)
            }, false);
            this.upload.addEventListener("abort", function(oEvent) {
                changeStateShow(false)
            }, false);
            return oldXHROpen.apply(this, arguments);
        }
    }

    render() {
        this.progress();
        const {show,showNetworkError,messageError} = this.state;
        return (
            <div className="App" key={"principal-div-app"}>
                <Modal
                    show={show}
                    dialogClassName="modal-90w"
                >
                    <Modal.Body>
                        <ProgressBar label={`chargement ...`} animated={true} now={100}/>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showNetworkError}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Erreur réseau</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{messageError}</p>
                    </Modal.Body>
                </Modal>
                <div className="App-content" key={"app-content"}>
                    {!API.isAuth() && <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo"/>
                    </header>}
                    {!API.isAuth() && <Login/>}
                    {API.isAuth() && API.getRole() === 'admin' && <Navigationbar/>}
                    {API.isAuth() && API.getRole() === 'user' && <NavigationbarUser/>}
                    <Container fluid key={"container"}>
                        <Switch key={"choix-route"}>
                            <PrivateRoute exact path="/user/add" component={CreateUser} key={"route-user-add"}/>
                            <PrivateRoute exact path="/user/edit" component={EditUser}  key={"route-user-edit"}/>
                            <PrivateRoute exact path="/prog/add" component={Assistant} key={"route-prog-add"}/>
                            <PrivateRoute exact path="/prog/edit" component={EditProg}  key={"route-prog-edit"}/>
                            <PrivateRoute exact path="/lieu/add" component={CreateLieu} key={"route-lieu-add"}/>
                            <PrivateRoute exact path="/lieu/edit" component={EditLieu}  key={"route-lieu-edit"}/>
                            <PrivateRoute exact path="/lieu/catalogue" component={CatalogueLieux}  key={"route-lieu-catalogue"}/>
                            <PrivateRoute exact path="/spec/add" component={CreateSpec} key={"route-spec-add"}/>
                            <PrivateRoute exact path="/spec/edit" component={EditSpec}  key={"route-spec-edit"}/>
                            <PrivateRoute exact path="/spec/catalogue" component={CatalogueSpecs}  key={"route-spec-catalogue"}/>
                            <PrivateRoute exact path="/evt/add" component={AssistantEvt}  key={"route-evt-add"}/>
                            <PrivateRoute exact path="/evt/edit" component={EditEvenement}  key={"route-evt-edit"}/>
                            <PrivateRoute exact path="/res/add" component={CreateRessource} key={"route-res-add"}/>
                            <PrivateRoute exact path="/res/edit" component={EditRessource}  key={"route-res-edit"}/>
                            <PrivateRoute exact path="/res/view" component={ViewRessources}  key={"route-res-view"}/>
                            <PrivateRoute exact path="/catalogue" component={Catalogue}  key={"route-catalogue"}/>
                            {API.isAuth() && API.getRole()==='admin' &&
                            <Route path='/' component={Welcome} />}
                            {API.isAuth() && API.getRole()==='user' &&
                            <Route path='/' component={WelcomeContributeur} />}
                        </Switch>
                    </Container>
                    {API.isAuth() && <Footer/>}
                </div>
            </div>
        );
    }
}
export default App;