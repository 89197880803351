import React from "react";
import {Form, Accordion, Card, Row, Col, Button, ListGroup} from "react-bootstrap";
import API from "../../utils/API";
import PropTypes from 'prop-types'
import {Documents} from "../Utils/Documents";
import {Ressource} from "@singuliers/backoffice_dans_tous_les_sens";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiRessource = new BackofficeDansTousLesSens.RessourceApi();
const apiDocument = new BackofficeDansTousLesSens.DocumentApi();

export class FormRessource extends React.Component {

    static propTypes = {

        /**
         * Callback on change data
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Data of ressource if EditMode
         */
        data: PropTypes.object,
        /**
         * Edit or Create Mode
         */
        as: PropTypes.oneOf(['edit', 'create']).isRequired,
        /**
         * Parent id
         */
        parentId: PropTypes.string,
        /**
         * Set hide fields
         */
        hideFields: PropTypes.array
    }
    state = {
        countMaxCharDescription: 0,
        ressourceData: {documents: [], liens: []}
    };

    documents = [];
    liens = [];

    onDocumentChange = (document) => {
        try {
            API.addConfSecurity();
            if (this.props.as === 'edit') {
                const callbackSucessDocument = async (data) => {
                    const ressource = await apiRessource.getOneRessource(this.state.ressourceData.id);
                    this.setState({error: '', success: 'Document ajouté!', ressourceData: ressource});
                }
                const callbackError = async (data) => {
                    this.setState({error: 'Impossible d\'ajouter le document!', success: ''});
                }
                apiRessource.uploadDocument(this.state.ressourceData.id, document).then(
                    function (data) {
                        callbackSucessDocument(data);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } else {
                this.documents.push(document);
                let ressource = this.state.ressourceData;
                ressource.documents = this.documents;
                this.setState({ressourceData: ressource}, () => this.props.onChange(ressource));
            }
        } catch
            (error) {
            console.error("ERROR: ", error);
            if (error.message)
                alert(error.message)
        }
    }

    onChangeAltDocument = async (id, documentUpdate) => {
        API.addConfSecurity();
        try {
            if (this.props.as === 'edit') {
                let updateObject = new BackofficeDansTousLesSens.DocumentRessourceUpdate();
                updateObject.id = documentUpdate.id;
                updateObject.titre = documentUpdate.titre;
                updateObject.texteAlternatif = documentUpdate.texteAlternatif;
                updateObject.description = documentUpdate.description;
                console.debug("before update document alt:", updateObject)
                await apiDocument.updateDocument(this.state.ressourceData.id, "RESSOURCE", updateObject);
            } else {
                let ressource = this.state.ressourceData;
                this.documents.map((document, index) => {
                        if (document.id === id) {
                            this.documents.splice(index, 1);
                        }
                        return true
                    }
                );
                this.documents.push(documentUpdate);
                ressource.documents = this.documents;
                this.setState({ressourceData: ressource}, () => this.props.onChange(ressource));
            }
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    onDocumentDelete = async (documentId) => {
        API.addConfSecurity();
        try {
            if (this.props.as === "edit") {
                const callbackSucessDocument = async (data) => {
                    const ressource = await apiRessource.getOneRessource(this.state.ressourceData.id);
                    this.setState({error: '', success: 'Document supprimé!', ressourceData: ressource});
                    console.debug("ressource after delete picture:", ressource);
                }
                const callbackError = async (data) => {
                    this.setState({error: 'Impossible de supprimer le document!', success: ''});
                }

                let deleteObject = new BackofficeDansTousLesSens.DocumentRessourceDelete();
                deleteObject.id = documentId;
                apiRessource.deleteDocument(this.state.ressourceData.id, deleteObject).then(
                    function (data) {
                        callbackSucessDocument(data);
                    },
                    function (error) {
                        callbackError(error);
                    });
            }

            this.documents.map((document, index) => {
                    if (document.id === documentId) {
                        this.documents.splice(index, 1);
                    }
                    return true
                }
            );
            let ressource = this.state.ressourceData;
            ressource.documents = this.documents;
            this.setState({ressourceData: ressource}, () => this.props.onChange(ressource));
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    handleChange = (e) => {
        let {id, value} = e.target;
        let countMaxCharDescription = this.state.countMaxCharDescription;
        let ressourceData = this.state.ressourceData;
        console.debug("handlechange: ", id, value, e);
        if (id === 'document') {
            value = e.target.files[0];
        } else if (id.startsWith('diffusion')) {
            ressourceData[id] = e.target.checked;
        } else if (id.startsWith('ulien')) {
            let index = id.split('n')[1]
            console.debug("ID : ", ressourceData['liens'])
            ressourceData['liens'][index] = value;
        } else if (id.startsWith('lientitre')) {
            let index = id.split('X')[1]
            console.debug("ID : ", ressourceData['liens'])
            ressourceData.liens[index].titre = value;
        } else if (id.startsWith('lienadresse')) {
            let index = id.split('X')[1]
            ressourceData.liens[index].adresse = value;
        } else if (id === 'description') {
            countMaxCharDescription = value.length
            ressourceData[id] = value;
        } else {
            ressourceData[id] = value;
        }
        console.debug("resourceData", ressourceData);
        this.setState({
                ressourceData: ressourceData,
                countMaxCharDescription: countMaxCharDescription,
            }, () => this.props.onChange(ressourceData)
        );
        //this.callbackChange();
    };

    handleClickAddRessource = () => {
        if (this.props.data) {
            let ressourceData = this.props.data;
            ressourceData['liens'][ressourceData.liens.length] = {titre: "", adresse: ""};
            this.setState({ressourceData: ressourceData})
        } else {
            let ressource = this.state.ressourceData;
            ressource.liens = this.liens;
            ressource['liens'][this.liens.length] = {titre: "", adresse: ""};
            this.setState({ressourceData: ressource}, () => this.props.onChange(ressource));
        }
    }

    handleClickDeleteLink = (index) => {
        let ressourceData;
        if (this.props.data)
            ressourceData = this.props.data;
        else
            ressourceData = this.state.ressourceData;

        if (window.confirm(`Êtes vous sûr de vouloir supprimer le lien ${ressourceData['liens'][index]['titre']} ?`)) {
            try {
                ressourceData['liens'].splice(index, 1);
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
        this.setState({
                ressourceData: ressourceData,
            }, () => this.props.onChange(ressourceData)
        );
    }

    componentDidMount() {
        API.addConfSecurity();

        if (this.props.data) {
            let ressourceData = this.props.data;
            if (!ressourceData.documents) ressourceData.documents = []
            if (!ressourceData.liens) ressourceData.liens = []
            this.setState({ressourceData: ressourceData})
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data && (!state.ressourceData || props.data.id !== state.ressourceData.id)) {
            return {
                ressourceData: props.data
            };
        }

        return null;
    }


    render() {
        const {
            countMaxCharDescription, ressourceData
        } = this.state;

        return (

            <>
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("titre") < 0)) &&
                    <Form.Group controlId="titre">
                        <Form.Label>Titre</Form.Label>
                        <Form.Control required placeholder="Titre de la ressource"
                                      onChange={this.handleChange} value={ressourceData.titre}/>
                    </Form.Group>
                }
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("description") < 0)) &&
                    <Form.Group controlId="description">
                        <Form.Label id={"description"}>Description ({countMaxCharDescription}/1000)</Form.Label>
                        <Form.Control as="textarea" rows={3} maxLength="1000"
                                      placeholder="Description"
                                      value={(ressourceData.description ? ressourceData.description : "")}
                                      onChange={this.handleChange}/>
                    </Form.Group>
                }
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Diffusion
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Form.Group id="diffusion" as={Row}>
                                <Form.Label column lg={7}>Front : </Form.Label>
                                <Form.Check type="checkbox" name="diffusionFront"
                                            id="diffusionFront"
                                            onChange={this.handleChange}
                                            defaultChecked={this.state.ressourceData.diffusionFront}/>

                                <Form.Label column lg={7}>BackOffice (User) : </Form.Label>
                                <Form.Check type="checkbox" name="diffusionBackoffice"
                                            id="diffusionBackoffice"
                                            onChange={this.handleChange}
                                            defaultChecked={this.state.ressourceData.diffusionBackoffice}/>
                            </Form.Group>
                        </Accordion.Collapse>
                    </Card>
                    <hr className="hr-1"></hr>
                </Accordion>
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("liens") < 0)) &&
                    <Form.Group>
                        <Form.Label>Liens</Form.Label>
                        <Button variant="primary" type="button" onClick={this.handleClickAddRessource}>
                            Ajouter un lien
                        </Button>

                        {this.state.ressourceData.liens.map((link, index) =>
                            <ListGroup.Item key={"lien" + index}>
                                <div className={'row'}>
                                    <div className={'col-sm-offset-1'}><Button variant="danger" size={"sm"}
                                                                               onClick={() => {
                                                                                   this.handleClickDeleteLink(index)
                                                                               }}>X</Button></div>
                                    <div className={'col-sm-7'}><Form.Control required
                                                                              placeholder="titre"
                                                                              id={"lientitreX" + index}
                                                                              onChange={this.handleChange}
                                                                              value={link.titre}/>
                                    </div>
                                    <div className={'col-sm-3'}><Form.Control type={'url'} required
                                                                              placeholder="ex: https://dtls2.org/presentation"
                                                                              id={"lienadresseX" + index}
                                                                              onChange={this.handleChange}
                                                                              value={link.adresse}/>
                                    </div>
                                    <div className={'col-sm-offset-1'}><Button variant="info" size={"sm"}
                                                                               onClick={() => window.open(link.adresse, "_blank", "noreferrer")
                                                                               }>test</Button></div>
                                </div>
                            </ListGroup.Item>
                        )}
                    </Form.Group>
                }
                {
                    <Documents initDocuments={ressourceData.documents} onChange={this.onDocumentChange}
                               onDelete={this.onDocumentDelete}
                               masterKey={this.props.as === "edit" ? this.props.data.id : "new"}
                               onChangeAltTexte={this.onChangeAltDocument}/>
                }
            </>

        );
    }
}