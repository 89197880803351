import React from "react";

import {Image} from "react-bootstrap";
import {Redirect} from "react-router";
import logo from "../../logo-sa.png";

var style = {
    backgroundColor: "#f8f9fa",
    textAlign: "center",
    padding: "5px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "40px",
    width: "100%",
    fontSize: "0.75em"
};


export class Footer extends React.Component {
    state = {
        redirect: null,
        version: process.env.REACT_APP_VERSION
    };

    render() {
        const {
            redirect
        } = this.state;
        if (redirect) {
            return <Redirect to={this.state.redirect} />
        }


        return (
                <div>
                    <hr className="hr-1"></hr>
                    <hr className="hr-1"></hr>
                    <hr className="hr-1"></hr>
                    <footer style={style}>
                        <div className="container" height={"40px"}>
                            <Image src={logo} height={"30px"} />
                            <span className="text-muted"> version {this.state.version}</span>
                        </div>
                    </footer>
                </div>
        );
    }
}