import React from "react";
import API from "../../utils/API";
import {Login} from "../Login/Login";
import {Col, Row, Card, Image, Container, Badge} from "react-bootstrap";
import logo from "../../logo.png";
import imagehome from "../../logo-dtls.svg";

import Alert from "react-bootstrap/Alert";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class Welcome extends React.Component {
    state = {
        stats: [],
        labels: {
            lastMonthCount: "au cours des 30 derniers jours",
            notValidated: 'non validés'
        }
    }


    componentDidMount() {
        if (!API.isAuth()) return;
        var dashboardApi = new BackofficeDansTousLesSens.DashboardApi();
        API.addConfSecurity();


        const stats = async () => {
            try {
                var data = await dashboardApi.getStats();
                let deployerApi = new BackofficeDansTousLesSens.DeployerApi();
                let deployments = await deployerApi.listDeployments();
                if (data) {
                    console.debug("stats", data);
                    this.setState({stats: data, deployments: deployments});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }

        };
        stats();

    }

    async loadHistoryDeployments() {
        if (!API.isAuth()) return;
        let deployerApi = new BackofficeDansTousLesSens.DeployerApi();
        let deployments = await deployerApi.listDeployments();
        this.setState({deployments: deployments})
    }

    render() {
        const {stats, labels, deployments} = this.state;
        return (<>
            {!API.isAuth() && <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
            </header>}
            {!API.isAuth() && <Login/>}
            {API.isAuth() && (API.getRole() === 'admin' || API.getRole() === 'root') &&
            <Container fluid="md" key={'welcome-container'}>
                <hr className="hr-1"></hr>
                {stats && <Row key={'welcome-row'}>
                    {stats.map(stat =>
                        <Col key={`welcome-col-${stat.category}`} md={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>{stat.category}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title><Badge
                                        variant="info">{stat.value}</Badge> enregistrement{stat.value > 1 ? "s" : ""}
                                    </Card.Title>
                                    <Card.Text>
                                        {stat.secondType &&
                                        <span><Badge
                                            variant="info">{stat.secondValue}</Badge> enregistrement{stat.secondValue > 1 ? "s" : ""} {labels[stat.secondType]}</span>
                                        }
                                        {stat.thirdType &&
                                        <div><Badge
                                            variant="danger">{stat.thirdValue} </Badge> enregistrement{stat.thirdValue > 1 ? "s" : ""} {labels[stat.thirdType]}</div>
                                        }
                                        {stat.fourthType &&
                                        <span>{stat.fourthValue} enregistrement{stat.fourthValue > 1 ? "s" : ""} {labels[stat.fourthType]}</span>
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <hr className="hr-1"></hr>
                        </Col>
                    )
                    }
                </Row>}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>État des dernières générations</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {deployments && <Card.Text>
                                    {

                                        deployments.map(histo =>
                                                <>
                                                    {
                                                        histo.status === 'running' && <Alert variant='info'>
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit"
                                                            }).format(new Date(histo.date))} [{histo.status}]
                                                        </Alert>
                                                    }
                                                    {
                                                        histo.status === 'failed' && <Alert variant='danger'>
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit"
                                                            }).format(new Date(histo.date))} [{histo.status}]
                                                        </Alert>
                                                    }
                                                    {
                                                        histo.status === 'success' && <Alert variant='success'>
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit"
                                                            }).format(new Date(histo.date))} [{histo.status}]
                                                        </Alert>
                                                    }
                                                    {
                                                        histo.status === 'canceled' && <Alert variant='dark'>
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "2-digit",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                second: "2-digit"
                                                            }).format(new Date(histo.date))} [{histo.status}]
                                                        </Alert>
                                                    }
                                                </>
                                            // <div><span>{histo.date}</span><span>{histo.status}</span></div>
                                        )
                                    }
                                </Card.Text>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Image src={imagehome} width={"400px"}/>
                    </Col>
                </Row>
            </Container>
            }
        </>);
    }
}