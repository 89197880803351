import React from "react";
import API from "../../utils/API";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import {Card} from "react-bootstrap";

registerLocale('fr', fr)

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const pageNumber = 2;

export class Step3 extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        if (props.getStore()[pageNumber])
            this.state = props.getStore()[pageNumber];
        else
            this.saveData(this.state)
        this._validateOnDemand = true;

        this.saveData = this.saveData.bind(this);
    }


    saveData = function (data) {
        console.debug("Saving data...", data);
        let store = this.props.getStore();
        store[pageNumber] = data;
        this.props.updateStore({
            ...store,
            savedToCloud: false
        });
    };

    setDate = (date) => {
        this.setState({startDate: date}, () => this.saveData(this.state));
    }

    isValidated() {
        return true;
    }

    setTarif = (e) => {
        this.setState({tarifs: e.target.value}, () => this.saveData(this.state));
    }

    setTransport = (e) => {
        this.setState({transport: e.target.value}, () => this.saveData(this.state));
    }

    setServicesSup = (e) => {
        this.setState({servicesSup: e.target.value}, () => this.saveData(this.state));
    }

    setEvenemenAssocie = (e) => {
        this.setState({evenemenAssocie: e.target.value}, () => this.saveData(this.state));
    }

    setServiceSupItems = (e) => {
        let {id} = e.target

        if (id.startsWith('ssup')) {
            let indexDelim = id.indexOf("_");
            //let key = id.substring(0, indexDelim);
            let list = this.state.listeServicesup;
            //console.debug("list", list);

            list.forEach(item => {
                console.debug(`${item.code} === ${id.substring(indexDelim + 1)}`);
                if (item.code === id.substring(indexDelim + 1)) {
                    item.selected = e.target.checked
                }
            });
            console.log("servicesSupItems",list)
            this.setState({servicesSupItems: list}, () => this.saveData(this.state));
        }
    }

    accessIsCheckec = (key, listItem) => {
        let checked = false;
        listItem.map(item => {
            if (item.code === key) {
                checked = item.selected
            }
            return true
        })
        return checked
    }

    componentDidMount() {
        API.addConfSecurity();
        let apiData = new BackofficeDansTousLesSens.DataRefApi()


        let servicesupItems = [];


        apiData.getAllServicesup().then(
            function success(data) {
                servicesupItems = data;
                callback();
            },
            function error(data) {

            }
        );

        const callback = () => {
            this.setState({
                listeServicesup: servicesupItems
            })
        }
    }


    render() {
        const {startDate, tarifs, transport, servicesSup, evenemenAssocie, listeServicesup} = this.state;
        return (
            <>
                <h1>Tarifs et Date</h1>

                <Form.Group controlId="date">
                    <Form.Label>Date et horaire de l'évènement:</Form.Label>
                    <DatePicker
                        selected={startDate}
                        onChange={date => this.setDate(date)}
                        showTimeSelect
                        locale="fr"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                    />
                </Form.Group>


                <Form.Group controlId="tarifs">
                    <Form.Label>Tarifs de l'évènement:</Form.Label>
                    <Form.Control as={"textarea"} value={tarifs} onChange={this.setTarif} placeholder="Tarifs"/>
                </Form.Group>

                <Form.Group controlId="transport">
                    <Form.Label>Moyen de transport</Form.Label>
                    <Form.Control as={"textarea"} value={transport} onChange={this.setTransport}
                                  placeholder="Moyen de transport"/>
                </Form.Group>
                {true === false &&
                <Form.Group controlId="servicesSup">
                    <Form.Label>Services sup</Form.Label>
                    <Form.Control as={"textarea"} value={servicesSup} onChange={this.setServicesSup}
                                  placeholder="Services sup"/>
                </Form.Group>
                }
                <Form.Group controlId="servicesSup">
                    <Form.Label>Services supplémentaires</Form.Label>
                    <Card>
                        {listeServicesup && listeServicesup.map(acc =>
                            <>{
                                <Form.Check type="checkbox" name={`ssup_${acc.code}]`}
                                            id={`ssup_${acc.code}`} label={acc.libelle}
                                            onChange={this.setServiceSupItems}
                                    // defaultChecked={this.accessIsCheckec(acc.code, specData.accessibilite.ssup)}
                                            className="text-left"/>
                            }</>
                        )}
                    </Card>
                </Form.Group>

                <Form.Group controlId="evenemenAssocie">
                    <Form.Label>Événement associé</Form.Label>
                    <Form.Control as={"textarea"} value={evenemenAssocie} onChange={this.setEvenemenAssocie}
                                  placeholder="Événement associé"/>
                </Form.Group>

            </>
        )
    }
}