import React from "react";
import {ListGroup, Button, Alert, Jumbotron, Form, FormControl, Pagination} from "react-bootstrap";
import API from "../../utils/API";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ListEvenements extends React.Component {
    state = {
        evenements: [],
        evenementsFiltered: [],
        messageSuccess: "",
        messageError: "",
        maxPerPage: 10,
        nbItemsPager: 7,
        pageNumber: 0,
        min: 0,
        max: 10,
        filterText: "",
        filterValid: "all",
    };


    componentDidMount() {
        API.addConfSecurity();
        this.filterList();
        this.setPageNumber(this.state.pageNumber);
        let pageNumber=this.state.pageNumber;
        const evenements = async () => {
            const api = new BackofficeDansTousLesSens.EvenementApi();
            try {
                const data = await api.getAllEvenements();
                if (data) {

                    data.sort(function(a, b) {
                        let dateA = a.dateTime; // ignore upper and lowercase
                        const dateB = b.dateTime; // ignore upper and lowercase
                        if (dateA < dateB) {
                            return 1; //lieuA comes first
                        }
                        if (dateA > dateB) {
                            return -1; // lieuB comes first
                        }
                        if (dateA===dateB) {
                            const nameA = a.spectacleTitre.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.spectacleTitre.toUpperCase(); // ignore upper and lowercase
                            return nameA.localeCompare(nameB);
                        }
                    });
                    console.debug("evts", data);
                    this.setState({evenements: data, evenementsFiltered: data});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.filterList();
            this.setPageNumber(pageNumber);
        };
        evenements();

    }

    filterList = (event) => {
        let filterText = this.state.filterText;
        let filterValid = this.state.filterValid;
        if (event) {
            this.setPageNumber(0);
            if (event.target.type === 'radio' && event.target.id === "notvalid") filterValid = "false";
            else if (event.target.type === 'radio' && event.target.id === "valid") filterValid = "true";
            else if (event.target.type === 'radio' && event.target.id !== "valid" && event.target.id !== "notvalid") filterValid = "all";
            else filterText = event.target.value.toLowerCase();
            this.setState({filterText: filterText, filterValid: filterValid});
        }
        //premier filtre sur la validation
        let filteredItemsValidation = this.state.evenements.filter(function (evenement) {
            console.debug(`filters : filterValid= ${filterValid} filterText= ${filterText}`);
            if (filterValid.toLowerCase() === "true" || filterValid.toLowerCase() === "false")
                return evenement.validation.valid === (filterValid === "true");
            else return evenement;
        });
        //second filtre sur le text
        let filteredItems = filteredItemsValidation.filter(function (evenement) {
            console.debug(`filters : filterValid= ${filterValid} filterText= ${filterText}`);
            if (filterText !== "") return evenement.spectacleTitre.toLowerCase().search(filterText) > -1 || evenement.lieuNom.toLowerCase().search(filterText) > -1;
            else return filteredItemsValidation;
        });

        let min = ((this.state.pageNumber * this.state.maxPerPage) > filteredItems.length) ? 0 : (this.state.pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;
        console.debug("nbr evenements filtres : ", filteredItems.length);
        this.setState({evenementsFiltered: filteredItems, pageNumber: 0, min: min, max: max});
    }

    deleteEvenement = async (eventDel) => {
        if (window.confirm(`Êtes vous sûr de vouloir supprimer l'évènement ${eventDel.spectacleTitre}/${eventDel.lieuNom} ?`)) {
            const api = new BackofficeDansTousLesSens.EvenementApi();
            await api.deleteEvent(eventDel.id);
            this.componentDidMount();
            this.props.onSelect(null);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    setPageNumber = (pageNumber) => {
        if (pageNumber<0) pageNumber=0;
        let lastPage =Math.ceil(this.state.evenementsFiltered.length/this.state.maxPerPage);
        if (pageNumber>lastPage-1) pageNumber=lastPage-1;
        console.log(`lastPage : ${lastPage}`);
        console.log(`setPageNumber : ${pageNumber}`);
        let min = ((pageNumber * this.state.maxPerPage) > this.state.evenementsFiltered.length) ? 0 : (pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;

        this.setState({pageNumber: pageNumber, min: min, max: max});
    }

    getPageItems = () => {
        let pageItems = [];
        let minPage;
        console.debug("nb events : ", this.state.evenementsFiltered.length);
        let nbMaxPage = Math.ceil(this.state.evenementsFiltered.length/this.state.maxPerPage);
        let halfItemsPager=Math.ceil(this.state.nbItemsPager/2);
        if (nbMaxPage <=this.state.nbItemsPager || this.state.pageNumber <halfItemsPager || this.state.pageNumber>nbMaxPage) minPage = 0 ;
        else if (this.state.pageNumber > nbMaxPage - halfItemsPager) minPage = nbMaxPage - this.state.nbItemsPager ;
        else minPage=this.state.pageNumber - halfItemsPager;
        let nbMaxItem = minPage + this.state.nbItemsPager;
        if (nbMaxPage<nbMaxItem) nbMaxItem=nbMaxPage;
        for (let i = minPage; i < nbMaxItem; i++) {
            pageItems.push(<Pagination.Item key={i} active={i === this.state.pageNumber}
                                            onClick={() => this.setPageNumber(i)} >{i + 1}</Pagination.Item>)
        }
        console.debug("nb pages : ", nbMaxPage);
        return pageItems;
    }


    render() {
        const {onSelect} = this.props;
        const {
            evenementsFiltered,
            maxPerPage,
            messageError,
            messageSuccess,
            min, max
        } = this.state;

        function formatDate(dateTime) {
            if (dateTime==null || dateTime===""){
                return "Pas de date";
            }
            let dateRender=new Date(dateTime);
            return dateRender.toLocaleString('default',{ day: '2-digit',month: 'short',year:'numeric',hour:'2-digit',minute:'2-digit' });
        }

        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h5>Liste des évènements</h5>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                <Alert key={"successDel"} variant={"success"}>
                    {messageSuccess}
                </Alert>
                }
                {messageError.length > 0 &&
                <Alert key={"errorDel"} variant={"danger"}>
                    {messageError}
                </Alert>
                }

                <FormControl type="text" placeholder="filter" className="mr-sm-2" onChange={this.filterList}/>
                <Form inline>
                    <Form.Check type="radio" label="à valider " name="valid" id="notvalid" onChange={this.filterList}/>
                    <Form.Check type="radio" label="validés " name="valid" id="valid" onChange={this.filterList}/>
                    <Form.Check type="radio" label="tous " name="valid" id="all" onChange={this.filterList}/>
                </Form>
                <Pagination size={'sm'}>
                    <>
                    {this.state.evenementsFiltered.length / maxPerPage > this.state.nbItemsPager &&
                    <><Pagination.First onClick={() => this.setPageNumber(0)} />
                        <Pagination.Prev onClick={() => this.setPageNumber(this.state.pageNumber - 1)} />
                    </>
                    }
                    {this.state.evenementsFiltered.length > maxPerPage &&
                        this.getPageItems()
                    }
                    {this.state.evenementsFiltered.length / maxPerPage > this.state.nbItemsPager &&
                    <> <Pagination.Next onClick={() => this.setPageNumber(this.state.pageNumber + 1)}/>
                        <Pagination.Last onClick={() => this.setPageNumber(this.state.evenementsFiltered.length/maxPerPage)}/></>
                    }
                    </>
                </Pagination>
                <ListGroup>
                    {
                        evenementsFiltered.slice(min, max).map(evenement =>
                            <ListGroup.Item key={evenement.id}>
                                <div className={'listAction'}>
                                    <Button className={'itemListMax'} onClick={() => {
                                        onSelect(evenement)
                                    }}> <div>&nbsp;&nbsp;&nbsp;{evenement.spectacleTitre}</div><div style={{ color: "black",fontSize: 15}}>{evenement.lieuNom}</div><div>{formatDate(evenement.dateTime)}</div>
                                    </Button>
                                    <Button variant="danger" size={"sm"} className={'deleteItem'} onClick={() => {
                                        this.deleteEvenement(evenement)
                                    }}>X</Button>
                                </div>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </div>
        );
    }
}