import React from "react";
import API from "../../utils/API";
import {Col, Row, Card, Image, Container, Badge} from "react-bootstrap";
import logo from "../../logo.png";
import imagehome from "../../logo-dtls.svg";
import {Login} from "../Login/Login";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class WelcomeContributeur extends React.Component {
    state = {
        lieux: [],
        spectacles: [],
        events: []
    }

    componentDidMount() {
        if (!API.isAuth()) return;
        const stats = async () => {
            var dashboardApi = new BackofficeDansTousLesSens.DashboardApi();
            API.addConfSecurity();
            let lieuxResponse = await dashboardApi.getLieuxValidationStats();
            let spectacalesResponse = await dashboardApi.getSpectaclesValidationStats();
            let eventsResponse = await dashboardApi.getEventsValidationStats();

            this.setState({lieux: lieuxResponse.lieux, spectacles: spectacalesResponse.specs, events: eventsResponse});
        };

        stats();

    }

    render() {
        const {lieux, spectacles, events} = this.state;
        return (
            <>
                {!API.isAuth() && <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                </header>}
                {!API.isAuth() && <Login/>}
                {API.isAuth() && (API.getRole() === 'user') &&
                <Container fluid="md" key={'welcome-container'}>
                    <hr className="hr-1"></hr>
                    <Row>
                        <Col key={`welcome-col-lieux-validation`} md={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Lieux</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {lieux &&
                                    <Card.Title>{lieux.length} lieu{lieux.length > 0 && "x"} en validation</Card.Title>}
                                    {lieux && <Card.Text>
                                        {
                                            lieux.map(lieu =>
                                                <div>{lieu.nom}</div>
                                            )
                                        }
                                    </Card.Text>
                                    }
                                </Card.Body>
                            </Card>
                            <hr className="hr-1"></hr>
                        </Col>
                        <Col key={`welcome-col-spectacle-validation`} md={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Spectacles</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {spectacles &&
                                    <Card.Title>{spectacles.length} spectacle{spectacles.length > 0 && "s"} en
                                        validation</Card.Title>}
                                    {spectacles && <Card.Text>
                                        {
                                            spectacles.map(spectacle =>
                                                <div>{spectacle.nom}</div>
                                            )
                                        }
                                    </Card.Text>
                                    }
                                </Card.Body>
                            </Card>
                            <hr className="hr-1"></hr>
                        </Col>
                        <Col key={`welcome-col-spectacle-validation`} md={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Évènements</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {events && <Card.Title>{events.length} évènement{events.length > 0 && "s"} en
                                        validation</Card.Title>}
                                    {events && <Card.Text>
                                        {
                                            events.map(event =>
                                                <div>{event.nom}</div>
                                            )
                                        }
                                    </Card.Text>
                                    }
                                </Card.Body>
                            </Card>
                            <hr className="hr-1"></hr>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Image src={imagehome} width={"400px"}/>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        )
    }
}
