import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {ListUser} from "./ListUser";
import {UserFields} from "./UserFields";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class EditUser extends React.Component {
    state = {
        userData:{
            success:'',
            error:''
        },
        submitDisabled: true,
        reload: false
    };

    constructor(props) {
        super(props);
        this.saveData = this.saveData.bind(this);
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if(this.state.submitDisabled) return ;
        const api = new BackofficeDansTousLesSens.UserApi()
        const user = new BackofficeDansTousLesSens.User(); // {User} User properties
        user.nom = this.state.userData.nom;
        user.prenom = this.state.userData.prenom;
        user.login = this.state.userData.login;
        user.email = this.state.userData.email;
        user.password = this.state.userData.password;
        user.programmateur = this.state.userData.programmateur;
        user.gestionnaire = this.state.userData.gestionnaire;

        const callbackSucess = async (data) => {
            this.setState({success: "Utilisateur modifié avec succès!", error: "",reload:true, submitDisabled:true});
            console.debug('API called successfully. Returned data: ', data);
        }

        const callbackError = async (data) => {
            this.setState({error: "Impossible de modifier l'utilisateur!", success: ""});
            console.error(data);
        }
        console.debug("userUpdate", user);
        api.update(user.login, user).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    selectItem = (item) => {
        if (item) {
            item.sucess='';
            item.error='';
            this.setState({
                userData:item,
                reload:true,
                submitDisabled:false
            });
        } else {
            this.setState({
                userData:{sucess:'',error:''}
            });
        }
    }

    saveData = function (data) {
        let userData = this.state.userData;
        userData[data.key]=data.value;
        this.setState({userData: userData});
    };

    getData = () => {
        return this.state.userData;
    }

    onChange = () => {
        this.setState({
            submitDisabled:false
        });
    }

    componentDidMount() {
        API.addConfSecurity();
    }

    checkReload = () => {
        if (this.state.reload) {
            this.setState({reload: false});
            return true
        } else return false;
    }

    render() {
        const {
            userData
        } = this.state;
        return (
            <Row>
                <Col lg={3} ref={"test"}>
                    <ListUser onSelect={this.selectItem} reload={this.checkReload()}/>
                </Col>
                {userData.id && <Col lg={9}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Modification d'utilisateur</h1>
                    </Jumbotron>
                    {userData.success && userData.success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {this.state.success}
                    </Alert>
                    }
                    {userData.error && userData.error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {this.state.userData.error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit}>
                        <UserFields saveData={this.saveData} dataStore={this.getData} change={this.onChange} reload={this.checkReload()}/>
                        <Button variant="primary" type="submit"
                                disabled={this.state.userData.error.length > 0}>
                            Modifier l'utilisateur
                        </Button>
                    </Form>
                </Col>
                }
            </Row>

        );
    }

}