import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {ListProg} from "./ListProg";
import {FormProg} from "./FormProg";
import {FormLieu} from "../Lieux/FormLieu";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiProg = new BackofficeDansTousLesSens.ProgApi();

export class EditProg extends React.Component {
    state = {
        prog: {},
        success: "",
        error: "",
        submitDisabled: true,
        prog2Save: {},
        lieu2Save: {},
        reload: false
    };

    componentDidMount() {
        API.addConfSecurity();

        if(API.getRole()==='user'){
            this.selectProg({id:API.getPid()})
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.submitDisabled) return;
        console.debug("this.state.prog2Save", this.state.prog2Save)
        console.debug("this.state.lieu2Save", this.state.lieu2Save)

        let api = new BackofficeDansTousLesSens.ProgApi();
        let prog = new BackofficeDansTousLesSens.Prog();

        if (this.state.prog2Save.nom) prog.nom = this.state.prog2Save.nom;
        if (this.state.prog2Save.presentation) prog.presentation = this.state.prog2Save.presentation;

        if (this.state.prog2Save.typeLieu != undefined) {
            prog.typeLieu = this.state.prog2Save.typeLieu
        }

        // correction aprés réunion du vendredi 31 juillet
        /*if (this.state.lieu2Save == null) {
            prog.lieu = this.state.lieu2Save;
        } else {
            prog.lieu = this.state.prog2Save.lieu;
        }*/
        if (this.state.lieu2Save.id != null) {
            prog.lieu = this.state.lieu2Save;
        } else {
            prog.lieu = this.state.prog2Save.lieu;
        }

        if(!prog.lieu.personneContact)prog.lieu.personneContact="";
        if(!prog.lieu.trajetDescription)prog.lieu.trajetDescription="";
        if(!prog.lieu.presentation)prog.lieu.presentation="";
        if(!prog.lieu.horaires) prog.lieu.horaires = {"h1":{"info":""}};


        if (this.state.prog2Save.contactNom) prog.contactNom = this.state.prog2Save.contactNom;
        if (this.state.prog2Save.contactPrenom) prog.contactPrenom = this.state.prog2Save.contactPrenom;
        if (this.state.prog2Save.contactTel) prog.contactTel = this.state.prog2Save.contactTel;
        if (this.state.prog2Save.contactMail) prog.contactMail = this.state.prog2Save.contactMail;
        const photosLieu = prog.lieu.photos;
        prog.lieu.photos = [];

        console.debug("prog", prog)

        const callbackSucess = async (data) => {
            console.debug('API  called successfully. Returned data: ', data);
            prog.lieu.photos = photosLieu;
            this.setState({success: "Programmateur modifié avec succès!", error: "", reload: true,submitDisabled: true});
        }

        const callbackError = async (data) => {
            if (data.body)
                this.setState({error: 'Impossible de modifier le Programmateur! : ' + data.body.message, success: ''});
            else
                this.setState({error: `Impossible de modifier le Programmateur! : $data`, success: ''});
            console.error(data);
        }

        api.updateProg(this.state.prog2Save.id, prog).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    selectProg = async (progLight) => {
        console.debug("prog sel", progLight);

        if (progLight) {
            try {
                const prog = await apiProg.getProg(progLight.id);
                console.debug("prog", prog);
                this.setState({
                    prog: prog,
                    prog2Save: prog,
                    success: '', error: ''
                });
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            this.setState({
                prog: undefined,
                success: '', error: ''
            });
        }

    }

    changeCallbackProg = function (data) {
        console.debug("Prog 2save:", this.state.prog2Save);
        this.setState({prog2Save: data, submitDisabled: false})
    }
    changeCallbackLieu = function (data) {
        console.debug("Lieu 2save:", this.state.lieu2Save);
        this.setState({lieu2Save: data, submitDisabled: false})
    }

    checkReload = () => {
        if (this.state.reload) {
            this.setState({reload: false});
            return true
        } else return false;
    }

    render() {
        const {
            success, error, prog, submitDisabled, reload
        } = this.state;
        return (

            <Row key={"editRow"}>
                {API.getRole()==='admin' &&<Col sm={3} ref={"editCol"}>
                    <ListProg key={"listEditProg"} onSelect={this.selectProg} reload={this.checkReload()}/>
                </Col>}
                {prog && prog.id && <Col sm={API.getRole() === 'admin'?9:12} key={"colEdit"}>
                    <hr className="hr-1"></hr>
                    {(API.getRole() === 'admin') &&
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Modification d'un programmateur</h1>
                    </Jumbotron>
                    }
                    {(API.getRole() === 'user') &&
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Ma fiche programmateur</h1>
                    </Jumbotron>
                    }

                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit}>

                        <FormProg data={prog}
                                  onChange={this.changeCallbackProg.bind(this)}
                                  as={"edit"}
                        >
                        </FormProg>

                        <h2>Lieu</h2>
                        <FormLieu as={"editObjectEmbedded"}
                                  onChange={this.changeCallbackLieu.bind(this)}
                                  parentId={prog.id}
                                  data={prog.lieu}
                                  key={`fl${prog.id}`}>
                        </FormLieu>


                        <Button variant="primary" type="submit">
                            Mettre à jour le programmateur
                        </Button>
                    </Form>

                </Col>
                }
            </Row>

        );
    }
}