import React from "react";
import StepZilla from "react-stepzilla";
import {Step2} from "./Step2";
import {Step1} from "./Step1";
import {Step3} from "./Step3";
import './main.css';

export class Assistant extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.sampleStore = {
            savedToCloud: false
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    getStore() {
        return this.sampleStore;
    }

    updateStore(update) {
        this.sampleStore = {
            ...this.sampleStore,
            ...update,
        }
    }

    render() {
        const steps = [
            {name: 'Programmateur', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} /> },
            {name: 'Lieu du programmateur',  component: <Step2 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} /> },
            {name: 'Enregistrement',  component: <Step3 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} /> }
        ]

        return <>
            <div className='step-progress'>
                <StepZilla steps={steps} stepsNavigation={true} showSteps={true} nextButtonText={"suivant"} backButtonText={"précédent"}
                           preventEnterSubmission={true}
                           prevBtnOnLastStep={false}
                           // hocValidationAppliedTo={[1]}
                           nextTextOnFinalActionStep={"Save"}
                />
            </div>
        </>
    }
}