import React from "react";
import {Form, Col, Row, Button, Image as ReactImage, Container, Media, Modal, Alert, Card} from "react-bootstrap";
import PropTypes from 'prop-types'

export class Photos extends React.Component {

    static propTypes = {
        /**
         * Init datas photos
         */
        initPhotos: PropTypes.array,
        /**
         * Callback on change photo
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Callback on delete photo
         */
        onDelete: PropTypes.func.isRequired,
        /**
         * Unique masterKey-> pour diff des photos si changement d'objet maitre
         */
        masterKey: PropTypes.string.isRequired,
        /**
         * Max pictures
         */
        onChangeAltTexte: PropTypes.func.isRequired,
        max: PropTypes.number

    }

     state = {
        show: false,
        photos: [],
        max: -1,
        labelPhoto: '',
        errorMessage: '',
        result: this.props.value,

        success: "", error: ""
    };



    handleChange = (e) => {
        var {id, value} = e.target
        if (id === 'photo') {
            console.debug("file", e.target.files[0] instanceof File);
            value = e.target.files[0];
            value.id = Date.now();

            let _URL = window.URL;
                let file, img;
                if ((file = e.target.files[0])) {
                    img = new Image();
                    img.props=this.props;
                    img.targetFile = file
                    img.onload = function () {
                        if(this.width<800||this.width>1600){
                            alert("Votre image doit avoir une largeur entre 800 et 1600 pixels de large");
                        }
                        else
                            img.props.onChange(img.targetFile);

                    };
                    img.src = _URL.createObjectURL(file);
                }

        }
        this.setState({
                [id]: value
            }
        );
    };

    componentDidMount() {
        let ph = this.props.initPhotos;
        this.setState({labelPhoto: this.props.label, max: this.props.max})

        const callback = (ph) => {
            console.debug('callback work photo b64:', ph)
            this.setState({photos: ph});
        }
        let photosWITHB64 = this.parseInitPhotos(ph);

        photosWITHB64.then(photosResult => callback(photosResult));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.masterKey!==this.props.masterKey || nextProps.initPhotos.length !== this.props.initPhotos.length
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(!nextProps.initPhotos)return;
       console.debug("!!!!", nextProps.initPhotos)
        if(nextProps.masterKey !== this.props.masterKey || nextProps.initPhotos.length!==this.props.initPhotos.length){
            this.setState({photos: []});
            let ph = nextProps.initPhotos;

            const callback = (ph) => {
                console.debug('callback work photo b64:', ph)
                this.setState({photos: ph});
            }
            let photosWITHB64 = this.parseInitPhotos(ph);
            photosWITHB64.then(photosResult => callback(photosResult));
        }
    }

    parseInitPhotos(photos) {
        const tob64 = this.arrayBufferToBase64.bind(this);
        return new Promise (function (resolve,reject) {
            let photosB = [];
            if (!photos) return photosB;

            photos.forEach((ph) => {
                if (ph){
                    tob64(ph).then(
                        photoFile=>{
                            photosB.push(photoFile)
                            if(photos.indexOf(ph)+1===photos.length){
                               return resolve(photosB);
                            }
                        }
                    );
                }
            });
        })


    }

    canAddPhoto() {
        //if (!this.state.photos || !this.state.photos) return false;
        return this.state.max > 0 && this.state.photos.length >= this.state.max;
    }

    arrayBufferToBase64(photo) {
        return new Promise(function (resolve, reject) {
            if (photo instanceof File) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    photo.b64 = e.target.result
                    return resolve(photo);
                }
                reader.readAsDataURL(photo); // convert to base64 string
            } else {
                let buffer = photo.buffer
                let binary = '';
                let bytes = [].slice.call(new Uint8Array(buffer));
                console.debug("bufffffer",buffer)
                bytes.forEach((b) => binary += String.fromCharCode(b));
                photo.b64 = `data:${photo.mimetype};base64, ${photo.buffer}`;//${window.btoa(binary)}`;
                return resolve(photo);
            }
        })


    };

    popupAltTexte = (photo, index) => {
        this.setState({
            titre: photo.titre,
            texteAlternatif: photo.texteAlternatif,
            description: photo.description,
            indexPhoto: index,
            show: true,
        });
    }

    handleAltChange = async e => {
        const {value, id} = e.target;
        this.setState({[id]: value})
    }

    handleModifier = (photo, index) => {
        console.debug("handleModifier");
        console.debug(index)
        var popupAltTexte = this.popupAltTexte(photo, index);

        this.setState({show: true})
    }

    handleValidateAltTexte = () => {
        let photo = this.state.photos[this.state.indexPhoto];
        photo.titre = this.state.titre;
        photo.texteAlternatif = this.state.texteAlternatif;
        photo.description = this.state.description;

        this.props.onChangeAltTexte(this.state.indexPhoto, photo);

        this.handleClose();
    }

    handleClose = (evt) => {
        this.setState({show: false})
    };

    render() {

        const {
            show, photos, labelPhoto,
            titre, texteAlternatif, description,
            errorMessage
        } = this.state;
        {
            console.log("photos size:", photos)
        }
        return (
            <>
                <h3 className="text-left">Photos</h3>
                <Form.Group controlId="photo">
                    <Form.File
                        id="photo"
                        label="Custom file input"
                        lang="fr"
                        custom
                        disabled={this.canAddPhoto()}
                    >
                        <Form.File.Input accept="image/*" disabled={this.canAddPhoto()} onChange={this.handleChange}/>
                        <Form.File.Label data-browse="Parcourir">
                            {labelPhoto && <span>{labelPhoto}</span>}
                            {!labelPhoto && <span>Nouvelle photo</span>}
                        </Form.File.Label>
                        {this.state.image &&
                        <img width={80} src={this.state.image}/>
                        }
                    </Form.File>
                </Form.Group>

                <Form.Group controlId="container-photos">
                    {photos && photos.map(
                        (photo, index) =>
                            <>
                                <Card>
                                    <Container>
                                        <Row>
                                            <Col md={3}>
                                                <Row>
                                                    <p>
                                                        <span className="badge badge-secondary">#{index+1}</span>
                                                    </p>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ReactImage
                                                            width={160}
                                                            className="align-self-start mr-3"
                                                            rounded
                                                            src={photo.b64}
                                                        />
                                                        <hr className="hr-1"></hr>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-left">
                                                        <small>{photo.originalname ? photo.originalname : photo.name}</small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={9}>
                                                <div>
                                                    <hr className="hr-1"></hr>
                                                </div>
                                                <Media.Body className="text-left">
                                                    <h5>Titre: {photo.titre ? photo.titre : "à remplir"}</h5>
                                                    <p><small>
                                                        <strong>Description:</strong> {photo.description ? photo.description : "à remplir"}
                                                    </small></p>
                                                </Media.Body>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>

                                            </Col>
                                            <Col className="text-right">
                                                <Button size={"sm"} onClick={() => this.handleModifier(photo, index)}>
                                                    <small>Modifier</small>
                                                </Button>
                                                <Button variant="danger" size={"sm"} onClick={() => {
                                                if (window.confirm(`Supprimer l'image ${photo.name ? photo.name : photo.originalname}?`)) this.props.onDelete(photo.id)
                                            }}><small>x</small></Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                                <hr className="hr-1"></hr>
                            </>
                    )}
                </Form.Group>

                <Modal show={show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Définition des textes alternatif</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && errorMessage.length > 0 &&
                        <Alert key={"danger"} variant={"danger"}>
                            {errorMessage}
                        </Alert>
                        }
                        <Form.Group controlId="titre">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control required placeholder="Titre" value={titre}
                                          onChange={this.handleAltChange}/>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control required placeholder="Description" value={description}
                                          onChange={this.handleAltChange}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="cancel" onClick={this.handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" disabled={errorMessage.length > 0}
                                onClick={() => this.handleValidateAltTexte()}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}