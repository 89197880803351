import React from "react";
import {
    Accordion,
    ListGroup,
    Button,
    Alert,
    Jumbotron,
    FormControl,
    Pagination,
    Card,
    Image as ReactImage
} from "react-bootstrap";
import fileIcon from "../../utils/file.svg";
import linkIcon from "../../utils/link.svg";
import API from "../../utils/API";
import {Link} from "react-router-dom";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ViewRessources extends React.Component {
    state = {
        ressources: [],
        ressourcesFiltered: [],
        messageSuccess: "",
        messageError: "",
        maxPerPage: 10,
        pageNumber: 0,
        nbItemsPager: 15,
        min: 0,
        max: 5,
        filterText: "",
        filterDiffusion: "all",
    };

    componentDidMount() {
        API.addConfSecurity();
        this.filterList();
        this.setPageNumber(this.state.pageNumber);
        let pageNumber = this.state.pageNumber;
        const ressources = async () => {
            const api = new BackofficeDansTousLesSens.RessourceApi();
            try {
                const data = await api.getAllRessourcesBack();
                console.debug("ressources", data.ressources);
                if (data) {
                    /*//sort by date dans la requete
                    data.ressources.sort(function (a, b) {
                        const nameA = a.titre.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.titre.toUpperCase(); // ignore upper and lowercase
                        return nameB.localeCompare(nameA);
                    });*/
                    console.debug("ressources", data);
                    this.setState({ressources: data.ressources, ressourcesFiltered: data.ressources});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.filterList();
            this.setPageNumber(pageNumber);
        };
        ressources();
    }

    filterList = (event) => {
        let filterText = this.state.filterText;
        if (event) {
            this.setPageNumber(0);
            filterText = event.target.value.toLowerCase();
            this.setState({filterText: filterText});
        }
        let filteredItems = this.state.ressources.filter(function (ressource) {
            console.debug(`filterText= ${filterText}`);
            if (filterText !== "" && ressource.description != null) return (ressource.titre.toLowerCase().search(filterText) > -1 || ressource.description.toLowerCase().search(filterText) > -1);
            else if (filterText !== "") return (ressource.titre.toLowerCase().search(filterText) > -1);
            else return ressource;
        });

        console.debug(`filter : nb= ${filteredItems.length}`);
        this.setState({ressourcesFiltered: filteredItems});
    }

    setPageNumber = (pageNumber) => {
        if (pageNumber < 0) pageNumber = 0;
        let lastPage = Math.ceil(this.state.ressourcesFiltered.length / this.state.maxPerPage);
        if (pageNumber > lastPage - 1) pageNumber = lastPage - 1;
        console.log(`lastPage : ${lastPage}`);
        console.log(`setPageNumber : ${pageNumber}`);
        let min = ((pageNumber * this.state.maxPerPage) > this.state.ressourcesFiltered.length) ? 0 : (pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;

        this.setState({pageNumber: pageNumber, min: min, max: max});
    }

    getPageItems = () => {
        let pageItems = [];
        let minPage;
        console.debug("nb ressources : ", this.state.ressourcesFiltered.length);
        let nbMaxPage = Math.ceil(this.state.ressourcesFiltered.length / this.state.maxPerPage);
        let halfItemsPager = Math.ceil(this.state.nbItemsPager / 2);
        if (nbMaxPage <= this.state.nbItemsPager || this.state.pageNumber < halfItemsPager || this.state.pageNumber > nbMaxPage) minPage = 0;
        else if (this.state.pageNumber > nbMaxPage - halfItemsPager) minPage = nbMaxPage - this.state.nbItemsPager;
        else minPage = this.state.pageNumber - halfItemsPager;
        let nbMaxItem = minPage + this.state.nbItemsPager;
        if (nbMaxPage < nbMaxItem) nbMaxItem = nbMaxPage;
        for (let i = minPage; i < nbMaxItem; i++) {
            pageItems.push(<Pagination.Item key={i} active={i === this.state.pageNumber}
                                            onClick={() => this.setPageNumber(i)}>{i + 1}</Pagination.Item>)
        }
        console.debug("nb pages : ", nbMaxPage);
        return pageItems;

    }

    getIcon(ressource, document) {
        /*if (document.mimetype.startsWith("image/"))
            return this.arrayBufferToBase64(ressource,document);
        else*/
        return fileIcon
    };

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    async getBlob(ressource, resDocument) {
        const documentApi = new BackofficeDansTousLesSens.DocumentApi();
        const buffer = await documentApi.getDocumentBuffer(ressource.id, resDocument.id, "RESSOURCE")
        console.log("BUFFER :", `data:${resDocument.mimetype};base64, ${buffer}`)
        return this.b64toBlob(buffer, resDocument.mimetype);

    };

    async getBlobUrl(ressource, resDocument) {
        const blob = await this.getBlob(ressource, resDocument);
        return URL.createObjectURL(blob);
    };

    openFile(ressource, resDocument) {
        this.getBlobUrl(ressource, resDocument).then((blobUrl) => {
            const a = document.createElement('a')
            a.href = blobUrl;
            a.target = '_blank';
            a.click()
        })

    }

    saveFile(ressource, resDocument) {
       this.getBlobUrl(ressource, resDocument).then((blobUrl) => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = resDocument.originalname;
            a.click();
            window.URL.revokeObjectURL(blobUrl);
        })
    }

    render() {
        const {onSelect} = this.props;
        const {
            ressourcesFiltered,
            maxPerPage,
            min, max,
            messageError,
            messageSuccess
        } = this.state;
        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h1>Les Ressources</h1>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                    <Alert key={"successDel"} variant={"success"}>
                        {messageSuccess}
                    </Alert>
                }
                {messageError.length > 0 &&
                    <Alert key={"errorDel"} variant={"danger"}>
                        {messageError}
                    </Alert>
                }
                <p>
                    <FormControl type="text" placeholder="faire une recherche sur le titre ou la description"
                                 className="mr-sm-2" onChange={this.filterList}/>
                </p>
                {this.state.ressourcesFiltered.length > maxPerPage &&
                    <Pagination size={'sm'}>
                        <>
                            {this.state.ressourcesFiltered.length / maxPerPage > this.state.nbItemsPager &&
                                <><Pagination.First onClick={() => this.setPageNumber(0)}/>
                                    <Pagination.Prev onClick={() => this.setPageNumber(this.state.pageNumber - 1)}/>
                                </>
                            }
                            {this.state.ressourcesFiltered.length > maxPerPage &&
                                this.getPageItems()
                            }
                            {this.state.ressourcesFiltered.length / maxPerPage > this.state.nbItemsPager &&
                                <> <Pagination.Next onClick={() => this.setPageNumber(this.state.pageNumber + 1)}/>
                                    <Pagination.Last
                                        onClick={() => this.setPageNumber(this.state.ressourcesFiltered.length / maxPerPage)}/></>
                            }
                        </>
                    </Pagination>
                }
                {ressourcesFiltered.slice(min, max).map(ressource =>
                    <Accordion key={ressource.id}>
                            <Accordion.Toggle as={Card.Header} style={{cursor: "pointer", background: "white",border : "none", fontSize: "18px",fontWeight: 'bold'}}
                                              eventKey={ressource.id}>
                                <div className={'row'}
                                     style={{marginLeft: '1rem',textAlign: 'left'}}>{'\u2795'}{ressource.titre}</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={ressource.id}>
                                <Card style={{marginLeft: '4rem',marginRight: '5rem',textAlign: 'left'}}><div>
                                    <p className={'right'}
                                       style={{marginLeft: '1rem', marginRight: '1rem',textAlign: 'left'}}>{ressource.description}</p>
                                    <ListGroup style={{marginLeft: '1rem',marginRight: '5rem',textAlign: 'left'}}>
                                        {ressource.liens.map((link, index) =>
                                            <ListGroup.Item className={'listResource'} key={"lien" + index}>
                                                <Link variant="link" size={"sm"} className={'linkButton'}
                                                                               onClick={() => window.open(link.adresse, "_blank", "noreferrer")
                                                                               }>{'\u25CF'} {link.titre} <ReactImage
                                                width={15} src={linkIcon}/></Link>
                                            </ListGroup.Item>
                                        )}
                                    </ListGroup>
                                    <hr className="hr-1"></hr>
                                    <ListGroup horizontal>
                                        {ressource.documents.map((document, index) =>
                                            <ListGroup.Item className={"listResource"} key={"document" + index}>
                                                <div className={'row'}><span><Button variant="link" size={"sm"}
                                                        onClick={() => this.openFile(ressource, document)
                                                        }><ReactImage
                                                    width={40}
                                                    alt={document.alt}
                                                    rounded
                                                    src={this.getIcon(ressource, document)}
                                                /></Button></span><span>{document.titre ?? document.originalname}<br/><Link
                                                onClick={() => this.saveFile(ressource, document)
                                                }><small>Télécharger
                                                ({(document.size / 1024).toFixed(1)} Ko)</small></Link></span></div>
                                            </ListGroup.Item>
                                        )}
                                    </ListGroup>
                                    <hr className="hr-1"></hr>
                                </div></Card>
                            </Accordion.Collapse>
                        <hr className="hr-1"></hr>
                    </Accordion>
                )
                }
            </div>
        );
    }
}