import React from "react";

export class Catalogue extends React.Component {


    render() {
        const url= process.env.REACT_APP_CATALOGUE_URL
        return (
                <iframe src={url} title={'catalogue'} allowFullScreen={true} style={{height:1500,width:'100%',border:'none',marginLeft:0,marginRight:0}}>></iframe>
        );
    }
}
