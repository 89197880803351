import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import API from "../../utils/API";
import {FormSpec} from "./FormSpec";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class CreateSpec extends React.Component {
    state = {
        success: "", error: "", submitDisabled:true,
        spec:{}
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        API.addConfSecurity();
        var api = new BackofficeDansTousLesSens.SpecApi();
        var spec = new BackofficeDansTousLesSens.Spec();
        let photoApi = new BackofficeDansTousLesSens.PhotoApi();

        spec.titre = this.state.spec.titre;
        spec.presentation = this.state.spec.presentation;
        spec.ephemere = this.state.spec.ephemere;

        spec.genre = this.state.spec.genre;
        spec.public=this.state.spec.public;

        spec.compagnie = this.state.spec.compagnie;
        spec.distribution = this.state.spec.distribution;
        spec.auteur = this.state.spec.auteur;
        spec.miseEnScene = this.state.spec.miseEnScene;
        spec.choregraphie=this.state.spec.choregraphie;
        spec.direction=this.state.spec.direction;
        spec.musique=this.state.spec.musique;
        spec.composition=this.state.spec.composition;
        spec.duree=this.state.spec.duree;
        spec.entracte=this.state.spec.entracte;
        spec.age=this.state.spec.age;

        spec.accessibilite= this.state.spec.accessibilite;
        spec.videos=this.state.spec.videos;

        console.debug("spec create:", spec);
        let countSendPhoto = 0;
        const callbackSucessPhoto = async (data) => {
            countSendPhoto += 1;
            if (countSendPhoto === this.state.spec.photos.length) {
                console.debug('API called successfully. Returned data: ', data);
                this.setState({success: "Spectacle créé avec succès!", error: ""},()=>this.cleanSucess());
            }
        }

        const callbackSucess = async (data) => {
            console.debug('API called successfully. Returned data: ', data);
            if (this.state.spec.photos && this.state.spec.photos.length>0) {
                this.state.spec.photos.map(photo =>
                    photoApi.uploadImage(data.id, "SPEC",photo,{titre:photo.titre,texteAlternatif:photo.texteAlternatif,description:photo.description}).then(
                        function (data) {
                            callbackSucessPhoto(data);
                        },
                        function (error) {
                            callbackError(error);
                        })
                );
            } else {
                this.setState({success: "Spectacle créé avec succès!", error: ""},()=>this.cleanSucess());
            }
        }

        const callbackError = async (data) => {
            console.error(data);
            if (data.body)
                this.setState({error: "Impossible de créer le spectacle! : " + data.body.message, success: ""});
            else if (data.error)
                this.setState({error: "Impossible de créer le spectacle! : " + data.error, success: ""});
            else {
                this.setState({error: "Impossible de créer le spectacle! : erreur inconnue!"});
            }
        }

        api.createSpec(spec).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            }
        );
    };

    cleanSucess = function (){
        this.setState({spec:{},submitDisabled:true});
    }

    changeCallback = function(data){
        this.setState({spec:data,submitDisabled:false})
        //this.setState({"spec":data})
    }

    render() {
        const {
            success, error,submitDisabled
        } = this.state;
        return (
            <Row>
                <Col lg={12}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-5 font-weight-normal">Création d'un spectacle</h1>
                    </Jumbotron>

                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit}>
                        <FormSpec key={`fl-new`}  onChange={this.changeCallback.bind(this)} as={"create"}/>
                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Enregistrer le spectacle
                        </Button>
                    </Form>
                </Col>
            </Row>

        );
    }

}