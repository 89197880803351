import React from "react";
import {Form, Col, Card, Row} from "react-bootstrap";
import API from "../../utils/API";
import {Photos} from "../Utils/Photos";
import {Videos} from "../Utils/Videos";
import PropTypes from 'prop-types';

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiSpec = new BackofficeDansTousLesSens.SpecApi();
const apiPhoto = new BackofficeDansTousLesSens.PhotoApi();

export class FormSpec extends React.Component {
    static propTypes = {

        /**
         * Callback on change data
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Data of spec if EditMode
         */
        data: PropTypes.object,
        /**
         * Edit or Create Mode
         */
        as: PropTypes.oneOf(['edit', 'create']).isRequired
    }

    photos = [];
    //videos =[];

    state = {
        listeGenre: [],
        listePublic: [],
        listeAqui: [],
        listeComment: [],
        listeAdaptation: [],
        listeDispositif: [],
        countMaxCharPresentation: 0,
        specData: {photos: [], accessibilite: {}, videos: []}
    };

    onVideoAdd = (video) => {
        console.debug("onVideoAdd")
        let spec = this.state.specData;
        if (spec.videos)
            spec.videos.push(video);
        else
            spec.videos = [video];
        this.setState({specData: spec}, () => this.props.onChange(spec));
    }

    onVideoChange = (videoId, video) => {
        console.debug("onVideoChange")
        console.debug(videoId, video)

        let spec = this.state.specData;
        if (this.props.as === 'edit') {
            let exist = false;
            spec.videos.map((video, index) => {
                    console.debug(`videoId:${videoId} :: ${video.id}`)
                    if (video.id === videoId) {
                        spec.videos[index] = video
                        exist = true
                    }
                    return true
                }
            );
            if (exist)
                this.setState({specData: spec}, () => this.props.onChange(spec));
            else
                this.onVideoAdd(video)
        } else {

            this.onVideoAdd(video)
        }
    }

    onVideoDelete = (videoId) => {
        console.debug("onVideoDelete");

        let spec = this.state.specData

        spec.videos.map((video, index) => {
                if (video.id === videoId) {
                    spec.videos.splice(index, 1);
                }
                return true
            }
        );


        this.setState({specData: spec}, () => {
            this.props.onChange(spec)
        });
    }

    onChangeAltVideo = async (id, videoUpdate) => {
        API.addConfSecurity();

        console.debug("onChangeAltVideo");
        console.debug("id", id);
        console.debug("videoUpdate", videoUpdate);

        if (id == 0) {
            let spec = this.state.specData;

            this.setState({specData: spec}, () => this.props.onChange(spec));
        } else {

        }

        // à voir


    }

    onPhotoChange = (photo) => {
        API.addConfSecurity();
        if (this.props.as === 'edit') {
            const callbackSucessPhoto = async (data) => {
                try {
                    const spec = await apiSpec.getSpec(this.state.specData.id);
                    this.setState({error: '', success: 'Photo ajoutée!', specData: spec});
                } catch (error) {
                    console.error(error);
                    if (error.message)
                        alert(error.message)
                }
            }
            const callbackError = async (data) => {
                this.setState({error: 'Impossible d\'ajouter la photo!', success: ''});
            }

            apiSpec.uploadPhotoSpec(this.state.specData.id, photo).then(
                function (data) {
                    callbackSucessPhoto(data);
                },
                function (error) {
                    callbackError(error);
                });
        } else {

            this.photos = [photo];
            let spec = this.state.specData;
            spec.photos = this.photos;
            this.setState({specData: spec}, () => this.props.onChange(spec));
        }

    }

    onPhotoDelete = (photoId) => {
        API.addConfSecurity();
        if (this.props.as === "edit") {
            const callbackSucessPhoto = async (data) => {
                try {
                    const spec = await apiSpec.getSpec(this.state.specData.id);
                    this.setState({error: '', success: 'Photo supprimée!', specData: spec});
                    console.debug("spec after delete picture:", spec);
                } catch (error) {
                    console.error(error);
                    if (error.message)
                        alert(error.message)
                }
            }
            const callbackError = async (data) => {
                this.setState({error: 'Impossible de supprimer la photo!', success: ''});
            }
            let deleteObject = new BackofficeDansTousLesSens.PhotoSpecDelete();
            deleteObject.id = photoId;
            apiSpec.deletePhotoSpec(this.state.specData.id, deleteObject).then(
                function (data) {
                    callbackSucessPhoto(data);
                },
                function (error) {
                    callbackError(error);
                });
        } else {
            this.photos.map((photo, index) => {
                    if (photo.id === photoId) {
                        this.photos.splice(index, 1);
                    }
                    return true
                }
            );
            let spec = this.state.specData;
            spec.photos = this.photos;
            this.setState({specData: spec}, () => this.props.onChange(spec));
        }

    }

    handleChange = (e) => {
        let {id, value} = e.target
        let countMaxCharPresentation = this.state.countMaxCharPresentation;
        let specData = this.state.specData;

        if (id === 'photo') {
            value = e.target.files[0];
        } else if (id === 'ephemere') {
            value = e.target.checked;
        } else if (id === 'entracte') {
            value = e.target.checked;
        } else if (id === 'presentation') {
            countMaxCharPresentation = value.length
        }

        if (id.startsWith('aqui') || id.startsWith('comment')
            || id.startsWith('adaptation') || id.startsWith('dispositif')) {
            let indexDelim = id.indexOf("_");
            let key = id.substring(0, indexDelim);
            let list = this.state.specData.accessibilite[key];
            //console.debug("list", list);
            let newItem=true;

            list.forEach(item => {
                //console.debug("key", item.key);
                //console.debug("id.substring(indexDelim+1)", id.substring(indexDelim+1));
                console.debug(`${item.code} === ${id.substring(indexDelim + 1)}`);
                /*if(item.key===id.substring(indexDelim+1)) {
                    item.value=e.target.checked
                }*/
                if (item.code === id.substring(indexDelim + 1)) {
                    item.selected = e.target.checked
                    newItem=false;
                }
            });
            if (newItem){list.push({'code' : id.substring(indexDelim + 1), 'selected' : e.target.checked})}
            //id=key;
            //value=list;
            console.debug("DEBUG",key, list);
            specData.accessibilite[key] = list;
        }

        // console.debug("id:", id, "value", value);

        specData[id] = value;

        this.setState({
                specData: specData,
                countMaxCharPresentation: countMaxCharPresentation
            }, () => this.props.onChange(specData)
        );
    };

    onChangeAltPhoto = async (id, photoUpdate) => {
        API.addConfSecurity();

        if (this.props.as === 'edit') {
            let updateObject = new BackofficeDansTousLesSens.PhotoSpecUpdate();
            updateObject.id = photoUpdate.id;
            updateObject.titre = photoUpdate.titre ? photoUpdate.titre : "";
            updateObject.texteAlternatif = photoUpdate.texteAlternatif ? photoUpdate.texteAlternatif : "";
            updateObject.description = photoUpdate.description ? photoUpdate.description : "";
            console.debug("before update photo alt:", updateObject)
            try {
                await apiPhoto.updateImage(this.state.specData.id, "SPEC", updateObject);
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            let spec = this.state.specData;
            this.photos.map((photo, index) => {
                    if (photo.id === id) {
                        this.photos.splice(index, 1);
                    }
                    return true
                }
            );

            this.photos = [photoUpdate];

            console.debug("change alt list photos:", this.photos);
            console.debug("change alt ph:", photoUpdate);
            console.debug("change alt index:", id);

            spec.photos = this.photos;
            this.setState({specData: spec}, () => this.props.onChange(spec));
        }

    }

    componentDidMount() {
        API.addConfSecurity();
        var apiData = new BackofficeDansTousLesSens.DataRefApi()

        let genresItems = [];
        let publicItems = [];

        let aquiItems = [];
        let commentItems = [];
        let adaptationItems = [];
        let dispositifItems = [];

        apiData.getAllGenreSpectacle().then(
            function success(data) {
                genresItems = data;
                callback();
            },
            function error(data) {

            }
        );

        apiData.getAllAqui().then(
            function success(data) {
                aquiItems = data;
                callback();
            },
            function error(data) {

            }
        );
        apiData.getAllComment().then(
            function success(data) {
                commentItems = data;
                callback();
            },
            function error(data) {

            }
        );
        apiData.getAllAdaptation().then(
            function success(data) {
                adaptationItems = data;
                callback();
            },
            function error(data) {

            }
        );
        apiData.getAllPublic().then(
            function success(data) {
                publicItems = data;
                callback();
            },
            function error(data) {

            }
        );
        apiData.getAllDispositif().then(
            function success(data) {
                dispositifItems = data;
                callback();
            },
            function error(data) {

            }
        );
        if (this.props.data) this.setState({specData: this.props.data})

        const callback = () => {
            //console.debug("callback");

            if (this.props.as === 'edit') {
                //console.debug("aquiItems", aquiItems);
                this.setState({
                    listeGenre: genresItems,
                    listePublic: publicItems,
                    listeAqui: aquiItems,
                    listeComment: commentItems,
                    listeAdaptation: adaptationItems,
                    listeDispositif: dispositifItems,
                })
            } else {
                let listeAquiTemp = [];
                let listeCommentTemp = [];
                let listeAdaptationTemp = [];
                let listeDispositifTemp = [];

                aquiItems.map(acc => {
                    listeAquiTemp.push({code: acc.code, selected: false});
                });
                commentItems.map(acc => {
                    listeCommentTemp.push({code: acc.code, selected: false});
                });
                adaptationItems.map(acc => {
                    listeAdaptationTemp.push({code: acc.code, selected: false});
                });
                dispositifItems.map(acc => {
                    listeDispositifTemp.push({code: acc.code, selected: false});
                });

                let spec = this.state.specData
                //console.debug("spec:",spec)
                /*if(!spec.accessibilite.aqui) spec.accessibilite.aqui=listeAquiTemp
                if(!spec.accessibilite.comment) spec.accessibilite.comment=listeCommentTemp
                if(!spec.accessibilite.adaptation) spec.accessibilite.adaptation=listeAdaptationTemp
                if(!spec.accessibilite.dispositif) spec.accessibilite.dispositif=listeDispositifTemp*/

                spec.accessibilite.aqui = listeAquiTemp
                spec.accessibilite.comment = listeCommentTemp
                spec.accessibilite.adaptation = listeAdaptationTemp
                spec.accessibilite.dispositif = listeDispositifTemp

                this.setState({
                    listeGenre: genresItems,
                    listePublic: publicItems,
                    listeAqui: aquiItems,
                    listeComment: commentItems,
                    listeAdaptation: adaptationItems,
                    listeDispositif: dispositifItems,
                    specData: spec
                })
            }


        }


    }

    static getDerivedStateFromProps(props, state) {
        if (props.data && props.data.id !== state.specData.id) {
            return {
                specData: props.data
            };
        }

        return null;
    }

    accessIsCheckec = (key, listItem) => {
        //if (this.props.as === "edit") {
        let checked = false;
        listItem.map(item => {
            if (item.code === key) {
                checked = item.selected
            }
            return true
        })
        return checked
        //}
    }

    setValid = (e) => {
        let specData = this.state.specData
        specData.validation.valid = e.target.checked;
        this.setState({
                specData: specData
            }, () => this.props.onChange(specData)
        );
    }

    render() {
        const {
            specData,
            listeGenre, listeAqui, listeComment, listeAdaptation, listePublic, listeDispositif,
            countMaxCharPresentation
        } = this.state;

        return (

            <>
                <Form.Group controlId="titre">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control required placeholder="Titre du spectacle"
                                  onChange={this.handleChange}
                                  value={specData.titre}/>
                </Form.Group>

                <Form.Group id="ephemere" as={Row}>
                    <Form.Label column lg={2}>Spectacle éphémère</Form.Label>
                    <Col lg={10}>
                        <Form.Check type="checkbox" name="ephemere" id="ephemere" label="Oui"
                                    onChange={this.handleChange}
                                    defaultChecked={specData.ephemere}/>
                    </Col>
                </Form.Group>

                <Form.Group controlId="presentation">
                    <Form.Label>Présentation ({countMaxCharPresentation}/1000)</Form.Label>
                    <Form.Control required placeholder="Présentation du spectacle"
                                  maxLength="1000"
                                  as="textarea"
                                  onChange={this.handleChange}
                                  value={specData.presentation}/>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="genre">
                        <Form.Label>Genre</Form.Label>
                        <Form.Control required as="select" custom
                                      onChange={this.handleChange} value={specData.genre}>
                            <option>Choisir ....</option>
                            {listeGenre.map(genre =>
                                <option key={genre.id} value={genre.id}>{genre.libelle}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    {true === false &&
                    <Form.Group as={Col} controlId="direction">
                        <Form.Label>Direction</Form.Label>
                        <Form.Control placeholder="Direction du spectacle"
                                      onChange={this.handleChange}
                                      value={specData.direction}/>
                    </Form.Group>
                    }
                </Form.Row>

                <Form.Group controlId="distribution">
                    <Form.Label>Distribution</Form.Label>
                    <Form.Control placeholder="Distribution du spectacle"
                                  as="textarea"
                                  onChange={this.handleChange}
                                  value={specData.distribution}/>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="compagnie">
                        <Form.Label>Compagnie / Ensemble</Form.Label>
                        <Form.Control placeholder="Compagnie / Ensemble du spectacle"
                                      onChange={this.handleChange}
                                      value={specData.compagnie}/>
                    </Form.Group>
                    {true === false &&
                    <Form.Group as={Col} controlId="composition">
                        <Form.Label>Composition</Form.Label>
                        <Form.Control placeholder="Composition du spectacle"
                                      onChange={this.handleChange}
                                      value={specData.composition}/>
                    </Form.Group>
                    }
                </Form.Row>
                {true === false &&
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="auteur">
                        <Form.Label>Auteur</Form.Label>
                        <Form.Control placeholder="Auteur du spectacle"
                                      onChange={this.handleChange}
                                      value={specData.auteur}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="miseEnScene">
                        <Form.Label>Mise en scène</Form.Label>
                        <Form.Control placeholder="Mise en scène"
                                      onChange={this.handleChange}
                                      value={specData.miseEnScene}/>
                    </Form.Group>
                </Form.Row>
                }
                {true === false &&
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="choregraphie">
                        <Form.Label>Choregraphie</Form.Label>
                        <Form.Control placeholder="Choregraphie du spectacle"
                                      onChange={this.handleChange}
                                      value={specData.choregraphie}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="musique">
                        <Form.Label>Musique</Form.Label>
                        <Form.Control placeholder="Musique du spectacle"
                                      onChange={this.handleChange}
                                      value={specData.musique}/>
                    </Form.Group>
                </Form.Row>
                }
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="duree">
                        <Form.Label>Durée</Form.Label>
                        <Form.Control placeholder="Durée"
                                      onChange={this.handleChange}
                                      value={specData.duree}/>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="age">
                        <Form.Label>À partir de ... ans</Form.Label>
                        <Form.Control placeholder="Age"
                                      onChange={this.handleChange}
                                      value={specData.age}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="public">
                        <Form.Label>Public</Form.Label>
                        <Form.Control as="select"
                                      onChange={this.handleChange} value={specData.public}>
                            <option>Choisir ....</option>
                            {listePublic && listePublic.map(publicU =>
                                <option key={publicU.id} value={publicU.id}>{publicU.libelle}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} id="entracte">
                        <Form.Label>Entracte</Form.Label>
                        <Form.Check type="checkbox" name="entracte" id="entracte" label="Oui"
                                    onChange={this.handleChange}
                                    defaultChecked={specData.entracte}/>
                    </Form.Group>
                </Form.Row>

                <h2>Accessibilité de l’oeuvre</h2>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="aqui">
                        <Form.Label>À qui</Form.Label>
                        <Card>
                            {listeAqui && listeAqui.map(acc =>
                                <>{
                                    <Form.Check type="checkbox" name={`aqui_${acc.code}]`}
                                                id={`aqui_${acc.code}`} label={acc.libelle}
                                                onChange={this.handleChange}
                                                defaultChecked={this.accessIsCheckec(acc.code, specData.accessibilite.aqui)}
                                                className="text-left"/>
                                }</>
                            )}
                        </Card>
                    </Form.Group>
                    <Form.Group as={Col} controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Card>
                            {listeComment && listeComment.map(acc =>
                                <>{
                                    <Form.Check type="checkbox" name={`comment_${acc.code}]`}
                                                id={`comment_${acc.code}`} label={acc.libelle}
                                                onChange={this.handleChange}
                                                defaultChecked={this.accessIsCheckec(acc.code, specData.accessibilite.comment)}
                                                className="text-left"/>
                                }</>
                            )}
                        </Card>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="aqui">
                        <Form.Label>Adaptation</Form.Label>
                        <Card>
                            {listeAdaptation && listeAdaptation.map(acc =>
                                <>{
                                    <Form.Check type="checkbox" name={`adaptation_${acc.code}]`}
                                                id={`adaptation_${acc.code}`} label={acc.libelle}
                                                onChange={this.handleChange}
                                                defaultChecked={this.accessIsCheckec(acc.code, specData.accessibilite.adaptation)}
                                                className="text-left"/>
                                }</>
                            )}
                        </Card>
                    </Form.Group>
                    <Form.Group as={Col} controlId="comment">
                        <Form.Label>Dispositif complémentaire</Form.Label>
                        <Card>
                            {listeDispositif && listeDispositif.map(acc =>
                                <>{
                                    <Form.Check type="checkbox" name={`dispositif_${acc.code}]`}
                                                id={`dispositif_${acc.code}`} label={acc.libelle}
                                                onChange={this.handleChange}
                                                defaultChecked={this.accessIsCheckec(acc.code, specData.accessibilite.dispositif)}
                                                className="text-left"/>
                                }</>
                            )}
                        </Card>
                    </Form.Group>
                </Form.Row>

                <h2>Médias</h2>

                <Photos initPhotos={specData.photos}
                        onChange={this.onPhotoChange}
                        onDelete={this.onPhotoDelete}
                        onChangeAltTexte={this.onChangeAltPhoto}
                        max={1}
                        masterKey={this.props.as === "edit" ? this.props.data.id : "new"}/>

                <Videos initVideos={specData.videos}
                        onChange={this.onVideoChange}
                        onDelete={this.onVideoDelete}
                        onChangeAltTexte={this.onChangeAltVideo}
                        masterKey={this.props.as === "edit" ? this.props.data.id : "new"}/>
                {this.props.as === "edit" && API.getRole() === 'admin' &&
                <Form.Group controlId="validation">
                    <Form.Label>Validation</Form.Label>
                    <Form.Check type="checkbox" label={'Validé et visible sur le site public'}
                                onChange={this.setValid}
                                defaultChecked={specData.validation.valid}
                                className="text-left"/>
                </Form.Group>
                }
            </>

        );
    }
}