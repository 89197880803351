import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {ListRessources} from "./ListRessources";
import {FormRessource} from "./FormRessource";
import API from "../../utils/API";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiRessource = new BackofficeDansTousLesSens.RessourceApi();

export class EditRessource extends React.Component {
    state = {
        ressource: undefined,
        success: "",
        error: "",
        submitDisabled: true,
        reload: false
    };

    ressource2Save = {};

    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.submitDisabled) return;
        let api = new BackofficeDansTousLesSens.RessourceApi();
        console.log("ressource 2 save:",this.ressource2Save)

        let ressource = new BackofficeDansTousLesSens.Ressource();
        ressource.titre = this.ressource2Save.titre;
        ressource.description = this.ressource2Save.description;
        ressource.ordre = this.ressource2Save.ordre;
        ressource.diffusionBackoffice = this.ressource2Save.diffusionBackoffice;
        ressource.diffusionFront = this.ressource2Save.diffusionFront;
        ressource.documents = this.ressource2Save.documents;
        ressource.liens = this.ressource2Save.liens;

        console.debug("update ressource:", ressource);

        const callbackSucessDocument = async (data) => {
            console.debug('API document called successfully. Returned data: ', this.state.id);
            api.getOneRessource(this.state.id).then(
                function (data) {
                    updateData("Ressource modifiée avec succès!", '', data)
                },
                function (error) {
                    updateData({success: "Ressource modifiée avec succès!", error: ''});
                }
            );
        }
        const updateData = async (message, error, data) => {
            console.debug(data.documents);
            if (data)
                this.setState({
                    success: message,
                    error: error,
                    document: 'data:application/*,' + this.arrayBufferToBase64(data.document.buffer.data)
                });
            else {
                if (message)
                    this.setState({success: message, error: error, reload:true, submitDisabled:true});
                else
                    this.setState({success: message, error: error});
            }

        }
        const callbackSucess = async (data) => {
            console.debug('API  called successfully. Returned data: ', data);
            if (this.state.ressource.document) {
                api.uploadDocument(this.state.id, this.state.ressource.document).then(
                    function (data) {
                        callbackSucessDocument(data);
                    },
                    function (error) {
                        callbackError(error);
                    });
            } else {
                this.setState({success: "Ressource modifiée avec succès!", error: "", reload: true, submitDisabled: true});
            }
        }
        const callbackError = async (data) => {
            if (data.body)
                this.setState({error: 'Impossible de modifier la ressource! : ' + data.body.message, success: ''});
            else
                this.setState({error: `Impossible de modifier la ressource! : ${data.error}`, success: ''});
            console.error(data);

        }

        console.debug("id:", this.ressource2Save.id, "ressource:", ressource)

        api.updateRessource(this.ressource2Save.id, ressource).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    selectRessource = async (ressourceLight) => {
        if (ressourceLight) {
            try {
                const ressource = await apiRessource.getOneRessource(ressourceLight.id);
                console.debug("ressource", ressource);
                this.ressource2Save = ressource;
                this.setState({
                    ressource: ressource,
                    success: '', error: ''
                });
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            this.setState({
                ressource: undefined,
                success: '', error: ''
            });
        }
    }

    changeCallback = function (data) {
        console.debug("Ressource 2save:", this.ressource2Save);
        this.ressource2Save = data;
        this.setState({submitDisabled: false})
        //this.setState({"ressource":data})
    }

    checkReload = () => {
        if (this.state.reload) {
            this.setState({reload: false});
            return true;
        } else return false;
    }

    componentDidMount() {
        API.addConfSecurity();
    }

    render() {
        const {
            success, error, ressource, submitDisabled
        } = this.state;
        return (

            <Row key={"editRow"}>
                <Col sm={3} key={"editCol"}>
                    <ListRessources key={"listEditRessources"} onSelect={this.selectRessource} reload={this.checkReload()}/>
                </Col>
                {ressource && <Col sm={9} key={"colEdit"}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Modification d'une ressource</h1>
                    </Jumbotron>

                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit} key={`form${ressource.id}`}>
                        <FormRessource key={`fl${ressource.id}`} data={ressource} onChange={this.changeCallback.bind(this)}
                                       as={"edit"}/>
                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Mettre à jour la ressource
                        </Button>
                    </Form>

                </Col>
                }
            </Row>
        );
    }
}