import React from "react";
import {Form, Col, Row, Alert} from "react-bootstrap";
import {Photos} from "../Utils/Photos";
import PropTypes from "prop-types";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiPhoto = new BackofficeDansTousLesSens.PhotoApi();

export class FormProg extends React.Component {
    state = {
        prog: {logoCouleur: undefined, logoNB: undefined},
        success: "", error: ""
    };

    static propTypes = {
        /**
         * Callback on change data
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Data of lieu if present
         */
        data: PropTypes.object,
        /**
         * Edit or Create Mode
         */
        as: PropTypes.oneOf(['edit', 'create']).isRequired
    }
    onLogoCouleurChange = async (logo) => {
        let prog = this.state.prog
        prog.logoCouleur = logo
        this.setState({prog: prog}, () => this.props.onChange(this.state.prog));
        if (this.props.as === 'edit') {
            try {
                await apiPhoto.uploadImage(prog.id, 'PROG_LOGO', logo)
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    onLogoCouleurDelete = async (logo) => {
        let prog = this.state.prog
        prog.logoCouleur = undefined

        this.setState({prog: prog}, () => {
            this.props.onChange(this.state.prog)
        });
        if (this.props.as === 'edit') {
            console.debug("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! le logo", logo);
            try {
                await apiPhoto.deleteImage(prog.id, 'PROG_LOGO', {id: logo})
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }
    onLogoNbChange = async (logo) => {
        let prog = this.state.prog
        prog.logoNB = logo
        this.setState({prog: prog}, () => this.props.onChange(this.state.prog));
        if (this.props.as === 'edit') {
            try {
                await apiPhoto.uploadImage(prog.id, 'PROG_LOGONB', logo)
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    onChangeLogoNBAltPhoto = async (id, photoUpdate) => {
        API.addConfSecurity();
        if (this.props.as === 'edit') {
            let updateObject = new BackofficeDansTousLesSens.PhotoSpecUpdate();
            updateObject.id = photoUpdate.id;
            updateObject.titre = photoUpdate.titre;
            updateObject.texteAlternatif = '';//photoUpdate.texteAlternatif;
            updateObject.description = photoUpdate.description;
            try {
                await apiPhoto.updateImage(this.state.prog.id, "PROG_LOGONB", updateObject);
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            let prog = this.state.prog
            prog.logoNB = photoUpdate
            this.setState({prog: prog}, () => this.props.onChange(this.state.prog));
        }
    }

    onChangeLogoCouleurAltPhoto = async (id, photoUpdate) => {
        API.addConfSecurity();
        if (this.props.as === 'edit') {
            let updateObject = new BackofficeDansTousLesSens.PhotoSpecUpdate();
            updateObject.id = photoUpdate.id;
            updateObject.titre = photoUpdate.titre;
            updateObject.texteAlternatif = '';//photoUpdate.texteAlternatif;
            updateObject.description = photoUpdate.description;
            try {
                await apiPhoto.updateImage(this.state.prog.id, "PROG_LOGO", updateObject);
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            let prog = this.state.prog
            prog.logoCouleur = photoUpdate
            this.setState({prog: prog}, () => this.props.onChange(this.state.prog));
        }
    }

    onLogoNbDelete = async (logo) => {
        let prog = this.state.prog
        prog.logoNB = undefined
        this.setState({prog: prog}, () => {
            this.props.onChange(this.state.prog)
        });

        if (this.props.as === 'edit') {
            try {
                await apiPhoto.deleteImage(prog.id, 'PROG_LOGONB', {id: logo})
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({prog: nextProps.data})
        console.debug("data formprog props:", nextProps.data)
    }

    componentDidMount() {

        if (this.props.data) {
            this.setState({prog: this.props.data})
        } else {
            let prog = new BackofficeDansTousLesSens.Prog();
            this.setState({prog: prog})
        }
    }

    handleChange = (e) => {
        let {id, value} = e.target
        let prog = this.state.prog;
        console.debug("id:", id, "value", value);
        if (id === 'typeLieu') {
            value = e.target.checked;
        }
        prog[id] = value

        this.setState({
                prog: prog
            }, () => this.props.onChange(prog)
        );


    };


    render() {
        const {
            prog,
            success, error
        } = this.state;
        return (
            <Row>
                <Col lg={12}>
                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form.Group controlId="nom">
                        <Form.Label>Nom du programmateur</Form.Label>
                        <Form.Control placeholder="Nom du programmateur"
                                      onChange={this.handleChange} value={prog.nom}/>
                    </Form.Group>

                    <Form.Group controlId="presentation">
                        <Form.Label>Présentation du programmateur</Form.Label>
                        <Form.Control as={"textarea"} placeholder="Présentation"
                                      onChange={this.handleChange} value={prog.presentation}/>
                    </Form.Group>

                    <h2>Contact pour l'accessibilité</h2>

                    <Form.Row>
                        <Form.Group as={Col} xs={12} md={6} controlId="contactNom">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control placeholder="Nom du contact programmateur"
                                          onChange={this.handleChange} value={prog.contactNom}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} md={6} controlId="contactPrenom">
                            <Form.Label>Prénom</Form.Label>
                            <Form.Control placeholder="Prénom du contact programmateur"
                                          onChange={this.handleChange} value={prog.contactPrenom}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs={12} md={6} controlId="contactTel">
                            <Form.Label>Téléphone</Form.Label>
                            <Form.Control placeholder="Téléphone du contact programmateur"
                                          onChange={this.handleChange} value={prog.contactTel}/>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} md={6} controlId="contactMail">
                            <Form.Label>Mail</Form.Label>
                            <Form.Control placeholder="Mail du contact programmateur"
                                          onChange={this.handleChange} value={prog.contactMail}/>
                        </Form.Group>
                    </Form.Row>


                    <Form.Group id="typeLieu" as={Row}>
                        <Form.Label column lg={2}>Est un lieu d'évènments</Form.Label>
                        <Col lg={10}>
                            <Form.Check type="checkbox" name="typeLieu" id="typeLieu" label="Oui"
                                        onChange={this.handleChange} defaultChecked={prog.typeLieu}/>
                        </Col>
                    </Form.Group>

                    <Photos max={"1"} label={"Logo couleur"} initPhotos={[prog.logoCouleur]}
                            onChange={this.onLogoCouleurChange}
                            onDelete={this.onLogoCouleurDelete}
                            masterKey={`wizz-prog-logo-create-${prog.logoCouleur ? prog.logoCouleur.id : '1'}`}
                            onChangeAltTexte={this.onChangeLogoCouleurAltPhoto}/>
                    <Photos max={"1"} initPhotos={[prog.logoNB]} label={"Logo noir et blanc"}
                            onChange={this.onLogoNbChange}
                            onDelete={this.onLogoNbDelete}
                            masterKey={`wizz-prog-logonb-create${prog.logoNB ? prog.logoNB.id : '2'}`}
                            onChangeAltTexte={this.onChangeLogoNBAltPhoto}/>

                </Col>
            </Row>

        );
    }

}