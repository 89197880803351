import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {Horaire} from "../Utils/Horaire";
import {Photos} from "../Utils/Photos";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class CreateProg extends React.Component {
    state = {
        nom: "",
        adresse: "",
        codePostal: "",
        ville: "",
        tel: "",
        email: "",
        site: "",
        horaires: {},
        typeLieu: false,
        logo: undefined,
        logonb: undefined,
        success: "", error: ""
    };

    onConfigureHoraireChange = (horaires) => {
        console.debug('recuperation des horaires:', horaires);
        this.state.horaires = horaires;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        API.addConfSecurity();
        let api = new BackofficeDansTousLesSens.ProgApi()
        let prog = new BackofficeDansTousLesSens.Prog();
        let photoApi = new BackofficeDansTousLesSens.PhotoApi();
        prog.nom = this.state.nom;
        prog.adresse = this.state.adresse;
        prog.codePostal = this.state.codePostal;
        prog.email = this.state.email;
        prog.ville = this.state.ville;
        prog.tel = this.state.tel;
        prog.site = this.state.site;
        prog.horaires = this.state.horaires;
        prog.typeLieu = this.state.typeLieu;

        console.debug("prog:", prog);

        const callbackSucess = async (data) => {
            try {
                if (this.state.logo || this.state.logonb) {
                    if (this.state.logo) await photoApi.uploadImage(data.id, 'PROG_LOGO', this.state.logo)
                    if (this.state.logoNb) await photoApi.uploadImage(data.id, 'PROG_LOGONB', this.state.logoNb)
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.setState({success: "Programmateur créé avec succès !", error: ""});
            console.debug('API called successfully. Returned data: ', data);
        }

        const callbackError = async (data) => {
            this.setState({error: "Impossible de créer le programmateur !", success: ""});
            console.error(data);
        }

        api.createProg(prog).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    onLogoNbChange = (logoNb) => {
        this.setState({logoNb: logoNb});
    }

    onLogoNbDelete = (logoNb) => {
        this.setState({logoNb: undefined});
    }
    onLogoChange = (logo) => {
        this.setState({logo: logo});
    }

    onLogoDelete = (logo) => {
        this.setState({logo: undefined}, () => {
            console.debug("ondelete:", this.state.logo)
        });

    }

    handleChange = (e) => {
        let {id, value} = e.target
        console.debug("id:", id, "value", value);
        if (id === 'typeLieu') {
            value = e.target.checked;
        }
        this.setState({
                [id]: value
            }
        );
    };


    render() {
        const {logo,
            logoNb,
            success, error
        } = this.state;
        return (
            <Row>
                <Col lg={12}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Création d'un programmateur</h1>
                    </Jumbotron>

                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" placeholder="Email du programmateur"
                                              onChange={this.handleChange}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="nom">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control placeholder="Nom du programmateur"
                                          onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group id="typeLieu" as={Row}>
                            <Form.Label column lg={2}>Est un lieu d'évènments</Form.Label>
                            <Col lg={10}>
                                <Form.Check type="checkbox" name="typeLieu" id="typeLieu" label="Oui"
                                            onChange={this.handleChange} defaultChecked={this.state.isLieu}/>
                            </Col>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} controlId="adresse">
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control required placeholder="Adresse du programmateur"
                                              onChange={this.handleChange} value={this.state.adresse}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="codePostal">
                                <Form.Label>C.P</Form.Label>
                                <Form.Control required placeholder="CP"
                                              onChange={this.handleChange} value={this.state.codePostal}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="ville">
                                <Form.Label>Ville</Form.Label>
                                <Form.Control required
                                              placeholder="Ville"
                                              onChange={this.handleChange} value={this.state.ville}/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="tel">
                            <Form.Label>Numéro de téléphone</Form.Label>
                            <Form.Control
                                placeholder="Numéro de téléphone du programmateur"
                                onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group controlId="site">
                            <Form.Label>Site web</Form.Label>
                            <Form.Control placeholder="Site web du programmateur"
                                          onChange={this.handleChange}/>
                        </Form.Group>

                        <Photos max={"1"} label={"Logo couleur"} initPhotos={[logo]} onChange={this.onLogoChange}
                                onDelete={this.onLogoDelete}/>
                        <Photos max={"1"} initPhotos={[logoNb]} label={"Logo noir et blanc"}
                                onChange={this.onLogoNbChange} onDelete={this.onLogoNbDelete}/>
                        <Horaire onConfigureHoraireChange={this.onConfigureHoraireChange}/>

                        <Button variant="primary" type="submit">
                            Enregistrer le programmateur
                        </Button>
                    </Form>
                </Col>
            </Row>

        );
    }

}