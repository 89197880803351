import React from "react";
import {Form, Col, Accordion, Card} from "react-bootstrap";
import {Horaire} from "../Utils/Horaire";
import API from "../../utils/API";
import {Photos} from "../Utils/Photos";
import PropTypes from 'prop-types'

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiLieu = new BackofficeDansTousLesSens.LieuApi();
const apiPhoto = new BackofficeDansTousLesSens.PhotoApi();
const progApi = new BackofficeDansTousLesSens.ProgApi();

export class FormLieu extends React.Component {
    static propTypes = {

        /**
         * Callback on change data
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Data of lieu if EditMode
         */
        data: PropTypes.object,
        /**
         * Edit or Create Mode
         */
        as: PropTypes.oneOf(['edit', 'create', 'editObjectEmbedded']).isRequired,
        /**
         * Parent id
         */
        parentId: PropTypes.string,
        /**
         * Set hide fields
         */
        hideFields: PropTypes.array
    }
    state = {
        listeAccessibiliteItems: [],
        countMaxCharTrajet: 0,
        countMaxCharPresentation: 0,
        lieuData: {photos: [], accessibilites: {}}
    };

    photos = [];
    onConfigureHoraireChange = (horaires) => {
        let lieu = this.state.lieuData;
        lieu.horaires = horaires;

        this.setState({horaires: horaires, lieuData: lieu}, () => this.props.onChange(lieu))
    }
    onPhotoChange = (photo) => {
        try {
            API.addConfSecurity();
            if (this.props.as === 'edit') {
                const callbackSucessPhoto = async (data) => {
                    const lieu = await apiLieu.getOneLieu(this.state.lieuData.id);
                    this.setState({error: '', success: 'Photo ajoutée!', lieuData: lieu});
                }
                const callbackError = async (data) => {
                    this.setState({error: 'Impossible d\'ajouter la photo!', success: ''});
                }
                apiLieu.uploadPhoto(this.state.lieuData.id, photo).then(
                    function (data) {
                        callbackSucessPhoto(data);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } else if (this.props.as === 'editObjectEmbedded') {

                const callbackSucessPhoto = async (data) => {
                    const prog = await progApi.getProg(this.props.parentId);
                    console.debug("lieu : photo ajoutée");
                    this.setState({error: '', success: 'Photo ajoutée!', lieuData: prog.lieu});
                }
                const callbackError = async (data) => {
                    this.setState({error: 'Impossible d\'ajouter la photo!', success: ''});
                }

                apiPhoto.uploadImage(this.props.parentId, 'PROG_LIEUX_LOGO', photo, {
                    titre: photo.titre,
                    texteAlternatif: photo.texteAlternatif,
                    description: photo.description
                }).then(
                    // apiPhoto.uploadPhoto(this.state.lieuData.id, photo).then(
                    function (data) {
                        callbackSucessPhoto(data);
                    },
                    function (error) {
                        callbackError(error);
                    }
                );
            } else {
                this.photos.push(photo);
                let lieu = this.state.lieuData;
                lieu.photos = this.photos;
                this.setState({lieuData: lieu}, () => this.props.onChange(lieu));
            }
        } catch
            (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    onChangeAltPhoto = async (id, photoUpdate) => {
        API.addConfSecurity();
        try {
            if (this.props.as === 'edit') {
                let updateObject = new BackofficeDansTousLesSens.PhotoSpecUpdate();
                updateObject.id = photoUpdate.id;
                updateObject.titre = photoUpdate.titre;
                updateObject.texteAlternatif = photoUpdate.texteAlternatif;
                updateObject.description = photoUpdate.description;
                console.debug("before update photo alt:", updateObject)
                await apiPhoto.updateImage(this.state.lieuData.id, "LIEU", updateObject);
            } else if (this.props.as === 'editObjectEmbedded') {
                let updateObject = new BackofficeDansTousLesSens.PhotoSpecUpdate();
                updateObject.id = photoUpdate.id;
                updateObject.titre = photoUpdate.titre;
                updateObject.texteAlternatif = photoUpdate.texteAlternatif?photoUpdate.texteAlternatif:"";
                updateObject.description = photoUpdate.description;
                console.debug("before update photo alt:", updateObject)
                console.debug("before update photo alt:", this.props.parentId)
                await apiPhoto.updateImage(this.props.parentId, "PROG_LIEUX_LOGO", updateObject);
            } else {
                let lieu = this.state.lieuData;
                this.photos.map((photo, index) => {
                        if (photo.id === id) {
                            this.photos.splice(index, 1);
                        }
                        return true
                    }
                );
                this.photos.push(photoUpdate);
                lieu.photos = this.photos;
                this.setState({lieuData: lieu}, () => this.props.onChange(lieu));
            }
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    onPhotoDelete = async (photoId) => {
        API.addConfSecurity();
        try {
            if (this.props.as === "edit") {
                const callbackSucessPhoto = async (data) => {
                    const lieu = await apiLieu.getOneLieu(this.state.lieuData.id);
                    this.setState({error: '', success: 'Photo supprimée!', lieuData: lieu});
                    console.debug("lieu after delete picture:", lieu);
                }
                const callbackError = async (data) => {
                    this.setState({error: 'Impossible de supprimer la photo!', success: ''});
                }

                let deleteObject = new BackofficeDansTousLesSens.PhotoLieuDelete();
                deleteObject.id = photoId;
                apiLieu.deletePhoto(this.state.lieuData.id, deleteObject).then(
                    function (data) {
                        callbackSucessPhoto(data);
                    },
                    function (error) {
                        callbackError(error);
                    });
            }
            if (this.props.as === "editObjectEmbedded") {
                const callbackSucessPhoto = async (data) => {
                    const prog = await progApi.getProg(this.props.parentId);

                    this.setState({error: '', success: 'Photo supprimée!', lieuData: prog.lieu});
                    console.debug("lieu after delete picture:", prog.lieu);
                }
                const callbackError = async (data) => {
                    this.setState({error: 'Impossible de supprimer la photo!', success: ''});
                }

                apiPhoto.deleteImage(this.props.parentId, "PROG_LIEUX_LOGO", {id: photoId}).then(
                    // apiLieu.deletePhoto(this.state.lieuData.id, deleteObject).then(
                    function (data) {
                        callbackSucessPhoto(data);
                    },
                    function (error) {
                        callbackError(error);
                    });
            } else {
                this.photos.map((photo, index) => {
                        if (photo.id === photoId) {
                            this.photos.splice(index, 1);
                        }
                        return true
                    }
                );
                let lieu = this.state.lieuData;
                lieu.photos = this.photos;
                this.setState({lieuData: lieu}, () => this.props.onChange(lieu));
            }
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    handleChange = (e) => {
        var {id, value} = e.target
        let countMaxCharTrajet = this.state.countMaxCharTrajet;
        let countMaxCharPresentation = this.state.countMaxCharPresentation;
        let lieuData = this.state.lieuData;

        if (id === 'photo') {
            value = e.target.files[0];
        } else if (id.startsWith('pmr') || id.startsWith('services') || id.startsWith('equipements')) {
            let indexDelim = id.indexOf("_");
            let key = id.substring(0, indexDelim);
            let list = this.state.lieuData.accessibilites[key];
            console.debug("list", list);
            let newItem=true;

            list.forEach(item => {
                console.debug(`${item.code} === ${id.substring(indexDelim + 1)}`);
                if (item.code === id.substring(indexDelim + 1)) {
                    item.selected = e.target.checked;
                    newItem=false;
                }
            });
            if (newItem){list.push({'code' : id.substring(indexDelim + 1), 'selected' : e.target.checked})}

            lieuData.accessibilites[key] = list;
            console.debug("list:", list);
        } else if (id === 'trajetDescription') {
            countMaxCharTrajet = value.length
            lieuData[id] = value;
        } else if (id === 'presentation') {
            countMaxCharPresentation = value.length
            lieuData[id] = value;
        } else {
            lieuData[id] = value;
        }


        this.setState({
                lieuData: lieuData,
                countMaxCharTrajet: countMaxCharTrajet,
                countMaxCharPresentation: countMaxCharPresentation
            }, () => this.props.onChange(lieuData)
        );

        //this.callbackChange();
    };

    componentDidMount() {
        API.addConfSecurity();
        var apiData = new BackofficeDansTousLesSens.DataRefApi()
        let accessibiliteItems = []
        apiData.getAllAccessibiliteItems().then(
            function success(data) {
                accessibiliteItems = data;
                callback();
            },
            function error(data) {

            }
        );

        if (this.props.data) {
            let lieuData = this.props.data;
            if (!lieuData.photos) lieuData.photos = []
            this.setState({lieuData: lieuData})
        }

        const callback = () => {
            if (this.props.as === 'edit') {
                this.setState({listeAccessibiliteItems: accessibiliteItems})
            } else {
                let listPmr = [];
                let listEqp = [];
                let listServ = [];

                accessibiliteItems.map(acc => {
                    if (acc.code.startsWith("PMR")) {
                        listPmr.push({code: acc.code, selected: false});
                    } else if (acc.code.startsWith("EQP")) {
                        listEqp.push({code: acc.code, selected: false});
                    } else if (acc.code.startsWith("SERV")) {
                        listServ.push({code: acc.code, selected: false});
                    }
                    return false;
                });
                let lieu = this.state.lieuData
                console.debug("lieu:", lieu)
                //test car en mode assistant il peut y avoir des données
                if (!lieu.accessibilites.pmr) lieu.accessibilites.pmr = listPmr
                if (!lieu.accessibilites.equipements) lieu.accessibilites.equipements = listEqp
                if (!lieu.accessibilites.services) lieu.accessibilites.services = listServ
                if (!lieu.photos) lieu.photos = []
                this.setState({listeAccessibiliteItems: accessibiliteItems, lieuData: lieu})
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data && (!state.lieuData || props.data.id !== state.lieuData.id)) {
            return {
                lieuData: props.data
            };
        }

        return null;
    }

    accessIsCheckec = (key, listItem) => {
        //if (this.props.as === "edit") {
        let checked = false;
        listItem.map(item => {
            if (item.code === key) {
                checked = item.selected
            }
            return true
        })
        return checked
        //}

    }

    setValid = (e) => {
        let lieuData = this.state.lieuData;
        lieuData.validation.valid=e.target.checked;
        this.setState({
                lieuData: lieuData
            }, () => this.props.onChange(lieuData)
        );
    }


    render() {
        const {
            countMaxCharTrajet, countMaxCharPresentation, lieuData, listeAccessibiliteItems
        } = this.state;

        return (

            <>
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("nom") < 0)) &&
                <Form.Group controlId="nom">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control required placeholder="Nom du lieu"
                                  onChange={this.handleChange} value={lieuData.nom}/>
                </Form.Group>
                }
                <Form.Row>
                    <Form.Group as={Col} controlId="adresse">
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control placeholder="Adresse du lieu"
                                      onChange={this.handleChange} value={lieuData.adresse}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="codePostal">
                        <Form.Label>C.P</Form.Label>
                        <Form.Control placeholder="CP"
                                      onChange={this.handleChange} value={lieuData.codePostal}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="ville">
                        <Form.Label>Ville</Form.Label>
                        <Form.Control
                            placeholder="Ville"
                            onChange={this.handleChange}
                            value={lieuData.ville}
                        />
                    </Form.Group>
                </Form.Row>
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("trajetDescription") < 0)) &&
                <Form.Group controlId="trajetDescription">
                    <Form.Label id={"test"}>Comment venir ({countMaxCharTrajet}/1000)</Form.Label>
                    <Form.Control as="textarea" rows={3} maxLength="1000"
                                  placeholder="Description du trajet"
                                  value={(lieuData.trajetDescription ? lieuData.trajetDescription : "")}
                                  onChange={this.handleChange}/>
                </Form.Group>
                }
                <Form.Group controlId="tel">
                    <Form.Label>Tél.</Form.Label>
                    <Form.Control placeholder="Tél du lieu" value={lieuData.tel}
                                  onChange={this.handleChange}/>
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email contact du lieu"
                                  value={(lieuData.email ? lieuData.email : "")}
                                  onChange={this.handleChange}/>
                </Form.Group>
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("personneContact") < 0)) &&
                <Form.Group controlId="personneContact">
                    <Form.Label>Personne contact</Form.Label>
                    {
                        console.debug("lieudata::::::=========>", lieuData)

                    }
                    <Form.Control type="personneContact" placeholder="Nom de la personne contact du lieu"
                                  value={(lieuData.personneContact ? lieuData.personneContact : "")}
                                  onChange={this.handleChange}/>
                </Form.Group>
                }
                <Form.Group controlId="site">
                    <Form.Label>Site</Form.Label>
                    <Form.Control type={"url"} placeholder="Url du site de lieu" value={lieuData.site}
                                  onChange={this.handleChange}/>
                </Form.Group>
                {(!this.props.hideFields || (this.props.hideFields && this.props.hideFields.indexOf("presentation") < 0)) &&
                <Form.Group controlId="presentation">
                    {/*<Form.Label>Présentation du lieu ({countMaxCharPresentation}/1000)</Form.Label>*/}
                    <Form.Label>Présentation du lieu </Form.Label>
                    {/*<Form.Control as="textarea" rows={3} maxLength="1000"*/}
                    <Form.Control as="textarea" rows={3}
                                  placeholder="Description du lieu"
                                  value={lieuData.presentation} onChange={this.handleChange}/>
                </Form.Group>
                }
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Accessibilité
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <h2>P.M.R</h2>
                                {listeAccessibiliteItems && listeAccessibiliteItems.map(acc =>
                                    <>{
                                        acc.code.startsWith("PMR") &&
                                        <Form.Check type="checkbox" name={`pmr_${acc.code}]`}
                                                    id={`pmr_${acc.code}`} label={acc.libelle}
                                                    onChange={this.handleChange}
                                                    defaultChecked={this.accessIsCheckec(acc.code, lieuData.accessibilites.pmr)}/>
                                    }</>
                                )}
                                <h2>Équipements</h2>
                                {listeAccessibiliteItems && listeAccessibiliteItems.map(acc =>
                                    <>{
                                        acc.code.startsWith("EQP") &&
                                        <Form.Check type="checkbox" name={`equipements_${acc.code}]`}
                                                    id={`equipements_${acc.code}`} label={acc.libelle}
                                                    onChange={this.handleChange}
                                                    defaultChecked={this.accessIsCheckec(acc.code, lieuData.accessibilites.equipements)}/>
                                    }</>
                                )}
                                <h2>Services</h2>
                                {listeAccessibiliteItems && listeAccessibiliteItems.map(acc =>
                                    <>{
                                        acc.code.startsWith("SERV") &&
                                        <Form.Check type="checkbox" name={`services_${acc.code}]`}
                                                    id={`services_${acc.code}`} label={acc.libelle}
                                                    onChange={this.handleChange}
                                                    defaultChecked={this.accessIsCheckec(acc.code, lieuData.accessibilites.services)}/>
                                    }</>
                                )}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <hr className="hr-1"></hr>
                </Accordion>
                {

                    <Photos initPhotos={lieuData.photos} onChange={this.onPhotoChange} onDelete={this.onPhotoDelete}
                            masterKey={this.props.as === "edit" ? this.props.data.id : "new"}
                            onChangeAltTexte={this.onChangeAltPhoto}/>
                }
                <Horaire onConfigureHoraireChange={this.onConfigureHoraireChange} value={lieuData.horaires}/>
                {this.props.as === "edit" &&  API.getRole() === 'admin' &&
                <Form.Group controlId="validation">
                    <Form.Label>Validation</Form.Label>
                    <Form.Check type="checkbox" label={'Validé et visible sur le site public'}
                                onChange={this.setValid}
                                defaultChecked={lieuData.validation.valid}
                                className="text-left"/>
                </Form.Group>
                }
            </>

        );
    }
}