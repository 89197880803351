import React from "react";
import Form from "react-bootstrap/Form";
import {Alert, Button, Col, ListGroup, Row} from "react-bootstrap";
import API from "../../utils/API";
import {FormLieu} from "../Lieux/FormLieu";

let BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const pageNumber = 1;

export class Step2 extends React.Component {
    state = {
        error: "",
        typeLieu: 'use',
        motifRecherche: '',
        lieux: [],
        execSearch: false
    }

    constructor(props) {
        super(props);
        if (props.getStore()[pageNumber])
            this.state = props.getStore()[pageNumber];
        else
            this.saveData(this.state)
        this._validateOnDemand = true;

        console.debug("satte", this.state);
        //this.refs.user.checkDatas=this.refs.user.checkDatas.bind(this);
        //this.goToNext = this.goToNext.bind(this);
        // this.checkDatas = this.checkDatas.bind(this);

        //On bind car appelé depuis un autre Component
        this.saveData = this.saveData.bind(this);
    }

    saveData = function (data) {
        console.debug("Saving data...", data);
        let store = this.props.getStore();
        store[pageNumber] = data;
        this.props.updateStore({
            ...store,
            savedToCloud: false
        });
    };

    isValidated() {

        // this.setState({error: "Veuillez saisir un mail valide"})
        //return false;

        console.debug(this.props.getStore()[pageNumber])
        if (!this.props.getStore()[pageNumber] || !this.props.getStore()[pageNumber].progSel || (!this.props.getStore()[pageNumber].lieu && !this.props.getStore()[pageNumber].typeLieu==='use')) {
            this.setState({error: "Veuillez choisir un programmateur et un lieux de spectacle valides"})
            return false;
        }
        return true;
    }

    handleChange = (e) => {
        let {id, value} = e.target;
        if (id === 'progId') {
            let progSel;
            this.state.progs.map(prog => {
                if (prog.id === value) {
                    progSel = prog;
                }
            });
            this.setState({[id]: value, progSel: progSel});
        } else if (id === 'progSelLieu') {
            value = e.target.checked
            this.setState({[id]: value}, () => this.saveData(this.state));
        } else
            this.setState({[id]: value}, () => this.saveData(this.state));

    }

    chercheLieux = async () => {
        API.addConfSecurity();
        let apiLieu = new BackofficeDansTousLesSens.LieuApi();
        let searchForm = new BackofficeDansTousLesSens.LieuFinder();
        searchForm.ville = this.state.motifRecherche;
        searchForm.adresse = this.state.motifRecherche;
        searchForm.nom = this.state.motifRecherche;
        searchForm.codePostal = this.state.motifRecherche;
        try {
            let result = await apiLieu.findLieux(searchForm);
            this.setState({
                lieux: result.lieux,
                lieu: null,
                typeLieu: 'use',
                execSearch: true
            }, () => this.saveData(this.state));
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    selectLieu = (data) => {
        this.setState({lieu: data}, () => this.saveData(this.state));
    }

    initCreerLieu = () => {
        this.state.typeLieu='new'
        this.saveData(this.state)
        this.setState({typeLieu: 'new', lieux: []}, () => this.saveData(this.state));
    }


    lieuChange = (data) => {
        this.setState({lieu: data}, () => this.saveData(this.state));
    }

    componentDidMount() {
        const loadProgs = async () => {
            var api = new BackofficeDansTousLesSens.ProgApi();
            if( API.getRole() === 'admin' || API.getRole() === 'root') {
                try {
                    var data = await api.getAllProgs();
                    console.debug(data);
                    if (data) {
                        data.progs.sort(function(a, b) {
                            const nameA = a.nom.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.nom.toUpperCase(); // ignore upper and lowercase
                            return nameA.localeCompare(nameB);
                        });
                        this.setState({progs: data.progs});
                    }
                } catch (error) {
                    console.error(error);
                    if (error.message)
                        alert(error.message)
                }
            }
            else {
                let user = await API.getUser(API.getUsername())
                this.setState({contributeur: user,progId: user.programmateur.id,progSel:user.programmateur});
                console.log("user !!!!!", user)
            }
        };

        loadProgs();
    }

    render() {
        const {error,motifRecherche, lieux, lieu, progs, progId, progSel, progSelLieu, execSearch} = this.state;
        const {typeLieu} = this.props.getStore()[pageNumber];

        return (
            <Form>
                {error && error.length > 0 &&
                <Alert key={"dangerError"} variant={"danger"}>
                    {error}
                </Alert>
                }
                {progId && progId !== '' && progSel &&
                <Alert variant="success">
                    <Alert.Heading>adresse du lieu</Alert.Heading>
                    <p>
                        {console.debug('progsel:', progSel)}
                        {progSel.nom}<br/>
                        {progSel.lieu.adresse}<br/>
                        {progSel.lieu.codePostal}<br/>
                        {progSel.lieu.ville}<br/>
                    </p>
                    {progSel.typeLieu &&
                    <p className="mb-0">
                        Ce programmateur est aussi un lieu de représentation.
                        <Form.Check type="checkbox" name="gestionnaire" id="progSelLieu" label="Choisir ce lieu"
                                    onChange={this.handleChange} defaultChecked={progSelLieu}/>
                    </p>
                    }
                </Alert>

                }
                {(API.getRole() === 'admin' || API.getRole() === 'root') &&
                <Form.Group controlId="progId">
                    <Form.Label>Choix du programmateur</Form.Label>
                    <Form.Control required as="select" onChange={this.handleChange} value={progId}>
                        <option value={''}>Choisir un programmateur</option>
                        {progs && progs.map(prog =>
                            <option key={prog.id} value={prog.id}>{prog.nom}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                }

                {!progSelLieu && typeLieu === 'use' &&
                <>
                    <Form.Group controlId="motifRecherche">
                        <Form.Label>Recherche du lieu</Form.Label>
                        <Form.Control placeholder="Chercher un lieu" onChange={this.handleChange}
                                      value={this.motifRecherche}/>
                        <Button variant="primary" type="button"
                                onClick={this.chercheLieux}>
                            Chercher
                        </Button>
                    </Form.Group>

                    <ListGroup>
                        {lieux && lieux.map(lieuitem =>
                            <ListGroup.Item key={lieuitem.id} active={lieu && lieu.id === lieuitem.id}>
                                <Row>
                                    <Col><h4>{lieuitem.nom}</h4>{lieuitem.codePostal} {lieuitem.ville}</Col>
                                    <Col className="col-6" >{lieuitem.presentation}</Col>
                                    <Col className="col col-lg-2"><Button variant="secondary" onClick={() => {
                                        this.selectLieu(lieuitem);
                                    }}>Choisir</Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )}
                    </ListGroup>

                    {execSearch && !lieu && <div>
                        Si aucun lieu ne correspond, vous pouvez alors le <Button variant="warning" size={"sm"}
                                                                                  onClick={() => {
                                                                                      this.initCreerLieu()
                                                                                  }}>créer</Button>
                    </div>}

                </>
                }
                {!progSelLieu && typeLieu === 'new' &&<><h1>Nouveau Lieu</h1><FormLieu onChange={this.lieuChange}
                                                                        as={"create"}/></>

                }
            </Form>
        )
    }
}