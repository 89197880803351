import React from "react";
import PropTypes from 'prop-types'
import {Accordion, Card, Table, Modal, Button, Form, Alert} from "react-bootstrap";

const listDaysOpenDef = {
    info:'',
    Lundi: {start: '', end: ''},
    Mardi: {start: '', end: ''},
    Mercredi: {start: '', end: ''},
    Jeudi: {start: '', end: ''},
    Vendredi: {start: '', end: ''},
    Samedi: {start: '', end: ''},
    Dimanche: {start: '', end: ''},
};

//revoir la fermeture
export class Horaire extends React.Component/*PureComponent*/ {

    static propTypes = {

        /**
         * Callback on change value
         */
        onConfigureHoraireChange: PropTypes.func.isRequired,
        /**
         * Horaire values
         */
        value:PropTypes.object
    }
    state = {
        days: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
        show: false,
        from: '09:00',
        to: '18:00',
        fromIsValid: true,
        toIsValid: true,
        fromIsInvalid: false,
        toIsInvalid: false,
        errorMessage: '',
        result: this.props.value?this.props.value:{h1: Object.create(listDaysOpenDef), h2: undefined, h3: undefined},
        ferme: false,
        dayEdit: '',
        hEdit: '',
        hKeyEdit: ''

    };

    popupTime = (h, nomJour, hkey) => {
        let ferme = false;
        if (!h || !h[nomJour] ||  (h && !h[nomJour].start && !h[nomJour].end)) ferme = true;

        if(ferme)
        this.setState({
            from: '09:00',
            to: '18:00',
            show: true,
            ferme: ferme,
            hKeyEdit: hkey,
            dayEdit: nomJour
        });
        else
            this.setState({
                from: h[nomJour].start,
                to: h[nomJour].end,
                show: true,
                ferme: ferme,
                hKeyEdit: hkey,
                dayEdit: nomJour
            });
    };

    onHandleFermeChange = async e => {
        this.setState({ferme: e.target.checked});
        if (!e.target.checked) {
            this.setState({from: '09:00'});
            this.setState({to: '18:00'});
        } else {
            this.setState({from: undefined});
            this.setState({to: undefined});
        }
    }

    onHandleTimeChange = async e => {
        const {value, id} = e.target;
        const validKey = id + "IsValid";
        const invalidKey = id + "IsInvalid";

        const testdate = () => {
            if (this.state.fromIsValid && this.state.toIsValid) {

                let datetimeFrom = new Date(`1970-01-01T${this.state.from}:00Z`);
                let datetimeTo = new Date(`1970-01-01T${this.state.to}:00Z`);
                if (datetimeFrom.getTime() > datetimeTo.getTime()) {
                    this.setState({errorMessage: "L'heure de d'ouverture ne peut pas être supérieure à l'heure de fermeture"});
                } else {
                    this.setState({errorMessage: ""});
                }
            }
        }

        if (value === '' || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
            this.setState({[validKey]: true, [invalidKey]: false, [id]: value}, testdate)
        } else {
            this.setState({[validKey]: false, [invalidKey]: true, [id]: value}, testdate)
        }
    };

    handleAddLine = () => {
        let initData = (obj)=>{
            obj = Object.create(listDaysOpenDef);
            obj.Lundi={start:'',end:''};
            obj.Mardi={start:'',end:''};
            obj.Mercredi={start:'',end:''};
            obj.Jeudi={start:'',end:''};
            obj.Vendredi={start:'',end:''};
            obj.Samedi={start:'',end:''};
            obj.Dimanche={start:'',end:''};
            return obj;
        };

        if (!this.state.result.h2 || !this.state.result.h3) {
            let resultvar = this.state.result;
            if (!resultvar.h2){
                resultvar.h2=initData(resultvar.h2);
            }
            else {
                resultvar.h3=initData(resultvar.h3);
            }
            this.setState({result: resultvar})
        }
    }

    handleRemoveLine = (e) => {
        if (this.state.result.h2 || this.state.result.h3) {
            let resultvar = this.state.result;

            if (resultvar.h3) resultvar.h3 = undefined;
            else resultvar.h2 = undefined;
            this.setState({result: resultvar})
        }
    }

    handleValidateHoraire = function handleValidateHoraire(onConfigureHoraireChange) {

        if (this.state.fromIsInvalid || this.state.toIsInvalid) {
            console.debug("Horaire not valide");
            return;
        }
        else if (this.state.ferme){
            var result = this.state.result;
            result[this.state.hKeyEdit][this.state.dayEdit] = {start: undefined, end: undefined};
            this.setState({result: result});
            onConfigureHoraireChange(result);
            this.handleClose();
        }
        else {
            var result = this.state.result;
            console.debug("result:", result);
            console.debug("hkeyEdit", this.state.hKeyEdit);
            console.debug("dayEdit", this.state.dayEdit);
            console.debug("from", this.state.from);
            console.debug("to", this.state.to);

            result[this.state.hKeyEdit][this.state.dayEdit] = {start: this.state.from, end: this.state.to};

            console.debug("beforesave", result);
            this.setState({result: result});
            onConfigureHoraireChange(result);
            this.handleClose();
        }

    };

    getHoraire = (onConfigureHoraireChange) => {
        var popupTime = this.popupTime;

        const ligne = function ligne(horaire, days, key) {
            return <tr key={key}>
                <td key={`${key}cb`}>Info<br/><input type={"text"} onChange={(e)=>{
                    let result= this.state.result;
                    horaire.info=e.target.value;
                    this.setState({result:result});
                    onConfigureHoraireChange(result);
                }} value={horaire.info}/></td>
                {
                    days.map(day =>
                        <td key={`${key}/${day}`} onClick={() => popupTime(horaire, day, key)}>
                            {(horaire[day]===undefined || (horaire[day] && !horaire[day].start && !horaire[day].end)) &&
                            <Alert key={`CLOSE`} variant={"warning"}>
                                FERMÉ!
                            </Alert>
                            }
                            {horaire[day] && horaire[day].start && horaire[day].end &&
                            <div ref={"start"}>{horaire[day].start}</div>}
                            {horaire[day] && horaire[day].start && horaire[day].end &&
                            <div ref={"separator"}>à</div>}
                            {horaire[day] && horaire[day].start && horaire[day].end &&
                            <div ref={"end"}>{horaire[day].end}</div>
                            }
                        </td>
                    )
                }</tr>
        }.bind(this);

        let days = this.state.days;
        const lignes = [];
        console.debug("liste des horarires:", this.state.result);
        if (this.state.result.h1) lignes.push(ligne(this.state.result.h1, days, "h1"));
        if (this.state.result.h2) lignes.push(ligne(this.state.result.h2, days, "h2"));
        if (this.state.result.h3) lignes.push(ligne(this.state.result.h3, days, "h3"));

        return lignes;

    }

    handleClose = (evt) => {
        this.setState({show: false})
    };


    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.value)
            this.setState({result:nextProps.value});
        else {
            this.setState({result:{h1: Object.create(listDaysOpenDef), h2: undefined, h3: undefined}});
        }
    }


    render() {
        const {onConfigureHoraireChange} = this.props;

        const {
            days,
            show,
            from,
            to,
            result,
            fromIsValid,
            toIsValid,
            fromIsinvalid,
            toIsinvalid, errorMessage,
            ferme
        } = this.state;


        return <div>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Configurer les horaires
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            <Table striped bordered hover size="sm">
                                <thead>
                                <tr>
                                    <th>
                                        <Button onClick={() => this.handleAddLine()} disabled={result.h3} size={"sm"}
                                                variant={"secondary"}>+</Button>
                                        <span> </span>
                                        <Button onClick={() => this.handleRemoveLine()} disabled={!result.h2}
                                                size={"sm"} variant={"secondary"}>-</Button>
                                    </th>
                                    {
                                        days.map(dayOfWeek =>
                                            <th key={dayOfWeek}>
                                                {dayOfWeek}
                                            </th>
                                        )
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.getHoraire(onConfigureHoraireChange)}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <hr className="hr-1"></hr>
            </Accordion>

            <Modal show={show} onHide={this.handleClose} animation={false}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Définition de l'horaire</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(!fromIsValid || !toIsValid) &&
                        <Alert key={"danger"} variant={"danger"}>
                            Mauvais format des heures
                        </Alert>
                        }
                        {errorMessage && errorMessage.length > 0 &&
                        <Alert key={"danger"} variant={"danger"}>
                            {errorMessage}
                        </Alert>
                        }
                        <Form.Row>
                            <Form.Group controlId="ferme">
                                <Form.Check type="checkbox" name="ferme" id="ferme" label="Fermé"
                                            checked={this.state.ferme} onChange={this.onHandleFermeChange}/>
                            </Form.Group>
                        </Form.Row>

                        de
                        <Form.Group controlId="from">
                            <Form.Control required type="time" isInvalid={fromIsinvalid}
                                          isValid={fromIsValid} value={from}
                                          onChange={this.onHandleTimeChange}
                                          placeholder="09:00" disabled={ferme}/>
                        </Form.Group> à
                        <Form.Group controlId="to">
                            <Form.Control required isValid={toIsValid} isInvalid={toIsinvalid}
                                          type="time"
                                          value={to} onChange={this.onHandleTimeChange}
                                          placeholder="18:00" disabled={ferme}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="cancel" onClick={this.handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" disabled={!fromIsValid || !toIsValid || errorMessage.length > 0}
                                onClick={() => this.handleValidateHoraire(onConfigureHoraireChange)}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>;
    }
}