import React from "react";
import Form from "react-bootstrap/Form";
import {Alert, Button, Col, ListGroup, Row} from "react-bootstrap";
import API from "../../utils/API";
import {FormSpec} from "../Spec/FormSpec";

let BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const pageNumber = 0

export class Step1 extends React.Component {
    state = {
        nomSpectacle: '',
        spectacles: [],
        typeSpectacle: 'use',
        error: "",
        spectacle: null,
        execSearch: false,
        listeAqui: [],
        listeComment: [],
        listeAdaptation: [],
        listeDispositif: [],
    }

    constructor(props) {
        super(props);
        if (props.getStore()[pageNumber])
            this.state = props.getStore()[pageNumber];
        else
            this.saveData(this.state)
        this._validateOnDemand = true;

        console.debug("state", this.state);
        //this.refs.user.checkDatas=this.refs.user.checkDatas.bind(this);
        //this.goToNext = this.goToNext.bind(this);
        // this.checkDatas = this.checkDatas.bind(this);

        //On bind car appelé depuis un autre Component
        this.saveData = this.saveData.bind(this);
    }

    componentDidMount() {
        API.addConfSecurity();
        var apiData = new BackofficeDansTousLesSens.DataRefApi();
        let aquiItems = [];
        let commentItems = [];
        let adaptationItems = [];
        let dispositifItems = [];

        apiData.getAllAqui().then(
            function success(data) {
                aquiItems = data;
                callback();
            },
            function error(data) {

            }
        );
        apiData.getAllComment().then(
            function success(data) {
                commentItems = data;
                callback();
            },
            function error(data) {

            }
        );
        apiData.getAllAdaptation().then(
            function success(data) {
                adaptationItems = data;
                callback();
            },
            function error(data) {

            }
        )
        apiData.getAllDispositif().then(
            function success(data) {
                dispositifItems = data;
                callback();
            },
            function error(data) {

            }
        )

        const callback = () => {
            console.log(aquiItems)
            this.setState({
                listeAqui: aquiItems,
                listeComment: commentItems,
                listeAdaptation: adaptationItems,
                listeDispositif: dispositifItems,
            })
        }
    }

    saveData = function (data) {
        console.debug("Saving data...", data);
        let store = this.props.getStore();
        store[pageNumber] = data;
        this.props.updateStore({
            ...store,
            savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
        });
    };

    isValidated() {
        console.debug(this.props.getStore()[pageNumber])
        if (!this.props.getStore()[pageNumber] || !this.props.getStore()[pageNumber].spectacle || !this.props.getStore()[pageNumber].spectacle.titre) {
            this.setState({error: "Veuillez créer ou choisir un spectacle"})
            return false;
        }
        return true;
    }

    handleChange = (e) => {
        const {id, value} = e.target;
        this.setState({[id]: value});
    }

    spectacleChange = (data) => {
        console.debug("data", data);
        this.setState({spectacle: data}, () => this.saveData(this.state));
    }


    chercheSpectacle = async () => {
        API.addConfSecurity();
        let apiSpec = new BackofficeDansTousLesSens.SpecApi();
        let searchForm = new BackofficeDansTousLesSens.SpectacleFinder();
        searchForm.nom = this.state.nomSpectacle;
        try {
            let spectacles = await apiSpec.findSpectacles(searchForm);
            console.debug(spectacles)
            this.setState({spectacles: spectacles.specs, spectacle: null, execSearch: true});
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    selectSpectacle = (data) => {
        console.debug('spec', data);
        this.setState({spectacle: data}, () => this.saveData(this.state));

    }

    initCreerSpectacle = () => {
        this.setState({typeSpectacle: 'new', spectacles: []})
        this.saveData(this.state);
    }

    render() {
        const {
            error,
            nomSpectacle,
            spectacles,
            typeSpectacle,
            spectacle,
            execSearch,
            listeAqui,
            listeComment,
            listeAdaptation,
            listeDispositif,
        } = this.state;
        return (
            <Form>
                {error && error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                }
                {typeSpectacle === 'use' &&
                    <>
                        <Form.Group controlId="nomSpectacle">
                            <Form.Label>Nom du spectacle</Form.Label>
                            <Form.Control placeholder="Nom du spectacle"
                                          onChange={this.handleChange} value={nomSpectacle}/>
                            <Button variant="primary" type="button"
                                    disabled={(!nomSpectacle || nomSpectacle.length < 3) === true}
                                    onClick={this.chercheSpectacle}>
                                Valider/chercher spectacle
                            </Button>
                        </Form.Group>

                        <ListGroup>
                            {spectacles && spectacles.map(spec =>
                                <>
                                    {(!spectacle || spectacle.id !== spec.id) &&
                                        <ListGroup.Item key={spec.titre + spec.id}>
                                            <Row>
                                                <Col>
                                                    <h4>{spec.titre}</h4>
                                                    <br/>
                                                    <div>{spec.compagnie}</div>
                                                    <br/>
                                                    <div>
                                                        {listeAqui && listeAqui.map(acc =>
                                                            <>{spec.accessibilite.aqui && spec.accessibilite.aqui.map(item => <>{
                                                                    item.code === acc.code && item.selected &&
                                                                    <img title={acc.libelle}
                                                                         alt={acc.libelle}
                                                                         width={40}
                                                                         src={'http://dtls2.org/images/picto/spectacle/AQUI-' + item.code + '.png'}>
                                                                    </img>
                                                                }</>
                                                            )}</>
                                                        )}
                                                        {listeComment && listeComment.map(acc =>
                                                            <>{spec.accessibilite.comment && spec.accessibilite.comment.map(item => <>{
                                                                    item.code === acc.code && item.selected &&
                                                                    <img title={acc.libelle}
                                                                         alt={acc.libelle}
                                                                         width={40}
                                                                         src={'http://dtls2.org/images/picto/spectacle/COMMENT-' + item.code + '.png'}>
                                                                    </img>
                                                                }</>
                                                            )}</>
                                                        )}
                                                        {listeAdaptation && listeAdaptation.map(acc =>
                                                            <>{spec.accessibilite.adaptation && spec.accessibilite.adaptation.map(item => <>{
                                                                    item.code === acc.code && item.selected &&
                                                                    <img title={acc.libelle}
                                                                         alt={acc.libelle}
                                                                         width={40}
                                                                         src={'http://dtls2.org/images/picto/spectacle/ADAPTATION-' + item.code + '.png'}>
                                                                    </img>
                                                                }</>
                                                            )}</>
                                                        )}
                                                        {listeDispositif && listeDispositif.map(acc =>
                                                            <>{spec.accessibilite.dispositif && spec.accessibilite.dispositif.map(item => <>{
                                                                    item.code === acc.code && item.selected &&
                                                                    <img title={acc.libelle}
                                                                         alt={acc.libelle}
                                                                         width={40}
                                                                         src={'http://dtls2.org/images/picto/spectacle/DISPOSITIF-' + item.code + '.png'}>
                                                                    </img>
                                                                }</>
                                                            )}</>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col className="col-7">{spec.presentation.substring(0, 400)} {spec.presentation.length >= 400 && '...'}
                                                    <br/><br/>{spec.distribution.substring(0, 300)} {spec.distribution.length >= 300 && '...'}</Col>
                                                <Col className="col col-lg-2"><Button variant="secondary" onClick={() => {
                                                    this.selectSpectacle(spec);
                                                }}>Choisir</Button>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    }
                                </>
                            )}
                        </ListGroup>

                        {spectacle &&
                            <>
                                <Alert key={"info-sel-spec"} variant={"info"}>
                                    Spectacle choisi!
                                </Alert>
                                <ListGroup>

                                    <ListGroup.Item key={spectacle.titre} active={true}>
                                        <Row>
                                            <Col>
                                                <h4>{spectacle.titre}</h4>
                                                <br/>
                                                <div>{spectacle.compagnie}</div>
                                                <br/>
                                                <div>
                                                    {listeAqui && listeAqui.map(acc =>
                                                        <>{spectacle.accessibilite.aqui && spectacle.accessibilite.aqui.map(item => <>{
                                                                item.code === acc.code && item.selected &&
                                                                <img title={acc.libelle}
                                                                     alt={acc.libelle}
                                                                     width={40}
                                                                     src={'http://dtls2.org/images/picto/spectacle/AQUI-' + item.code + '.png'}>
                                                                </img>
                                                            }</>
                                                        )}</>
                                                    )}
                                                    {listeComment && listeComment.map(acc =>
                                                        <>{spectacle.accessibilite.comment && spectacle.accessibilite.comment.map(item => <>{
                                                                item.code === acc.code && item.selected &&
                                                                <img title={acc.libelle}
                                                                     alt={acc.libelle}
                                                                     width={40}
                                                                     src={'http://dtls2.org/images/picto/spectacle/COMMENT-' + item.code + '.png'}>
                                                                </img>
                                                            }</>
                                                        )}</>
                                                    )}
                                                    {listeAdaptation && listeAdaptation.map(acc =>
                                                        <>{spectacle.accessibilite.adaptation && spectacle.accessibilite.adaptation.map(item => <>{
                                                                item.code === acc.code && item.selected &&
                                                                <img title={acc.libelle}
                                                                     alt={acc.libelle}
                                                                     width={40}
                                                                     src={'http://dtls2.org/images/picto/spectacle/ADAPTATION-' + item.code + '.png'}>
                                                                </img>
                                                            }</>
                                                        )}</>
                                                    )}
                                                    {listeDispositif && listeDispositif.map(acc =>
                                                        <>{spectacle.accessibilite.dispositif && spectacle.accessibilite.dispositif.map(item => <>{
                                                                item.code === acc.code && item.selected &&
                                                                <img title={acc.libelle}
                                                                     alt={acc.libelle}
                                                                     width={40}
                                                                     src={'http://dtls2.org/images/picto/spectacle/DISPOSITIF-' + item.code + '.png'}>
                                                                </img>
                                                            }</>
                                                        )}</>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col>{spectacle.presentation}<br/><br/>{spectacle.distribution.substring(0, 300)} {spectacle.distribution.length >= 300 && '...'}</Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </>
                        }
                        {execSearch && !spectacle && <div>
                            Si aucun spectacle correspond, vous pouvez alors le <Button variant="warning" size={"sm"}
                                                                                        title={"Aucun spectacle ne correspond"}
                                                                                        onClick={() => {
                                                                                            this.initCreerSpectacle()
                                                                                        }}>créer</Button>
                        </div>}

                    </>
                }
                {typeSpectacle === 'new' && <><h1>Nouveau Spectacle</h1><FormSpec onChange={this.spectacleChange}
                                                                                  as={"create"} data={spectacle}/></>

                }

            </Form>
        )
    }

}