const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
export default {
    initApi: () => {
        const api = BackofficeDansTousLesSens.ApiClient.instance
        api.basePath = process.env.REACT_APP_API_URL
    },

    login: async function(identifiant, password) {
        this.initApi()
        const userApi = new BackofficeDansTousLesSens.DefaultApi()
        let auth = new BackofficeDansTousLesSens.Authentication()

        auth.username=identifiant;
        auth.password=password;
        return await userApi.loginPost(auth);
    },

    isAuth: function() {
        return localStorage.getItem("token") !== null;
    },
    logout: function() {
        localStorage.clear();
    },
    get: function() {
        return localStorage.getItem("token");
    },

    getUsername: function (){
            const base64Url = this.get().split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload).sub;
    },
    getRole: function (){
        if(this.get()) {
            const base64Url = this.get().split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            console.log(JSON.parse(jsonPayload))
            return JSON.parse(jsonPayload).role;
        }
    },
    getPid: function (){
            const base64Url = this.get().split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            console.log(JSON.parse(jsonPayload))
            return JSON.parse(jsonPayload).pid;
    },

    getUser : function (login) {
        this.initApi()
        const userApi = new BackofficeDansTousLesSens.UserApi()

        console.log("get user ",login);
        return userApi.getOne(login)
    },

    addConfSecurity: function () {
        var cli = BackofficeDansTousLesSens.ApiClient.instance;
        cli.authentications = {
            'Bearer': {
                type: 'bearer',
                'in': 'header',
                name: 'Authorization',
                accessToken: this.get()
            }
        };
    }
};