import React from "react";
import {ListGroup, Button, Alert, Jumbotron, Accordion, Card} from "react-bootstrap";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ListUser extends React.Component {
    state = {
        progs: [],
        messageSuccess: "",
        messageError: ""
    };

    componentDidMount() {

        const loadUsers = async () => {
            var api = new BackofficeDansTousLesSens.UserApi();
            var apiProg = new BackofficeDansTousLesSens.ProgApi();

            try {
                var data = await api.getAll();
                let allProgs = await apiProg.getAllProgs();
                allProgs.progs.sort(function(a, b) {
                    const nameA = a.nom.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.nom.toUpperCase(); // ignore upper and lowercase
                    return nameA.localeCompare(nameB);
                });
                allProgs.progs.forEach(prog => {
                    if (!prog.users) prog.users = []
                    data.users.forEach(user => {
                        if (prog.id === user.programmateur) prog.users.push(user)
                    })
                });
                if (allProgs) {
                    this.setState({progs: allProgs.progs});

                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }

        };
        API.addConfSecurity();
        loadUsers();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    deleteUser = async (user2Del) => {
        if (window.confirm(`Êtes vous sûr de vouloir supprimer l'utilisateur ${user2Del.prenom} ${user2Del.nom}?`)) {
            var api = new BackofficeDansTousLesSens.UserApi();
            try {
                var result = await api.delUser(user2Del.login)
                if (result.error) {
                    this.setState({messageError: "Impossible de supprimer l'tilisateur " + user2Del.login});
                } else {
                    this.componentDidMount();
                    this.setState({messageSuccess: "Utilisateur " + user2Del.login + " supprimé"});
                    this.props.onSelect();
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    render() {
        const {onSelect} = this.props;
        const {
            progs,
            messageError,
            messageSuccess
        } = this.state;

        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h5>Liste des utilisateurs</h5>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                <Alert key={"successDel"} variant={"success"}>
                    {messageSuccess}
                </Alert>
                }
                {messageError.length > 0 &&
                <Alert key={"errorDel"} variant={"danger"}>
                    {messageError}
                </Alert>
                }
                <Accordion defaultActiveKey="0">
                    {
                        progs.map(prog =>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        {prog.nom}
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ListGroup>
                                            {
                                                prog.users.map(user =>
                                                    <ListGroup.Item key={user.id}>
                                                        <div className={'listAction'}>
                                                            <Button className={'itemListMax'} onClick={() => {
                                                                onSelect(user)
                                                            }}> {user.nom}</Button>
                                                            <Button className={'deleteItem'} variant="danger" size={"sm"} onClick={() => {
                                                                this.deleteUser(user)
                                                            }}>X</Button>
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            }
                                        </ListGroup>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}
                </Accordion>
            </div>
        );
    }
}