import React from "react";
import {Form, Col, Row, Button, Image, Container, Media, Modal, Alert, Card} from "react-bootstrap";
import PropTypes from 'prop-types'

export class Videos extends React.Component {

    static propTypes = {
        /**
         * Init datas videos
         */
        initVideos: PropTypes.array,
        /**
         * Callback on change photo
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Unique masterKey-> pour diff des photos si changement d'objet maitre
         */
        masterKey: PropTypes.string.isRequired,
        /**
         * Callback on delete photo
         */
        onDelete: PropTypes.func.isRequired,
        /**
         * Callback on change value
         */
        //onChangeAltTexte: PropTypes.func.isRequired,

    }

    state = {
        show: false,
        videos: [],
        max: -1,
        labelVideo: '',
        errorMessage: '',
        result: this.props.value,

        success: "", error: ""
    };

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.masterKey!==this.props.masterKey || nextProps.initVideos.length !== this.props.initVideos.length
    }

    handleChange = (e) => {
        console.debug("handleChange");

        var {id, value} = e.target

        this.setState({
                [id]: value
            }
        );
    };

    componentDidMount() {
        console.debug("componentDidMount")
        console.debug("this.props.initVideos",this.props.initVideos)
        // this.setState({
        //     url: "",
        //     titre: "",
        //     texteAlternatif: "",
        //     description: "",
        //     indexVideo: "",
        //     show: true,
        // });

        if(this.props.initVideos)
            this.setState({videos: this.props.initVideos});

    }


    handleAltChange = async e => {
        const {value, id} = e.target;
        console.debug("handleAltChange")
        console.debug(id,value)
        this.setState({[id]: value})
    }

    handleCreationVideo = () => {
        console.debug("handleAjouterVideo");

        this.setState({
            url: "",
            titre: "",
            texteAlternatif: "",
            description: "",
            show: true
        });
    }
    handleModifierVideo = (video, index) => {
        // handleModifier
        console.debug("handleAjouterVideo");

        var popupAltTexte = this.popupAltTexte(video, index);
        this.setState({show: true})
    }

    popupAltTexte = (video, index) => {
        this.setState({
            url: video.url,
            titre: video.titre,
            texteAlternatif: video.texteAlternatif,
            description: video.description,
            indexVideo: index,
            show: true,
        });
    }

    handleClose = (evt) => {
        this.setState({show: false})
    };

    handleValidateVideo = () => {
        // handleValidateAltTexte
        console.debug("handleValidateVideo");
        console.debug(this.state.indexVideo);

        if ( this.state.indexVideo == undefined ) {
            console.debug("new");

            let newVideo = {}
            newVideo.url = this.state.url?this.state.url:"";
            newVideo.titre = this.state.titre?this.state.titre:"";
            newVideo.texteAlternatif = this.state.texteAlternatif?this.state.texteAlternatif:"";
            newVideo.description = this.state.description?this.state.description:"";

            /*newVideo.url = this.state.url;
            newVideo.titre = this.state.titre;
            newVideo.texteAlternatif = this.state.texteAlternatif;
            newVideo.description = this.state.description;*/

            this.state.videos.push(newVideo);

            this.props.onChangeAltTexte(0, newVideo);

        } else {
            console.debug("edit");
            console.debug(this.state.indexVideo);

            let video = this.state.videos[this.state.indexVideo];

            video.url = this.state.url?this.state.url:"";
            video.titre = this.state.titre?this.state.titre:"";
            video.texteAlternatif = this.state.texteAlternatif?this.state.texteAlternatif:"";
            video.description = this.state.description?this.state.description:"";

            /*video.url = this.state.url;
            video.titre = this.state.titre;
            video.texteAlternatif = this.state.texteAlternatif;
            video.description = this.state.description;*/

            this.props.onChangeAltTexte(this.state.indexVideo, video);
            this.props.onChange(video.id, video);
        }

        this.handleClose();
    }

    handleOpenWindow = (video) => {
        window.open(video.url,
            'video',
            'toolbar=0,status=0,width=640,height=640');
    }

    render() {
        const {
            show, videos, labelVideo,
            url, titre, texteAlternatif, description,
            errorMessage
        } = this.state;

        return (
            <>
                <h3 className="text-left">Videos</h3>
                <Card>
                    <Container>
                        <Row>
                            <Col md={8}>
                            </Col>
                            <Col md={4}>
                                <Button className="btn btn-light" onClick={() => this.handleCreationVideo()}>
                                    Ajouter une nouvelle vidéo
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                <hr className="hr-1"></hr>
                <Form.Group controlId="container-videos">
                    {videos && videos.map(
                        (video, index) =>
                            <>
                                <Card>
                                    <Container>
                                        <Row>
                                            <Col md={1}>
                                                <Row>
                                                    <p>
                                                        <span className="badge badge-secondary">#{index+1}</span>
                                                    </p>
                                                </Row>
                                            </Col>
                                            <Col md={11}>
                                                <div>
                                                    <hr className="hr-1"></hr>
                                                </div>
                                                <Media.Body className="text-left">
                                                    <h5>Titre: {video.titre ? video.titre : "vide"}</h5>
                                                    <p><small>
                                                        <strong>URL de la vidéo:</strong> {video.url ? video.url : "vide"}
                                                    </small></p>
                                                    <p><small>
                                                        <strong>Description:</strong> {video.description ? video.description : "vide"}
                                                    </small></p>
                                                </Media.Body>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>

                                            </Col>
                                            <Col className="text-right">
                                                <Button size={"sm"} onClick={() => this.handleOpenWindow(video)}>
                                                    <small>Ouvrir la vidéo</small>
                                                </Button>
                                                <Button size={"sm"} onClick={() => this.handleModifierVideo(video, index)}>
                                                    <small>Modifier</small>
                                                </Button>
                                                <Button size={"sm"} variant="danger" onClick={() => {
                                                    if (window.confirm(`Supprimer la vidéo`)) this.props.onDelete(video.id)
                                                }}><small>x</small></Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                                <hr className="hr-1"></hr>
                            </>
                    )}
                </Form.Group>



                <Modal show={show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Vidéo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && errorMessage.length > 0 &&
                        <Alert key={"danger"} variant={"danger"}>
                            {errorMessage}
                        </Alert>
                        }
                        <Form.Group controlId="titre">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control placeholder="Titre" value={titre}
                                          onChange={this.handleAltChange}/>
                        </Form.Group>
                        <Form.Group controlId="url">
                            <Form.Label>Url de la vidéo</Form.Label>
                            <Form.Control type={"url"} placeholder="Url de la vidéo" value={url}
                                          onChange={this.handleAltChange}/>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control placeholder="Description" value={description}
                                          as="textarea" onChange={this.handleAltChange}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="cancel" onClick={this.handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" disabled={errorMessage.length > 0}
                                onClick={() => this.handleValidateVideo()}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}