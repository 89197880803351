import React from "react";
import {ListGroup, Button, Alert, Jumbotron, FormControl, Form, Pagination} from "react-bootstrap";
import API from "../../utils/API";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ListSpec extends React.Component {
    state = {
        specs: [],
        spectaclesFiltered: [],
        messageSuccess: "",
        messageError: "",
        maxPerPage: 10,
        nbItemsPager: 7,
        pageNumber: 0,
        min: 0,
        max: 10,
        filterText: "",
        filterValid: "all",
    };

    componentDidMount() {
        API.addConfSecurity();
        this.filterList();
        this.setPageNumber(this.state.pageNumber);
        let pageNumber=this.state.pageNumber;
        const loadSpecs = async () => {
            const api = new BackofficeDansTousLesSens.SpecApi();
            try {
                const data = await api.getAllSpecs();
                if (data) {
                    data.specs.sort(function(a, b) {
                        const nameA = a.titre.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.titre.toUpperCase(); // ignore upper and lowercase
                       return nameA.localeCompare(nameB);
                    });
                    console.debug("specs", data.specs);
                    this.setState({specs: data.specs, spectaclesFiltered: data.specs});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.filterList();
            this.setPageNumber(pageNumber);
        };
        loadSpecs();
    }

    filterList = (event) => {
        let filterText = this.state.filterText;
        let filterValid = this.state.filterValid;
        if (event) {
            this.setPageNumber(0);
            if (event.target.type === 'radio' && event.target.id === "notvalid") filterValid = "false";
            else if (event.target.type === 'radio' && event.target.id === "valid") filterValid = "true";
            else if (event.target.type === 'radio' && event.target.id !== "valid" && event.target.id !== "notvalid") filterValid = "all";
            else filterText = event.target.value.toLowerCase();
            this.setState({filterText: filterText, filterValid: filterValid});
        }
        //premier filtre sur la validation
        let filteredItemsValidation = this.state.specs.filter(function (spectacle) {
            console.debug(`filters : filterValid= ${filterValid} filterText= ${filterText}`);
            if (filterValid.toLowerCase() === "true" || filterValid.toLowerCase() === "false")
                return spectacle.validation.valid === (filterValid === "true");
            else return spectacle;
        });
        //second filtre sur le text
        let filteredItems = filteredItemsValidation.filter(function (spectacle) {
            console.debug(`filters : filterValid= ${filterValid} filterText= ${filterText}`);
            if (filterText !== "") return (spectacle.titre.toLowerCase().search(filterText) > -1)
            else return filteredItemsValidation
        });

        console.debug(`filter : nb= ${filteredItems.length}`);
        this.setState({spectaclesFiltered: filteredItems});


    }


    deleteSpec = async (spec2Del) => {
        if (window.confirm(`Êtes vous sûr de vouloir supprimer le spectacle ${spec2Del.titre} ?`)) {
            var api = new BackofficeDansTousLesSens.SpecApi();
            try {
                var result = await api.delSpec(spec2Del.id)
                if (result.error) {
                    this.setState({messageError: "Impossible de supprimer le spectacle " + spec2Del.id});
                } else {
                    this.componentDidMount();
                    this.setState({messageSuccess: "Spectacle " + spec2Del.id + " supprimé"});
                    this.props.onSelect();
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    setPageNumber = (pageNumber) => {
        if (pageNumber < 0) pageNumber = 0;
        let lastPage = Math.ceil(this.state.spectaclesFiltered.length / this.state.maxPerPage);
        if (pageNumber>lastPage-1) pageNumber=lastPage-1;
        console.log(`lastPage : ${lastPage}`);
        console.log(`setPageNumber : ${pageNumber}`);
        let min = ((pageNumber * this.state.maxPerPage) > this.state.spectaclesFiltered.length) ? 0 : (pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;

        this.setState({pageNumber: pageNumber, min: min, max: max});
    }

    getPageItems = () => {
        let pageItems = [];
        let minPage;
        console.debug("nb spectacles : ", this.state.spectaclesFiltered.length);
        let nbMaxPage = Math.ceil(this.state.spectaclesFiltered.length / this.state.maxPerPage);
        let halfItemsPager = Math.ceil(this.state.nbItemsPager / 2);
        if (nbMaxPage <= this.state.nbItemsPager || this.state.pageNumber < halfItemsPager || this.state.pageNumber > nbMaxPage) minPage = 0;
        else if (this.state.pageNumber > nbMaxPage - halfItemsPager) minPage = nbMaxPage - this.state.nbItemsPager;
        else minPage = this.state.pageNumber - halfItemsPager;
        let nbMaxItem = minPage + this.state.nbItemsPager;
        if (nbMaxPage < nbMaxItem) nbMaxItem = nbMaxPage;
        for (let i = minPage; i < nbMaxItem; i++) {
            pageItems.push(<Pagination.Item key={i} active={i === this.state.pageNumber}
                                            onClick={() => this.setPageNumber(i)}>{i + 1}</Pagination.Item>)
        }
        console.debug("nb pages : ", nbMaxPage);
        return pageItems;
    }

    render() {
        const {onSelect} = this.props;
        const {
            spectaclesFiltered,
            messageError,
            messageSuccess,
            maxPerPage,
            min,
            max
        } = this.state;

        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h5>Liste des spectacles</h5>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                <Alert key={"successDel"} variant={"success"}>
                    {messageSuccess}
                </Alert>
                }
                {messageError.length > 0 &&
                <Alert key={"errorDel"} variant={"danger"}>
                    {messageError}
                </Alert>
                }

                <FormControl type="text" placeholder="filter" className="mr-sm-2" onChange={this.filterList}/>
                <Form inline>
                    <Form.Check type="radio" label="à valider " name="valid" id="notvalid" onChange={this.filterList}/>
                    <Form.Check type="radio" label="validés " name="valid" id="valid" onChange={this.filterList}/>
                    <Form.Check type="radio" label="tous " name="valid" id="all" onChange={this.filterList}/>
                </Form>
                {this.state.spectaclesFiltered.length > maxPerPage &&
                <Pagination size={'sm'}>
                    <>
                        {this.state.spectaclesFiltered.length / maxPerPage > this.state.nbItemsPager &&
                            <><Pagination.First onClick={() => this.setPageNumber(0)}/>
                                <Pagination.Prev onClick={() => this.setPageNumber(this.state.pageNumber - 1)}/>
                            </>
                        }
                        {this.state.spectaclesFiltered.length > maxPerPage &&
                            this.getPageItems()
                        }
                        {this.state.spectaclesFiltered.length / maxPerPage > this.state.nbItemsPager &&
                            <> <Pagination.Next onClick={() => this.setPageNumber(this.state.pageNumber + 1)}/>
                                <Pagination.Last
                                    onClick={() => this.setPageNumber(this.state.spectaclesFiltered.length / maxPerPage)}/></>
                        }
                    </>
                </Pagination>
                }
                <ListGroup>
                    {
                        spectaclesFiltered.slice(min, max).map(spectacle =>
                            <ListGroup.Item key={spectacle.id}>
                                <div className={'listAction'}>
                                    <Button className={'itemListMax'} onClick={() => {
                                        onSelect(spectacle)
                                    }}> &nbsp;&nbsp;&nbsp;{spectacle.titre}
                                    </Button>
                                    <Button variant="danger" size={"sm"} className={'deleteItem'} onClick={() => {
                                        this.deleteSpec(spectacle)
                                    }}>X</Button>
                                
                                </div>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </div>
        );
    }
}
