import React from "react";
import {UserFields} from "../User/UserFields";
import Form from "react-bootstrap/Form";
import {FormProg} from "./FormProg";
import {Alert} from "react-bootstrap";

export class Step1 extends React.Component {
    state = {
        error: ""
    }

    constructor(props) {
        super(props);
        this.state = props.getStore();
        this._validateOnDemand = true;
        //this.refs.user.checkDatas=this.refs.user.checkDatas.bind(this);
        //this.goToNext = this.goToNext.bind(this);
        // this.checkDatas = this.checkDatas.bind(this);

        //On bind car appelé depuis un autre Component
        this.saveData = this.saveData.bind(this);
    }

    saveData = function (data) {
        console.debug("Saving data...", data);
        let store = this.props.getStore();
        store[0] = data;
        this.props.updateStore({
            ...store,
            savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
        });
    };

    isValidated() {
        if (!this.props.getStore()[0] || !this.props.getStore()[0].nom || this.props.getStore()[0].nom.length == 0) {
            this.setState({error: "Veuillez saisir un nom"})
            return false;
        }
        return true;
    }

    render() {
        const {error} = this.state;
        return (
            <Form>
                {error && error.length > 0 &&
                <Alert key={"dangerError"} variant={"danger"}>
                    {error}
                </Alert>
                }
                <FormProg onChange={this.saveData} data={this.props.getStore()[0]} as={"create"}
                          key={"setp1-formprog"}/>
            </Form>
        )
    }
}