import React from "react";
import {ListGroup, Button, Alert, Jumbotron, Form, FormControl, Pagination} from "react-bootstrap";
import API from "../../utils/API";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ListLieux extends React.Component {
    state = {
        lieux: [],
        lieuxFiltered: [],
        messageSuccess: "",
        messageError: "",
        maxPerPage: 5,
        nbItemsPager: 7,
        pageNumber: 0,
        min: 0,
        max: 5,
        filterText: "",
        filterValid: "all",
    };

    componentDidMount() {
        API.addConfSecurity();
        this.filterList();
        this.setPageNumber(this.state.pageNumber);
        let pageNumber = this.state.pageNumber;
        const lieux = async () => {
            const api = new BackofficeDansTousLesSens.LieuApi();
            try {
                const data = await api.getAllLieux();
                if (data) {
                    data.lieux.sort(function (a, b) {
                        const nameA = a.nom.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.nom.toUpperCase(); // ignore upper and lowercase
                        return nameA.localeCompare(nameB);
                    });
                    console.debug("lieux", data);
                    this.setState({lieux: data.lieux, lieuxFiltered: data.lieux});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.filterList();
            this.setPageNumber(pageNumber);
        };
        lieux();
    }

    filterList = (event) => {
        let filterText = this.state.filterText;
        let filterValid = this.state.filterValid;
        if (event) {
            this.setPageNumber(0);
            if (event.target.type === 'radio' && event.target.id === "notvalid") filterValid = "false";
            else if (event.target.type === 'radio' && event.target.id === "valid") filterValid = "true";
            else if (event.target.type === 'radio' && event.target.id !== "valid" && event.target.id !== "notvalid") filterValid = "all";
            else filterText = event.target.value.toLowerCase();
            this.setState({filterText: filterText, filterValid: filterValid});
        }
        //premier filtre sur la validation
        let filteredItemsValidation = this.state.lieux.filter(function (lieu) {
            console.debug(`filters : filterValid= ${filterValid} filterText= ${filterText}`);
            if (filterValid.toLowerCase() === "true" || filterValid.toLowerCase() === "false")
                return lieu.validation.valid === (filterValid === "true");
            else return lieu;
        });
        //second filtre sur le text
        let filteredItems = filteredItemsValidation.filter(function (lieu) {
            console.debug(`filters : filterValid= ${filterValid} filterText= ${filterText}`);
            if (filterText !== "") return (lieu.nom.toLowerCase().search(filterText) > -1);
            else return filteredItemsValidation;
        });

        console.debug(`filter : nb= ${filteredItems.length}`);
        this.setState({lieuxFiltered: filteredItems});
    }

    deleteLieu = async (lieu2Del) => {
        if (window.confirm(`Êtes vous sûr de vouloir supprimer le lieu ${lieu2Del.nom} ?`)) {
            try {
                const api = new BackofficeDansTousLesSens.LieuApi();
                let result = await api.deleteLieu(lieu2Del.id);
                if (result.error) {
                    this.setState({messageError: "Impossible de supprimer le lieu " + lieu2Del.nom});
                } else {
                    this.componentDidMount();
                    this.setState({messageSuccess: "Lieu " + lieu2Del.nom + " supprimé"});
                    this.props.onSelect();
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    setPageNumber = (pageNumber) => {
        if (pageNumber < 0) pageNumber = 0;
        let lastPage = Math.ceil(this.state.lieuxFiltered.length / this.state.maxPerPage);
        if (pageNumber>lastPage-1) pageNumber=lastPage-1;
        console.log(`lastPage : ${lastPage}`);
        console.log(`setPageNumber : ${pageNumber}`);
        let min = ((pageNumber * this.state.maxPerPage) > this.state.lieuxFiltered.length) ? 0 : (pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;

        this.setState({pageNumber: pageNumber, min: min, max: max});
    }

    getPageItems = () => {
        let pageItems = [];
        let minPage;
        console.debug("nb lieux : ", this.state.lieuxFiltered.length);
        let nbMaxPage = Math.ceil(this.state.lieuxFiltered.length / this.state.maxPerPage);
        let halfItemsPager = Math.ceil(this.state.nbItemsPager / 2);
        if (nbMaxPage <= this.state.nbItemsPager || this.state.pageNumber < halfItemsPager || this.state.pageNumber > nbMaxPage) minPage = 0;
        else if (this.state.pageNumber > nbMaxPage - halfItemsPager) minPage = nbMaxPage - this.state.nbItemsPager;
        else minPage = this.state.pageNumber - halfItemsPager;
        let nbMaxItem = minPage + this.state.nbItemsPager;
        if (nbMaxPage < nbMaxItem) nbMaxItem = nbMaxPage;
        for (let i = minPage; i < nbMaxItem; i++) {
            pageItems.push(<Pagination.Item key={i} active={i === this.state.pageNumber}
                                            onClick={() => this.setPageNumber(i)}>{i + 1}</Pagination.Item>)
        }
        console.debug("nb pages : ", nbMaxPage);
        return pageItems;
    }

    render() {
        const {onSelect} = this.props;
        const {
            lieuxFiltered,
            maxPerPage,
            min, max,
            messageError,
            messageSuccess
        } = this.state;
        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h5>Liste des Lieux</h5>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                    <Alert key={"successDel"} variant={"success"}>
                        {messageSuccess}
                    </Alert>
                }
                {messageError.length > 0 &&
                    <Alert key={"errorDel"} variant={"danger"}>
                        {messageError}
                    </Alert>
                }

                <FormControl type="text" placeholder="filter" className="mr-sm-2" onChange={this.filterList}/>
                <Form inline>
                    <Form.Check type="radio" label="à valider " name="valid" id="notvalid" onChange={this.filterList}/>
                    <Form.Check type="radio" label="validés " name="valid" id="valid" onChange={this.filterList}/>
                    <Form.Check type="radio" label="tous " name="valid" id="all" onChange={this.filterList}/>

                </Form>
                {this.state.lieuxFiltered.length > maxPerPage &&
                    <Pagination size={'sm'}>
                        <>
                            {this.state.lieuxFiltered.length / maxPerPage > this.state.nbItemsPager &&
                                <><Pagination.First onClick={() => this.setPageNumber(0)}/>
                                    <Pagination.Prev onClick={() => this.setPageNumber(this.state.pageNumber - 1)}/>
                                </>
                            }
                            {this.state.lieuxFiltered.length > maxPerPage &&
                                this.getPageItems()
                            }
                            {this.state.lieuxFiltered.length / maxPerPage > this.state.nbItemsPager &&
                                <> <Pagination.Next onClick={() => this.setPageNumber(this.state.pageNumber + 1)}/>
                                    <Pagination.Last
                                        onClick={() => this.setPageNumber(this.state.lieuxFiltered.length / maxPerPage)}/></>
                            }
                        </>
                    </Pagination>
                }
                <ListGroup>
                    {
                        lieuxFiltered.slice(min, max).map(lieu =>
                            <ListGroup.Item key={lieu.id}>
                                <div className={'listAction'}>
                                    <Button className={'itemListMax'} onClick={() => {
                                        onSelect(lieu)
                                    }}>&nbsp;&nbsp;&nbsp;{lieu.nom}
                                    </Button>
                                    <Button variant="danger" size={"sm"} className={'deleteItem'} onClick={() => {
                                        this.deleteLieu(lieu)
                                    }}>X</Button>

                                </div>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </div>
        );
    }
}