import React from "react";
import StepZilla from "react-stepzilla";
import {Step2} from "./Step2";
import {Step1} from "./Step1";
import {Step3} from "./Step3";
import {Step4} from "./Step4";
import './main.css';

export class AssistantEvt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.sampleStore = {
            savedToCloud: false
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    getStore() {
        return this.sampleStore;
    }

    updateStore(update) {
        this.sampleStore = {
            ...this.sampleStore,
            ...update,
        }
    }

    newEvent() {
        this.sampleStore={
            savedToCloud: false
        };
    }

    render() {
        const steps = [
            {
                name: 'Spectacle', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
            },
            {
                name: 'Programmateur & Lieu', component: <Step2 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
            },
            {
                name: 'Tarifs & Date', component: <Step3 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
            },
            {
                name: 'Enregistrement', component: <Step4 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }} newEvent={() => (this.newEvent())}/>
            }
        ]

        return <>
            <div className='step-progress'>
                <h1>Ajout d'un évènement</h1>
                <StepZilla steps={steps} stepsNavigation={true} showSteps={true} nextButtonText={"suivant"}
                           backButtonText={"précédent"}
                           preventEnterSubmission={true}
                           prevBtnOnLastStep={false}
                    // hocValidationAppliedTo={[1]}
                           nextTextOnFinalActionStep={"Save"}
                />
            </div>
        </>
    }
}