import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron, Accordion, Card} from "react-bootstrap";
import API from "../../utils/API";
import {FormLieu} from "./FormLieu";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class CreateLieu extends React.Component {
    state = {
        success: "", error: "",submitDisabled:true,
        lieu:{}
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        API.addConfSecurity();
        var api = new BackofficeDansTousLesSens.LieuApi()
        var lieu = new BackofficeDansTousLesSens.Lieu();
        let photoApi = new BackofficeDansTousLesSens.PhotoApi();

        lieu.nom = this.state.lieu.nom;
        lieu.adresse = this.state.lieu.adresse;
        lieu.codePostal = this.state.lieu.codePostal;
        lieu.ville = this.state.lieu.ville;
        lieu.tel = this.state.lieu.tel;
        lieu.email = this.state.lieu.email;
        lieu.site = this.state.lieu.site;
        lieu.horaires = this.state.lieu.horaires;
        lieu.accessibilites= this.state.lieu.accessibilites;
        lieu.presentation=this.state.lieu.presentation
        lieu.trajetDescription=this.state.lieu.trajetDescription
        lieu.personneContact=this.state.lieu.personneContact?this.state.lieu.personneContact:"";
        console.debug("lieu create:", lieu);
        let countSendPhoto = 0;
        const callbackSucessPhoto = async (data) => {
            countSendPhoto += 1;
            if (countSendPhoto === this.state.lieu.photos.length) {
                console.debug('API called successfully. Returned data: ', data);
                this.setState({success: "Lieu créé avec succès!", error: ""},()=>this.cleanSucess());
            }
        }

        const callbackSucess = async (data) => {
            console.debug('API called successfully. Returned data: ', data);
            if (this.state.lieu.photos && this.state.lieu.photos.length>0) {
                this.state.lieu.photos.map(photo =>
                    photoApi.uploadImage(data.id, "LIEU",photo,{titre:photo.titre,texteAlternatif:photo.texteAlternatif,description:photo.description}).then(
                        function (data) {
                            callbackSucessPhoto(data);
                        },
                        function (error) {
                            callbackError(error);
                        })
                );
            } else {
                this.setState({success: "Lieu créé avec succès!", error: ""},()=>this.cleanSucess());
            }
        }

        const callbackError = async (data) => {
            console.error(data);
            if (data.body)
                this.setState({error: "Impossible de créer le lieu! : " + data.body.message, success: ""});
            else if (data.error)
                this.setState({error: "Impossible de créer le lieu! : " + data.error, success: ""});
            else {
                this.setState({error: "Impossible de créer le lieu! : erreur inconnue!"});
            }
        }

        api.createLieu(lieu).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    cleanSucess = function (){
        this.setState({lieu:{},submitDisabled:true});
    }

    changeCallback = function(data){
        this.setState({lieu:data,submitDisabled:false})
        //this.setState({"lieu":data})
    }

    render() {
        const {
            success, error,submitDisabled
        } = this.state;
        return (
            <Row>
                <Col sm={12}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Création d'un lieu</h1>
                    </Jumbotron>
                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit} >
                        <FormLieu key={`fl-new`}  onChange={this.changeCallback.bind(this)} as={"create"}/>
                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Enregistrer le lieu
                        </Button>
                    </Form>

                </Col>
            </Row>

        );
    }
}