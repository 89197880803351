import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import API from './utils/API.js'
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

API.initApi()

ReactDOM.render(<BrowserRouter key={"brooter"}><App key={"root-app"}/></BrowserRouter>, document.getElementById('root'));