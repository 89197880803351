import React from "react";
import {Form, Col, Row, Alert} from "react-bootstrap";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class UserFields extends React.Component {
    state = {
        progs: [],
        userData: {
            password_has_error: false,
            password_is_init: false,
            success: '',
            error: ''
        }

    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit();
    }

    componentDidMount() {

        const loadProgs = async () => {
            var progApi = new BackofficeDansTousLesSens.ProgApi();
            try {
                var data = await progApi.getAllProgsLight();
                this.setState({progs: data.progs});
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        };
        API.addConfSecurity();
        loadProgs();

        if (this.props.dataStore) {
            let tempState = {
                ...this.state.userData,
                ...this.props.dataStore(),
            }
            this.setState({userData: tempState});
        }
    }


    checkDatas(e) {
        var {id, value} = e.target

        if (id === 'gestionnaire') {
            value = e.target.checked;
        }
        let userData = this.state.userData;
        userData[id] = value;

        this.setState({
                userData: userData
            }, () => {

                if (id === 'password' || id === 'cpassword')
                    this.checkPassword();
            }
        );
        console.debug("id:", id, "value", value);
        return {key: id, value: value};
    };

    handleChange = (e) => {
        const dataChecked = this.checkDatas(e);
        this.props.change();
        if (this.props.hasOwnProperty("saveData")) {
            this.props.saveData(dataChecked);
        }
    }

    checkPassword() {
        let userData = this.state.userData;
        if (!this.state.userData.password || this.state.userData.password !== this.state.userData.cpassword) {
            userData.password_is_init = true;
            userData.password_has_error = true;

            this.props.saveData({key: "password_is_init", value: true});
            this.props.saveData({key: "password_has_error", value: true});
        } else {
            userData.password_is_init = true;
            userData.password_has_error = false;
            this.props.saveData({key: "password_is_init", value: true});
            this.props.saveData({key: "password_has_error", value: false});
        }

        this.setState({userData: userData});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    render() {
        return (
            <div>
                {this.state.userData.password_has_error && this.state.userData.password_is_init &&
                <Alert key={"danger"} variant={"danger"}>
                    Les deux mots de passes doivent être identiques
                </Alert>
                }
                {this.state.userData.success.length > 0 &&
                <Alert key={"danger"} variant={"success"}>
                    {this.state.userData.success}
                </Alert>
                }
                {this.state.userData.error.length > 0 &&
                <Alert key={"dangerError"} variant={"danger"}>
                    {this.state.userData.error}
                </Alert>
                }
                <Form.Group controlId="login">
                    <Form.Label>Identifiant</Form.Label>
                    <Form.Control required placeholder="Identifiant de la personne utilisant le compte"
                                  onChange={this.handleChange} value={this.state.userData.login}/>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="Email du compte"
                                      onChange={this.handleChange} value={this.state.userData.email}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control required={!this.state.userData.id || this.state.userData.id.length === 0}
                                      type="password" placeholder="Mot de passe"
                                      onChange={this.handleChange} value={this.state.userData.password}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="cpassword">
                        <Form.Label>Password Verif</Form.Label>
                        <Form.Control required={!this.state.userData.id || this.state.userData.id.length === 0}
                                      type="password"
                                      placeholder="Mot de passee pour vérification"
                                      onChange={this.handleChange} value={this.state.userData.cpassword}/>
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="nom">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control placeholder="Nom de la personne utilisant le compte"
                                  onChange={this.handleChange} value={this.state.userData.nom}/>
                </Form.Group>

                <Form.Group controlId="prenom">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control placeholder="Prénom de la personne utilisant le compte"
                                  onChange={this.handleChange} value={this.state.userData.prenom}/>
                </Form.Group>
                <Form.Group controlId="programmateur">
                    <Form.Label>Programmateur</Form.Label>
                    <Form.Control required as="select" placeholder="Programmateur" onChange={this.handleChange}
                                  custom value={this.state.userData.programmateur}>
                        <option>Choisir ....</option>
                        {
                            this.state.progs.map(prog =>
                                <option key={prog.id} value={prog.id}>{prog.nom} </option>
                            )
                        }
                    </Form.Control>
                </Form.Group>

                <Form.Group id="gestionnaire" as={Row}>
                    <Form.Label column lg={2}>Gestionnaire</Form.Label>
                    <Col lg={10}>
                        <Form.Check type="checkbox" name="gestionnaire" id="gestionnaire" label="Oui"
                                    onChange={this.handleChange} defaultChecked={this.state.userData.gestionnaire}/>
                    </Col>
                </Form.Group>

            </div>
        );
    }
}
