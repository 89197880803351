import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import API from "../../utils/API";
import {FormRessource} from "./FormRessource";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class CreateRessource extends React.Component {
    state = {
        success: "", error: "",submitDisabled:true,
        ressource:{}
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        API.addConfSecurity();
        var api = new BackofficeDansTousLesSens.RessourceApi()
        var ressource = new BackofficeDansTousLesSens.Ressource();
        let documentApi = new BackofficeDansTousLesSens.DocumentApi();

        ressource.titre = this.state.ressource.titre;
        ressource.description = this.state.ressource.description;
        ressource.liens = this.state.ressource.liens;
        ressource.ordre = this.state.ressource.ordre;
        ressource.diffusionBackoffice = this.state.ressource.diffusionBackoffice;
        ressource.diffusionFront = this.state.ressource.diffusionFront;
        
        console.debug("ressource create:", ressource);
        let countSendDocument = 0;
        const callbackSucessDocument = async (data) => {
            countSendDocument += 1;
            if (countSendDocument === this.state.ressource.documents.length) {
                console.debug('API called successfully. Returned data: ', data);
                this.setState({success: "Ressource créée avec succès!", error: ""},()=>this.cleanSucess());
            }
        }

        const callbackSucess = async (data) => {
            console.debug('API called successfully. Returned data: ', data);
            if (this.state.ressource.documents && this.state.ressource.documents.length>0) {
                this.state.ressource.documents.map(document =>
                    documentApi.uploadDocument(data.id, "RESSOURCE",document,{titre:document.titre,texteAlternatif:document.texteAlternatif}).then(
                        function (data) {
                            callbackSucessDocument(data);
                        },
                        function (error) {
                            callbackError(error);
                        })
                );
            } else {
                this.setState({success: "Ressource créée avec succès!", error: ""},()=>this.cleanSucess());
            }
        }

        const callbackError = async (data) => {
            console.error(data);
            if (data.body)
                this.setState({error: "Impossible de créer la ressource! : " + data.body.message, success: ""});
            else if (data.error)
                this.setState({error: "Impossible de créer la ressource! : " + data.error, success: ""});
            else {
                this.setState({error: "Impossible de créer la ressource! : erreur inconnue!"});
            }
        }

        api.createRessource(ressource).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    cleanSucess = function (){
        this.setState({ressource:{},submitDisabled:true});
    }

    changeCallback = function(data){
        this.setState({ressource:data,submitDisabled:false})
        //this.setState({"ressource":data})
    }

    render() {
        const {
            success, error,submitDisabled
        } = this.state;
        return (
            <Row>
                <Col sm={12}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Création d'une ressource</h1>
                    </Jumbotron>
                    {success.length > 0 &&
                        <Alert key={"danger"} variant={"success"}>
                            {success}
                        </Alert>
                    }
                    {error.length > 0 &&
                        <Alert key={"dangerError"} variant={"danger"}>
                            {error}
                        </Alert>
                    }
                    <Form onSubmit={this.handleSubmit} >
                        <FormRessource key={`fl-new`}  onChange={this.changeCallback.bind(this)} as={"create"}/>
                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Créer la ressource
                        </Button>
                    </Form>

                </Col>
            </Row>

        );
    }
}