import React from "react";
import {UserFields} from "../User/UserFields";
import Form from "react-bootstrap/Form";
import {FormLieu} from "../Lieux/FormLieu";
import {FormProg} from "./FormProg";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class Step3 extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        this.state = props.getStore();
        this._validateOnDemand = true;

        this.saveData = this.saveData.bind(this);


    }

    componentDidMount() {
        this.saveFinal()
    }

    saveData = function (data) {
        console.debug("Saving data...", data);
        let store = this.props.getStore();
        store[2] = data;
        this.props.updateStore({
            ...store,
            savedToCloud: false
        });
    };

    saveFinal = function () {
        API.addConfSecurity();
        let api = new BackofficeDansTousLesSens.ProgApi()
        let prog = new BackofficeDansTousLesSens.Prog();
        let photoApi = new BackofficeDansTousLesSens.PhotoApi();
        let store = this.props.getStore();
        ;
        prog.nom = store[0].nom;
        prog.presentation = store[0].presentation;
        prog.typeLieu = store[0].typeLieu;
        prog.contactNom = store[0].contactNom;
        prog.contactPrenom = store[0].contactPrenom;
        prog.contactTel = store[0].contactTel;
        prog.contactMail = store[0].contactMail;

        prog.lieu = {
            nom: store[1].nom,
            adresse: store[1].adresse,
            codePostal: store[1].codePostal,
            ville: store[1].ville,
            tel: store[1].tel,
            email: store[1].email,
            site: store[1].site,
            horaires: store[1].horaires,
            accessibilites: store[1].accessibilites
        }
        if (prog.typeLieu) {
            prog.lieu.trajetDescription = store[1].trajetDescription
            prog.lieu.presentation = store[1].presentation
            prog.lieu.personneContact = store[1].personneContact
        }

        console.debug("prog:", prog);

        const callbackSucess = async (data) => {
            try {
                if (store[0].logoCouleur || store[0].logoNB) {
                    if (store[0].logoCouleur) await photoApi.uploadImage(data.id, 'PROG_LOGO', store[0].logoCouleur, {
                        titre: store[0].logoCouleur.titre,
                        texteAlternatif: store[0].logoCouleur.texteAlternatif,
                        description: store[0].logoCouleur.description
                    })
                    if (store[0].logoNB) await photoApi.uploadImage(data.id, 'PROG_LOGONB', store[0].logoNB, {
                        titre: store[0].logoNB.titre,
                        texteAlternatif: store[0].logoNB.texteAlternatif,
                        description: store[0].logoNB.description
                    })
                }
                if (store[1].photos && store[1].photos.length > 0) {

                    store[1].photos.map(async photo =>
                        await photoApi.uploadImage(data.id, 'PROG_LIEUX_LOGO', photo, {
                            titre: photo.titre,
                            texteAlternatif: photo.texteAlternatif,
                            description: photo.description
                        })
                    );
                }
                this.setState({success: "Programmateur créé avec succès !", error: ""});
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }

            console.debug('API called successfully. Returned data: ', data);
        }

        const callbackError = async (data) => {
            this.setState({error: "Impossible de créer le programmateur !", success: ""});
            console.error(data);
        }

        api.createProg(prog).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };


    isValidated() {
        return true;
    }

    render() {
        return (
            <h2>Enregistrement effectué!</h2>
        )
    }
}