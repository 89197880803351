import React from "react";
import {Form, Col, Row, Button, Image as ReactImage, Container, Media, Modal, Alert, Card} from "react-bootstrap";
import PropTypes from 'prop-types'

export class Documents extends React.Component {

    static propTypes = {
        /**
         * Init datas documents
         */
        initDocuments: PropTypes.array,
        /**
         * Callback on change document
         */
        onChange: PropTypes.func.isRequired,
        /**
         * Callback on delete document
         */
        onDelete: PropTypes.func.isRequired,
        /**
         * Unique masterKey-> pour diff des documents si changement d'objet maitre
         */
        masterKey: PropTypes.string.isRequired,
        /**
         * Max pictures
         */
        onChangeAltTexte: PropTypes.func.isRequired,
        max: PropTypes.number

    }

    state = {
        show: false,
        documents: [],
        max: -1,
        labelDocument: '',
        errorMessage: '',
        result: this.props.value,
        docMaxSize: 5,
        success: "", error: ""
    };

    handleChange = (e) => {
        var {id, value} = e.target
        if (id === 'document') {
            console.debug("file", e.target.files[0] instanceof File);
            value = e.target.files[0];
            value.id = Date.now();
            console.debug("value:", value);
            let _URL = window.URL;
            let file, doc, fic;
            try {
                if (!value.type.startsWith("application/pdf") && !value.type.startsWith("image/")) {
                    alert("type de fichier non pris en charge");
                } else if ((file = e.target.files[0]) && file.size >(this.state.docMaxSize * 1024 * 1024)) {
                    alert("Ce fichier est trop volumineux (taille Max : "+this.state.docMaxSize+"Mo)");
                } else {
                    fic = new FileReader();
                    fic.readAsBinaryString(file);
                    console.debug("taille: ", file.size);
                    fic.props = this.props;
                    fic.onload = function () {
                        fic.props.onChange(file);
                    };
                }
            } catch (e) {
                console.error(e);
            }
        }
        this.setState({
                [id]: value
            }
        );
    };

    componentDidMount() {
        let ph = this.props.initDocuments;
        this.setState({labelDocument: this.props.label, max: this.props.max})

        const callback = (ph) => {
            console.debug('callback work document b64:', ph)
            this.setState({documents: ph});
        }
        let documentsWITHB64 = this.parseInitDocuments(ph);

        documentsWITHB64.then(documentsResult => callback(documentsResult));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.masterKey !== this.props.masterKey || nextProps.initDocuments.length !== this.props.initDocuments.length
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.initDocuments) return;
        console.debug("!!!!", nextProps.initDocuments)
        if (nextProps.masterKey !== this.props.masterKey || nextProps.initDocuments.length !== this.props.initDocuments.length) {
            this.setState({documents: []});
            let ph = nextProps.initDocuments;

            const callback = (ph) => {
                console.debug('callback work document b64:', ph)
                this.setState({documents: ph});
            }
            let documentsWITHB64 = this.parseInitDocuments(ph);
            documentsWITHB64.then(documentsResult => callback(documentsResult));
        }
    }

    parseInitDocuments(documents) {
        const tob64 = this.arrayBufferToBase64.bind(this);
        return new Promise(function (resolve, reject) {
            let documentsB = [];
            if (!documents) return documentsB;

            documents.forEach((ph) => {
                if (ph) {
                    tob64(ph).then(
                        documentFile => {
                            documentsB.push(documentFile)
                            if (documents.indexOf(ph) + 1 === documents.length) {
                                return resolve(documentsB);
                            }
                        }
                    );
                }
            });
        })


    }

    canAddDocument() {
        //if (!this.state.documents || !this.state.documents) return false;
        return this.state.max > 0 && this.state.documents.length >= this.state.max;
    }

    arrayBufferToBase64(document) {
        return new Promise(function (resolve, reject) {
            if (document instanceof File) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    document.b64 = e.target.result
                    return resolve(document);
                }
                reader.readAsDataURL(document); // convert to base64 string
            } else {
                let buffer = document.buffer
                let binary = '';
                let bytes = [].slice.call(new Uint8Array(buffer));
                console.debug("bufffffer", buffer)
                bytes.forEach((b) => binary += String.fromCharCode(b));
                document.b64 = `data:${document.mimetype};base64, ${document.buffer}`;//${window.btoa(binary)}`;
                return resolve(document);
            }
        })


    };

    popupAltTexte = (document, index) => {
        this.setState({
            titre: document.titre,
            texteAlternatif: document.texteAlternatif,
            indexDocument: index,
            show: true,
        });
    }

    handleAltChange = async e => {
        const {value, id} = e.target;
        this.setState({[id]: value})
    }

    handleModifier = (document, index) => {
        console.debug("handleModifier");
        console.debug(index)
        var popupAltTexte = this.popupAltTexte(document, index);

        this.setState({show: true})
    }

    handleValidateAltTexte = () => {
        let document = this.state.documents[this.state.indexDocument];
        document.titre = this.state.titre;
        document.texteAlternatif = this.state.texteAlternatif;

        this.props.onChangeAltTexte(this.state.indexDocument, document);

        this.handleClose();
    }

    handleClose = (evt) => {
        this.setState({show: false})
    };

    render() {

        const {
            show, documents, labelDocument,
            titre, texteAlternatif,
            errorMessage
        } = this.state;
        {
            console.log("documents size:", documents.length)
        }
        return (
            <>
                <h3 className="text-left">Documents</h3>
                <Form.Group controlId="document">
                    <Form.File
                        id="document"
                        label="Custom file input"
                        lang="fr"
                        custom
                        disabled={this.canAddDocument()}
                    >
                        <Form.File.Input accept="*/*" disabled={this.canAddDocument()} onChange={this.handleChange}/>
                        <Form.File.Label data-browse="Parcourir">
                            {labelDocument && <span>{labelDocument}</span>}
                            {!labelDocument && <span>Nouveau document : image ou pdf - taille max {this.state.docMaxSize}Mo</span>}
                        </Form.File.Label>
                        {this.state.image &&
                            <img width={80} src={this.state.image}/>
                        }
                    </Form.File>
                </Form.Group>

                <Form.Group controlId="container-documents">
                    {documents && documents.map(
                        (document, index) =>
                            <>
                                <Card>
                                    <Container>
                                        <Row>
                                            <Col md={3}>
                                                <Row>
                                                    <p>
                                                        <span className="badge badge-secondary">#{index + 1}</span>
                                                    </p>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ReactImage
                                                            width={160}
                                                            className="align-self-start mr-3"
                                                            rounded
                                                            src={document.b64}
                                                        />
                                                        <hr className="hr-1"></hr>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-left">
                                                        <small>{document.originalname ? document.originalname : document.name}</small>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={9}>
                                                <div>
                                                    <hr className="hr-1"></hr>
                                                </div>
                                                <Media.Body className="text-left">
                                                    <h5>Titre: {document.titre ? document.titre : "à remplir"}</h5>
                                                    <h6>Text
                                                        alternatif: {document.texteAlternatif ? document.texteAlternatif : "à remplir"}</h6>
                                                </Media.Body>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>

                                            </Col>
                                            <Col className="text-right">
                                                <Button size={"sm"}
                                                        onClick={() => this.handleModifier(document, index)}>
                                                    <small>Modifier</small>
                                                </Button>
                                                <Button variant="danger" size={"sm"} onClick={() => {
                                                    if (window.confirm(`Supprimer le document ${document.name ? document.name : document.originalname}?`)) this.props.onDelete(document.id)
                                                }}><small>x</small></Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                                <hr className="hr-1"></hr>
                            </>
                    )}
                </Form.Group>

                <Modal show={show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Définition des textes alternatif</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && errorMessage.length > 0 &&
                            <Alert key={"danger"} variant={"danger"}>
                                {errorMessage}
                            </Alert>
                        }
                        <Form.Group controlId="titre">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control required placeholder="Titre" value={titre}
                                          onChange={this.handleAltChange}/>
                        </Form.Group>
                        <Form.Group controlId="texteAlternatif">
                            <Form.Label>Texte alternatif</Form.Label>
                            <Form.Control required placeholder="TexteAlternatif" value={texteAlternatif}
                                          onChange={this.handleAltChange}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="cancel" onClick={this.handleClose}>
                            Annuler
                        </Button>
                        <Button variant="primary" disabled={errorMessage.length > 0}
                                onClick={() => this.handleValidateAltTexte()}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}