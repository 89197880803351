import React from "react";
import API from "../../utils/API";
import {Button} from "react-bootstrap";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

const pageNumber = 3;

export class Step4 extends React.Component {
    state = {
        evenement: null
    }

    constructor(props) {
        super(props);
        if (props.getStore()[pageNumber])
            this.state = props.getStore()[pageNumber];
        else
            this.saveData(this.state, pageNumber)
        this._validateOnDemand = true;

        this.saveData = this.saveData.bind(this);
    }


    saveData = function (data, page) {
        console.debug("Saving data...", data);
        let store = this.props.getStore();
        store[page] = data;
        this.props.updateStore({
            ...store,
            savedToCloud: false
        });
    };


    componentDidMount() {
        this.createEvenement();


    }

    createEvenement = async () => {
        let apiEvt = new BackofficeDansTousLesSens.EvenementApi();
        let evenement = new BackofficeDansTousLesSens.Evenement();

        console.debug("evenement", evenement)

        let dataPage1 = this.props.getStore()[0];
        let dataPage2 = this.props.getStore()[1];
        let dataPage3 = this.props.getStore()[2];
        let dataPage4 = this.props.getStore()[3];

        if (!dataPage1.spectacle.id) {
            dataPage1.spectacle = await this.creerSpectacle(dataPage1.spectacle);
            dataPage1.typeSpectacle = 'use';
            this.saveData(dataPage1, 0);
        }

        if (dataPage2.lieu && !dataPage2.lieu.id && !dataPage2.progSelLieu) {
            dataPage2.lieu = await this.creerLieu(dataPage2.lieu);
            dataPage2.typeLieu = 'use';
            this.saveData(dataPage2, 1);
        }
        /*
        properties:
      id:

         */
        //Le lieu est celui du prog
        if (dataPage2.progSelLieu) {
            evenement.typeLieuProg = true;
        }

        evenement.programmateurId = dataPage2.progSel.id;
        evenement.spectacleId = dataPage1.spectacle.id;
        evenement.dateTime = dataPage3.startDate;
        if (!dataPage2.progSelLieu)
            evenement.lieuId = dataPage2.lieu.id;

        evenement.typeLieuProg = dataPage2.progSelLieu;
        evenement.tarifs = dataPage3.tarifs;
        evenement.transport = dataPage3.transport;
        evenement.servicesSup = dataPage3.servicesSup;
        if(!evenement.accessibilite)evenement.accessibilite={servicesup:[]}
            evenement.accessibilite.servicesup=dataPage3.servicesSupItems;

        evenement.evenemenAssocie = dataPage3.evenemenAssocie;

        if (!dataPage4 || !dataPage4.evenement) {
            try {
                evenement = await apiEvt.createEvenement(evenement);
                dataPage4.evenement = evenement;
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            alert("attention evt existe dejà");
        }
        this.saveData(dataPage4, 3);
    }

    creerSpectacle = async (dataPage1) => {
        let api = new BackofficeDansTousLesSens.SpecApi();
        let spec = new BackofficeDansTousLesSens.Spec();
        let photoApi = new BackofficeDansTousLesSens.PhotoApi();

        spec.titre = dataPage1.titre;
        spec.presentation = dataPage1.presentation;
        spec.ephemere = dataPage1.ephemere;

        spec.genre = dataPage1.genre;
        spec.public = dataPage1.public;

        spec.compagnie = dataPage1.compagnie;
        spec.distribution = dataPage1.distribution;
        spec.auteur = dataPage1.auteur;
        spec.miseEnScene = dataPage1.miseEnScene;
        spec.choregraphie = dataPage1.choregraphie;
        spec.direction = dataPage1.direction;
        spec.musique = dataPage1.musique;
        spec.texte = dataPage1.texte;
        spec.composition = dataPage1.composition;
        spec.duree = dataPage1.duree;
        spec.entracte = dataPage1.entracte;
        spec.age = dataPage1.age;
        spec.accessibilite = dataPage1.accessibilite;
        spec.videos=dataPage1.videos

        try {
            let newSpectacle = await api.createSpec(spec);

            if (dataPage1.photos && dataPage1.photos.length > 0) {
                try {
                    dataPage1.photos.map(async photo =>
                        await photoApi.uploadImage(newSpectacle.id, "SPEC", photo, {
                            titre: photo.titre,
                            texteAlternatif: photo.texteAlternatif,
                            description: photo.description
                        })
                    );
                } catch (error) {
                    console.error(error);
                    if (error.message)
                        alert(error.message)
                }
            }

            return newSpectacle;
        } catch (error) {
            console.error(error);
            if (error.message)
                alert(error.message)
        }
    }

    creerLieu = async (dataPage2) => {
        API.addConfSecurity();
        let api = new BackofficeDansTousLesSens.LieuApi()
        let lieu = new BackofficeDansTousLesSens.Lieu();
        let photoApi = new BackofficeDansTousLesSens.PhotoApi();

        lieu.nom = dataPage2.nom;
        lieu.adresse = dataPage2.adresse;
        lieu.codePostal = dataPage2.codePostal;
        lieu.ville = dataPage2.ville;
        lieu.tel = dataPage2.tel;
        lieu.email = dataPage2.email;
        lieu.site = dataPage2.site;
        lieu.horaires = dataPage2.horaires;
        lieu.accessibilites = dataPage2.accessibilites;
        lieu.presentation = dataPage2.presentation
        lieu.trajetDescription = dataPage2.trajetDescription
        lieu.personneContact = dataPage2.personneContact ? dataPage2.personneContact : "";

        let newLieu = await api.createLieu(lieu);
        if (dataPage2.photos && dataPage2.photos.length > 0) {
            try {
                dataPage2.photos.map(async photo =>
                    await photoApi.uploadImage(newLieu.id, "LIEU", photo, {
                        titre: photo.titre,
                        texteAlternatif: photo.texteAlternatif,
                        description: photo.description
                    }));
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }

        return newLieu;
    }

    isValidated() {
        return true;
    }

    newEvent(){
        this.props.newEvent();
        this.props.jumpToStep(0);
    }

    render() {
        return (
            <>
                <h2>Enregistrement effectué!</h2>

                    <Button variant="primary" onClick={() => {
                        this.newEvent();
                    }}>
                        Ajouter un nouvel évènement
                    </Button>
            </>
        )
    }
}