import React from "react";
import {ListGroup, Button, Alert, Jumbotron, Form, FormControl, Pagination} from "react-bootstrap";
import API from "../../utils/API";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ListRessources extends React.Component {
    state = {
        ressources: [],
        ressourcesFiltered: [],
        messageSuccess: "",
        messageError: "",
        maxPerPage: 5,
        pageNumber: 0,
        nbItemsPager: 7,
        min: 0,
        max: 5,
        filterText: "",
        filterDiffusion: "all",
    };

    componentDidMount() {
        API.addConfSecurity();
        this.filterList();
        this.setPageNumber(this.state.pageNumber);
        let pageNumber=this.state.pageNumber;
        const ressources = async () => {
            const api = new BackofficeDansTousLesSens.RessourceApi();
            try {
                const data = await api.getAllRessources();
                console.debug("ressources", data.ressources);
                if (data) {
                    data.ressources.sort(function(a, b) {
                        const nameA = a.titre.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.titre.toUpperCase(); // ignore upper and lowercase
                        return nameA.localeCompare(nameB);
                    });
                    console.debug("ressources", data);
                    this.setState({ressources: data.ressources, ressourcesFiltered: data.ressources});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.filterList();
            this.setPageNumber(pageNumber);
        };
        ressources();
    }

    filterList = (event) => {
        let filterText = this.state.filterText;
        let filterDiffusion = this.state.filterDiffusion;
        if (event) {
            this.setPageNumber(0);
            if (event.target.type === 'radio' && event.target.id === "front") filterDiffusion = "front";
            else if (event.target.type === 'radio' && event.target.id === "back") filterDiffusion = "back";
            else if (event.target.type === 'radio' && event.target.id === "both") filterDiffusion = "both";
            else if (event.target.type === 'radio' && event.target.id === "none") filterDiffusion = "none";
            else if (event.target.type === 'radio' && event.target.id === "all") filterDiffusion = "all";
            else filterText = event.target.value.toLowerCase();
            this.setState({filterText: filterText, filterDiffusion: filterDiffusion});
        }
        let filteredItemsDiffusion = this.state.ressources.filter(function (ressource) {
            console.debug(`filters : filterDiffusion= ${filterDiffusion} filterText= ${filterText}`);
            if (filterDiffusion.toLowerCase() === "front")
                return ressource.diffusionFront === true;
            else if (filterDiffusion.toLowerCase() === "back")
                return ressource.diffusionBackoffice === true;
            else if (filterDiffusion.toLowerCase() === "both")
                return ressource.diffusionFront === true && ressource.diffusionBackoffice === true;
            else if (filterDiffusion.toLowerCase() === "none")
                return ressource.diffusionFront === false && ressource.diffusionBackoffice === false;
            else return ressource;
        });
        //second filtre sur le text
        let filteredItems = filteredItemsDiffusion.filter(function (ressource) {
            console.debug(`filters : filterDiffusion= ${filterDiffusion} filterText= ${filterText}`);
            if (filterText !== "") return (ressource.titre.toLowerCase().search(filterText) > -1);
            else return filteredItemsDiffusion;
        });

        console.debug(`filter : nb= ${filteredItems.length}`);
        this.setState({ressourcesFiltered: filteredItems});
    }

    deleteRessource = async (ressource2Del) => {
        if (window.confirm(`Êtes vous sûr de vouloir supprimer la ressource ${ressource2Del.titre} ?`)) {
            try {
                const api = new BackofficeDansTousLesSens.RessourceApi();
                let result = await api.deleteRessource(ressource2Del.id);
                if (result.error) {
                    this.setState({messageError: "Impossible de supprimer la ressource " + ressource2Del.titre});
                } else {
                    this.componentDidMount();
                    this.setState({messageSuccess: "Ressource " + ressource2Del.titre + " supprimée"});
                    this.props.onSelect();
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.debug("RELOAD")
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    setPageNumber = (pageNumber) => {
        if (pageNumber < 0) pageNumber = 0;
        let lastPage = Math.ceil(this.state.ressourcesFiltered.length / this.state.maxPerPage);
        if (pageNumber>lastPage-1) pageNumber=lastPage-1;
        console.log(`lastPage : ${lastPage}`);
        console.log(`setPageNumber : ${pageNumber}`);
        let min = ((pageNumber * this.state.maxPerPage) > this.state.ressourcesFiltered.length) ? 0 : (pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;

        this.setState({pageNumber: pageNumber, min: min, max: max});
    }

    getPageItems = () => {
        let pageItems = [];
        let minPage;
        console.debug("nb ressources : ", this.state.ressourcesFiltered.length);
        let nbMaxPage = Math.ceil(this.state.ressourcesFiltered.length / this.state.maxPerPage);
        let halfItemsPager = Math.ceil(this.state.nbItemsPager / 2);
        if (nbMaxPage <= this.state.nbItemsPager || this.state.pageNumber < halfItemsPager || this.state.pageNumber > nbMaxPage) minPage = 0;
        else if (this.state.pageNumber > nbMaxPage - halfItemsPager) minPage = nbMaxPage - this.state.nbItemsPager;
        else minPage = this.state.pageNumber - halfItemsPager;
        let nbMaxItem = minPage + this.state.nbItemsPager;
        if (nbMaxPage < nbMaxItem) nbMaxItem = nbMaxPage;
        for (let i = minPage; i < nbMaxItem; i++) {
            pageItems.push(<Pagination.Item key={i} active={i === this.state.pageNumber}
                                            onClick={() => this.setPageNumber(i)}>{i + 1}</Pagination.Item>)
        }
        console.debug("nb pages : ", nbMaxPage);
        return pageItems;
    }

    render() {
        const {onSelect} = this.props;
        const {
            ressourcesFiltered,
            maxPerPage,
            min, max,
            messageError,
            messageSuccess
        } = this.state;
        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h5>Liste des Ressources</h5>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                    <Alert key={"successDel"} variant={"success"}>
                        {messageSuccess}
                    </Alert>
                }
                {messageError.length > 0 &&
                    <Alert key={"errorDel"} variant={"danger"}>
                        {messageError}
                    </Alert>
                }

                <FormControl type="text" placeholder="filter" className="mr-sm-2" onChange={this.filterList}/>
                <Form inline>
                    <Form.Check type="radio" label="front " name="diffusion" id="front" onChange={this.filterList}/>
                    <Form.Check type="radio" label="back " name="diffusion" id="back" onChange={this.filterList}/>
                    <Form.Check type="radio" label="les deux " name="diffusion" id="both" onChange={this.filterList}/>
                    <Form.Check type="radio" label="aucun " name="diffusion" id="none" onChange={this.filterList}/>
                    <Form.Check type="radio" label="tous " name="diffusion" id="all" onChange={this.filterList}/>

                </Form>
                {this.state.ressourcesFiltered.length > maxPerPage &&
                    <Pagination size={'sm'}>
                        <>
                            {this.state.ressourcesFiltered.length / maxPerPage > this.state.nbItemsPager &&
                                <><Pagination.First onClick={() => this.setPageNumber(0)}/>
                                    <Pagination.Prev onClick={() => this.setPageNumber(this.state.pageNumber - 1)}/>
                                </>
                            }
                            {this.state.ressourcesFiltered.length > maxPerPage &&
                                this.getPageItems()
                            }
                            {this.state.ressourcesFiltered.length / maxPerPage > this.state.nbItemsPager &&
                                <> <Pagination.Next onClick={() => this.setPageNumber(this.state.pageNumber + 1)}/>
                                    <Pagination.Last
                                        onClick={() => this.setPageNumber(this.state.ressourcesFiltered.length / maxPerPage)}/></>
                            }
                        </>
                    </Pagination>
                }
                <ListGroup>
                    {
                        ressourcesFiltered.slice(min, max).map(ressource =>
                            <ListGroup.Item key={ressource.id}>
                                <div className={'listAction'}>
                                    <Button className={'itemListMax'} onClick={() => {
                                        onSelect(ressource)
                                    }}>&nbsp;&nbsp;&nbsp;{ressource.titre}
                                    </Button>
                                    <Button variant="danger" size={"sm"} className={'deleteItem'} onClick={() => {
                                        this.deleteRessource(ressource)
                                    }}>X</Button>

                                </div>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </div>
        );
    }
}