import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron, Card} from "react-bootstrap";
import {ListEvenements} from "./ListEvenements";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import API from "../../utils/API";

registerLocale('fr', fr)

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apievenement = new BackofficeDansTousLesSens.EvenementApi();
const lieuApi = new BackofficeDansTousLesSens.LieuApi();
const progApi = new BackofficeDansTousLesSens.ProgApi();
const specApi = new BackofficeDansTousLesSens.SpecApi();


export class EditEvenement extends React.Component {
    state = {
        evenement: undefined,
        spec:undefined,
        success: "",
        error: "",
        submitDisabled: true,
        reload: false
    };

    componentDidMount() {
        API.addConfSecurity();
        let apiData = new BackofficeDansTousLesSens.DataRefApi()
        const loadData = async () => {
            try {
                let lieux = await lieuApi.getAllLieuxEvenements();
                let progs = await progApi.getAllProgs();

                let specs = await specApi.getAllSpecs();
                let apiData = new BackofficeDansTousLesSens.DataRefApi()

                let servicesupItems=  await apiData.getAllServicesup();

                this.setState({lieux: lieux.lieux.sort(function(a, b) {
                        const nameA = a.nom.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.nom.toUpperCase(); // ignore upper and lowercase
                        return nameA.localeCompare(nameB);
                    }), progs: progs.progs.sort(function(a, b) {
                        const nameA = a.nom.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.nom.toUpperCase(); // ignore upper and lowercase
                        return nameA.localeCompare(nameB);
                    }), specs: specs.specs.sort(function(a, b) {
                        const nameA = a.titre.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.titre.toUpperCase(); // ignore upper and lowercase
                        return nameA.localeCompare(nameB);
                    }),listeServicesup: servicesupItems});
            } catch (error) {
                if (error.message)
                    alert(error.message)
            }
        }

        loadData();
    }

    accessIsCheckec = (key, listItem) => {
        let checked = false;
        listItem.map(item => {
            if (item.code === key) {
                checked = item.selected
            }
            return true
        })
        return checked
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.submitDisabled) return;

        let api = new BackofficeDansTousLesSens.EvenementApi();
        let evenement = this.state.evenement;

        if (!evenement.tarifs) evenement.tarifs = '';
        if (!evenement.transport) evenement.transport = ''
        if (!evenement.servicesSup) evenement.servicesSup = ''
        if (!evenement.evenemenAssocie) evenement.evenemenAssocie = ''

        const callbackSucess = async (data) => {
            console.debug('API called successfully. Returned data: ', data);
            this.setState({success: "Evènement modifié avec succès!", error: "", submitDisabled: true, reload: true});
        }


        const callbackError = async (data) => {
            console.error(data);
            if (data.body)
                this.setState({
                    error: "Impossible de créer le lieu! : " + data.body.message,
                    success: "",
                    submitDisabled: false
                });
            else if (data.error)
                this.setState({
                    error: "Impossible de créer le lieu! : " + data.error,
                    success: "",
                    submitDisabled: false
                });
            else {
                this.setState({error: "Impossible de créer le lieu! : erreur inconnue!", submitDisabled: false});
            }
        }

        api.updateEvenement(evenement.id, evenement).then(
            function (data) {
                callbackSucess(data);
            }
            ,

            function (error) {
                callbackError(error);
            }
        );

        console
            .log(
                "update evenement:"
                ,
                evenement
            )
        ;

    };

    selectEvenement = async (evenementLight) => {
        if (evenementLight) {
            console.debug(this.state);
            let spec = await specApi.getSpec(evenementLight.spectacleId)
            this.setState({evenement: evenementLight, success: '', error: '',spec:spec});

        } else {
            this.setState({
                evenement: undefined,
                success: '', error: ''
            });
        }
    }

    setDate = (date) => {
        let evt = this.state.evenement;
        evt.dateTime = date;
        this.setState({evenement: evt, submitDisabled: false});
    }

    setValid = (e) => {
        let evt = this.state.evenement;
        evt.validation.valid=e.target.checked;
        this.setState({evenement: evt, submitDisabled: false});
    }


    handleChange = (e) => {
        let {id, value} = e.target;

        let evt = this.state.evenement;

        if (id === 'progSelLieu') {
            evt.typeLieuProg = e.target.checked
            evt.lieuId = undefined;
        } else {
            evt[id] = value;
        }
        this.setState({evenement: evt, submitDisabled: false});
    }

    setServiceSupItems = (e) => {
        let {id, value} = e.target
        let evt = this.state.evenement;
        if (id.startsWith('ssup')) {
            let indexDelim = id.indexOf("_");
            let key = id.substring(0, indexDelim);
            let list = this.state.listeServicesup;
            //console.debug("list", list);

            list.forEach(item => {
                console.debug(`${item.code} === ${id.substring(indexDelim + 1)}`);
                if (item.code === id.substring(indexDelim + 1)) {
                    item.selected = e.target.checked
                }
            });
            console.log("servicesSupItems",list)
            evt.accessibilite.servicesup = list;
            this.setState({evenement: evt, submitDisabled: false});
        }
    }

    checkReload = () => {
        if (this.state.reload) {
            this.setState({reload: false});
            return true
        } else return false;
    }

    render() {
        const {
            success, error, evenement, submitDisabled, lieux, progs, specs,spec,listeServicesup
        } = this.state;
        return (
            <Row key={"editRow"}>
                {console.debug("evt:", evenement)}
                <Col lg={3} key={"editCol"}>
                    <ListEvenements key={"listEditLieux"} onSelect={this.selectEvenement}
                                    reload={this.checkReload()}/>
                </Col>
                {evenement && <Col sm={9} key={"colEdit"}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Modification d'un évènement</h1>
                    </Jumbotron>

                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }

                    <Form onSubmit={this.handleSubmit} key={`form${evenement.id}`}>
                        {(API.getRole() === 'admin' || API.getRole() === 'root') &&
                        <>
                            <Form.Group controlId="programmateurId">
                                <Form.Label>programmateur</Form.Label>
                                <Form.Control required as="select" onChange={this.handleChange}
                                              value={evenement.programmateurId}>
                                    <option value={''}>Choisir un programmateur</option>
                                    {progs && progs.map(prog =>
                                        <option key={prog.id} value={prog.id}>{prog.nom}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="lieuId">
                                <Form.Label>lieux</Form.Label>
                                {progs && progs.map(prog =>
                                    <>
                                        {prog.id === evenement.programmateurId && prog.typeLieu &&
                                        <p className="mb-0">
                                            Ce programmateur est aussi un lieu de représentation.
                                            <Form.Check type="checkbox" name="gestionnaire" id="progSelLieu"
                                                        label="Choisir ce lieu"
                                                        onChange={this.handleChange}
                                                        defaultChecked={evenement.typeLieuProg}/>
                                        </p>
                                        }
                                    </>
                                )}
                                {!evenement.typeLieuProg &&
                                <Form.Control required as="select" onChange={this.handleChange}
                                              value={evenement.lieuId}>
                                    <option value={''}>Choisir un Lieu</option>
                                    {lieux && lieux.map(lieu =>
                                        <option key={lieu.id} value={lieu.id}>{lieu.nom}</option>
                                    )}
                                </Form.Control>
                                }
                            </Form.Group>
                            <Form.Group controlId="spectacleId">
                                <Form.Label>spectacle</Form.Label>
                                <Form.Control required as="select" onChange={this.handleChange}
                                              value={evenement.spectacleId}>
                                    <option value={''}>Choisir un spectacle</option>
                                    {specs && specs.map(spec =>
                                        <option key={spec.id} value={spec.id}>{spec.titre}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </>
                        }
                        {(API.getRole() === 'user') &&
                        <>
                            {/*<Form.Group controlId="programmateurId">*/}
                            {/*    <Form.Label>programmateur</Form.Label>*/}
                            {/*    {evenement.programmateurId}*/}
                            {/*</Form.Group>*/}
                            <Form.Group controlId="lieuId">
                                <Form.Label>lieux</Form.Label>
                                {progs && progs.map(prog =>
                                    <>
                                        {prog.id === evenement.programmateurId && prog.typeLieu &&
                                        <p className="mb-0">
                                            <i>Vous êtes aussi un lieu de représentation.</i>
                                            <Form.Check type="checkbox" name="gestionnaire" id="progSelLieu"
                                                        label="Choisir ce lieu"
                                                        onChange={this.handleChange}
                                                        defaultChecked={evenement.typeLieuProg}/>
                                        </p>
                                        }
                                    </>
                                )}
                                {!evenement.typeLieuProg &&
                                <Form.Control required as="select" onChange={this.handleChange}
                                              value={evenement.lieuId}>
                                    <option value={''}>Choisir un Lieu</option>
                                    {lieux && lieux.map(lieu =>
                                        <option key={lieu.id} value={lieu.id}>{lieu.nom}</option>
                                    )}
                                </Form.Control>
                                }
                            </Form.Group>
                            <Form.Group controlId="spectacleId">
                                <Form.Label>spectacle</Form.Label>
                                <div>
                                {spec.titre}
                                </div>
                            </Form.Group>
                        </>
                        }
                        <Form.Group controlId="dateTime">
                            <Form.Label>Date de prog</Form.Label>
                            <div>
                                <DatePicker
                                    selected={evenement.dateTime ? new Date(evenement.dateTime) : ''}
                                    onChange={date => this.setDate(date)}
                                    showTimeSelect
                                    locale="fr"
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="tarifs">
                            <Form.Label>Tarifs</Form.Label>
                            <Form.Control required as="textarea" onChange={this.handleChange}
                                          value={evenement.tarifs} placeholder="Tarifs">
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="transport">
                            <Form.Label>Moyen de transport</Form.Label>
                            <Form.Control as={"textarea"} onChange={this.handleChange}
                                          value={evenement.transport} placeholder="Moyen de transport"/>
                        </Form.Group>
                        {true === false &&
                        <Form.Group controlId="servicesSup">
                            <Form.Label>Services sup ( ce champ va être supprimé!)</Form.Label>
                            <Form.Control as={"textarea"} onChange={this.handleChange}
                                          value={evenement.servicesSup} placeholder="Services sup"/>
                        </Form.Group>
                        }
                        <Form.Group controlId="servicesSup">
                            <Form.Label>Services supplémentaires</Form.Label>
                            <Card>
                                {listeServicesup && listeServicesup.map(acc =>
                                    <>{
                                        <Form.Check type="checkbox" name={`ssup_${acc.code}]`}
                                                    id={`ssup_${acc.code}`} label={acc.libelle}
                                                    onChange={this.setServiceSupItems}
                                                    defaultChecked={evenement.accessibilite?this.accessIsCheckec(acc.code, evenement.accessibilite.servicesup):false}
                                                    className="text-left"/>
                                    }</>
                                )}
                            </Card>
                        </Form.Group>
                        <Form.Group controlId="evenemenAssocie">
                            <Form.Label>Événement associé</Form.Label>
                            <Form.Control as={"textarea"} onChange={this.handleChange}
                                          value={evenement.evenemenAssocie} placeholder="Événement associé"/>
                        </Form.Group>
                        {(API.getRole() === 'admin') &&
                        <Form.Group controlId="validation">
                            <Form.Label>Validatioń</Form.Label>
                            <Form.Check type="checkbox" label={'Validé et visible sur le site public'}
                                        onChange={this.setValid}
                                        defaultChecked={evenement.validation.valid}
                                        className="text-left"/>
                        </Form.Group>
                        }
                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Mettra à jour l'évènement
                        </Button>
                    </Form>

                </Col>
                }
            </Row>

        );
    }
}