import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {ListSpec} from "./ListSpec";
import {FormSpec} from "./FormSpec";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiSpec = new BackofficeDansTousLesSens.SpecApi();

export class EditSpec extends React.Component {
    state = {
        spec: undefined,
        success: "",
        error: "",
        submitDisabled: true,
        reload: false
    };

    spec2Save = {};

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.submitDisabled) return;

        var api = new BackofficeDansTousLesSens.SpecApi();

        console.debug("spec2Save:", this.spec2Save);

        let spec = new BackofficeDansTousLesSens.Spec();
        console.debug("spec:", spec);
        spec.titre = this.spec2Save.titre;
        spec.presentation = this.spec2Save.presentation;

        spec.entracte = this.spec2Save.entracte;
        spec.ephemere = this.spec2Save.ephemere;

        if (this.spec2Save.genre != undefined) {
            spec.genre = this.spec2Save.genre
        }
        if (this.spec2Save.public != undefined) {
            spec.public = this.spec2Save.public
        }

        spec.compagnie = this.spec2Save.compagnie ? this.spec2Save.compagnie : "";
        spec.distribution = this.spec2Save.distribution ? this.spec2Save.distribution : "";
        spec.auteur = this.spec2Save.auteur ? this.spec2Save.auteur : "";
        spec.miseEnScene = this.spec2Save.miseEnScene ? this.spec2Save.miseEnScene : "";
        spec.choregraphie = this.spec2Save.choregraphie ? this.spec2Save.choregraphie : "";
        spec.direction = this.spec2Save.direction ? this.spec2Save.direction : "";
        spec.musique = this.spec2Save.musique ? this.spec2Save.musique : "";
        spec.composition = this.spec2Save.composition ? this.spec2Save.composition : "";
        spec.duree = this.spec2Save.duree ? this.spec2Save.duree : "";
        spec.age = this.spec2Save.age ? this.spec2Save.age : "";

        spec.accessibilite = this.spec2Save.accessibilite;
        spec.videos = this.spec2Save.videos;
        spec.validation= {valid:this.spec2Save.validation.valid};
        console.debug("spec:", spec);
        console.debug("this.spec2Save.videos", this.spec2Save.videos)

        const callbackSucessPhoto = async (data) => {
            console.debug('API photo called successfully. Returned data: ', this.state.id);
            api.getSpec(this.state.id).then(
                function (data) {
                    updateData("Spectacle modifié avec succès!", '', data)
                },
                function (error) {
                    updateData({success: "Spectacle modifié avec succès!", error: ''});
                }
            );
        }

        const updateData = async (message, error, data) => {
            console.debug(data.photos);
            if (data)
                this.setState({
                    success: message,
                    error: error,
                    image: 'data:image/jpeg;base64,' + this.arrayBufferToBase64(data.photo.buffer.data)
                });
            else {
                if (message)
                    this.setState({success: message, error: error,submitDisabled: true,reload:true});
                else
                    this.setState({success: message, error: error});
            }
        }

        const callbackSucess = async (data) => {
            console.debug('API called successfully. Returned data: ', data);
            if (this.state.spec.photo) {
                api.uploadPhoto(this.state.id, this.state.spec.photo).then(
                    function (data) {
                        callbackSucessPhoto(data);

                    },
                    function (error) {
                        callbackError(error);
                    });
            } else {
                this.setState({
                    success: "Spectacle modifié avec succès!",
                    error: "",
                    reload: true,
                    submitDisabled: true
                });
            }
        }

        const callbackError = async (data) => {
            console.error(data);
            this.setState({
                error: 'Impossible de modifier le spectacle! : ' + data.body.message ? data.body.message : data.error,
                success: ''
            });
        }
        console.debug("id:", this.spec2Save.id, "spec:", spec)

        api.updateSpec(this.spec2Save.id, spec).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            }
        );
    };

    selectSpec = async (specLight) => {
        if (specLight) {
            console.debug("spec sel", specLight.id);
            //API.addConfSecurity();
            try {
                const spec = await apiSpec.getSpec(specLight.id);
                console.debug("spec sel", spec);
                this.spec2Save = spec;
                this.setState({
                    spec: spec,
                    success: '', error: ''
                });
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            this.setState({
                spec: undefined,
                success: '', error: ''
            });
        }
    }

    componentDidCatch(error, info) {
        console.debug("error!!!!!!", error);
    }

    changeCallback = function (data) {
        console.debug("Spec 2save:", this.spec2Save);
        console.debug("data:", data);
        this.spec2Save = data;
        this.setState({submitDisabled: false})
    }
    checkReload = () => {
        if (this.state.reload) {
            this.setState({reload: false});
            return true
        } else return false;
    }

    componentDidMount() {
        API.addConfSecurity();
    }

    render() {
        const {
            success, error, spec, submitDisabled
        } = this.state;

        return (

            <Row key={"editRow"}>
                <Col lg={3} key={"editCol"}>
                    <ListSpec key={"listEditSpec"} onSelect={this.selectSpec} reload={this.checkReload()}/>
                </Col>
                {spec && <Col lg={9} key={"colEdit"}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-5 font-weight-normal">Modification d'un spectacle</h1>
                    </Jumbotron>
                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit} key={`form${spec.id}`}>

                        <FormSpec key={`fl${spec.id}`} data={spec} onChange={this.changeCallback.bind(this)}
                                  as={"edit"}/>

                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Mettra à jour le spectacle
                        </Button>
                    </Form>
                </Col>
                }
            </Row>
        );
    }
}