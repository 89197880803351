import React from "react";
import {ListGroup, Button, Alert, Jumbotron, Pagination, FormControl, Form} from "react-bootstrap";
import API from "../../utils/API";

var BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class ListProg extends React.Component {
    state = {
        progs: [],
        progsFiltered: [],
        messageSuccess: "",
        messageError: "",
        maxPerPage: 5,
        nbItemsPager: 4,
        pageNumber: 0,
        min: 0,
        max: 5
    };

    componentDidMount() {
        this.filterList();
        this.setPageNumber(this.state.pageNumber);
        let pageNumber=this.state.pageNumber;
        const loadProgs = async () => {
            var api = new BackofficeDansTousLesSens.ProgApi();
            try {
                var data = await api.getAllProgs();
                console.debug(data);
                if (data) {
                    data.progs.sort(function(a, b) {
                        const nameA = a.nom.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.nom.toUpperCase(); // ignore upper and lowercase
                        return nameA.localeCompare(nameB);
                    });
                    console.debug("progs", data.progs);
                    this.setState({progs: data.progs, progsFiltered: data.progs});
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
            this.filterList();
            this.setPageNumber(pageNumber);
        }
        loadProgs();
    }

    filterList = (event) => {
        let filterText = this.state.filterText;
        if (event) {
            this.setPageNumber(0);
            filterText = event.target.value.toLowerCase();
            this.setState({filterText: filterText});
        }
        let filteredItems = this.state.progs.filter(function (prog) {
            return prog.nom.toLowerCase().search(filterText) > -1;
        });

        console.debug(`filter : nb= ${filteredItems.length}`);
        this.setState({progsFiltered: filteredItems});
    }

    deleteProg = async (prog2Del) => {
        if (window.confirm(`Êtes vous sûr de vouloir supprimer le programmateur ${prog2Del.nom}?`)) {
            var api = new BackofficeDansTousLesSens.ProgApi();
            try {
                var result = await api.delProg(prog2Del.id)
                if (result.error) {
                    this.setState({messageError: "Impossible de supprimer le programmateur " + prog2Del.id});
                } else {
                    this.componentDidMount();
                    this.setState({messageSuccess: "Programmateur " + prog2Del.id + " supprimé"});
                    this.props.onSelect('');
                }
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.componentDidMount();
        }
    }

    setPageNumber = (pageNumber) => {
        if (pageNumber < 0) pageNumber = 0;
        let lastPage = Math.ceil(this.state.progsFiltered.length / this.state.maxPerPage);
        if (pageNumber>lastPage-1) pageNumber=lastPage-1;
        console.log(`lastPage : ${lastPage}`);
        console.log(`setPageNumber : ${pageNumber}`);
        let min = ((pageNumber * this.state.maxPerPage) > this.state.progsFiltered.length) ? 0 : (pageNumber * this.state.maxPerPage);
        let max = min + this.state.maxPerPage;

        this.setState({pageNumber: pageNumber, min: min, max: max});
    }

    getPageItems = () => {
        let pageItems = [];
        let minPage;
        console.debug("nb progs : ", this.state.progsFiltered.length);
        let nbMaxPage = Math.ceil(this.state.progsFiltered.length / this.state.maxPerPage);
        let halfItemsPager = Math.ceil(this.state.nbItemsPager / 2);
        if (nbMaxPage <= this.state.nbItemsPager || this.state.pageNumber < halfItemsPager || this.state.pageNumber > nbMaxPage) minPage = 0;
        else if (this.state.pageNumber > nbMaxPage - halfItemsPager) minPage = nbMaxPage - this.state.nbItemsPager;
        else minPage = this.state.pageNumber - halfItemsPager;
        let nbMaxItem = minPage + this.state.nbItemsPager;
        if (nbMaxPage < nbMaxItem) nbMaxItem = nbMaxPage;
        for (let i = minPage; i < nbMaxItem; i++) {
            pageItems.push(<Pagination.Item key={i} active={i === this.state.pageNumber}
                                            onClick={() => this.setPageNumber(i)}>{i + 1}</Pagination.Item>)
        }
        console.debug("nb pages : ", nbMaxPage);
        return pageItems;
    }


    render() {
        const {onSelect} = this.props;
        const {
            progsFiltered,
            messageError,
            messageSuccess,
            maxPerPage,
            min, max
        } = this.state;

        return (
            <div>
                <hr className="hr-1"></hr>
                <Jumbotron>
                    <h5>Liste des programmeurs</h5>
                </Jumbotron>
                {messageSuccess.length > 0 &&
                <Alert key={"successDel"} variant={"success"}>
                    {messageSuccess}
                </Alert>
                }
                {messageError.length > 0 &&
                <Alert key={"errorDel"} variant={"danger"}>
                    {messageError}
                </Alert>
                }
                <Form inline>
                    <FormControl type="text" placeholder="filter" className="mr-sm-2" onChange={this.filterList}/>
                </Form>
                {this.state.progsFiltered.length > maxPerPage &&
                <Pagination size={'sm'}>
                    <>
                        {this.state.progsFiltered.length / maxPerPage > this.state.nbItemsPager &&
                            <><Pagination.First onClick={() => this.setPageNumber(0)} />
                                <Pagination.Prev onClick={() => this.setPageNumber(this.state.pageNumber - 1)} />
                            </>
                        }
                        {this.state.progsFiltered.length > maxPerPage &&
                            this.getPageItems()
                        }
                        {this.state.progsFiltered.length / maxPerPage > this.state.nbItemsPager &&
                            <> <Pagination.Next onClick={() => this.setPageNumber(this.state.pageNumber + 1)}/>
                                <Pagination.Last onClick={() => this.setPageNumber(this.state.progsFiltered.length/maxPerPage)}/></>
                        }
                    </>
                </Pagination>
                }
                <ListGroup>
                    {
                        progsFiltered.slice(min, max).map(prog =>
                            <ListGroup.Item key={prog.id}>
                                <div className={'listAction'}>
                                    <Button className={'itemListMax'} onClick={() => {
                                        onSelect(prog)
                                    }}> &nbsp;&nbsp;&nbsp;{prog.nom}
                                    </Button>
                                    <Button variant="danger" size={"sm"} className={'deleteItem'} onClick={() => {
                                        this.deleteProg(prog)
                                    }}>X</Button>

                                </div>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </div>
        );
    }
}