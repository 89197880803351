import React from "react";
import {Form, Col, Row, Button, Alert, Jumbotron} from "react-bootstrap";
import {ListLieux} from "./ListLieux";
import {FormLieu} from "./FormLieu";
import API from "../../utils/API";

const BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');
const apiLieu = new BackofficeDansTousLesSens.LieuApi();

export class EditLieu extends React.Component {
    state = {
        lieu: undefined,
        success: "",
        error: "",
        submitDisabled: true,
        reload: false
    };

    lieu2Save = {};

    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.submitDisabled) return;
        let api = new BackofficeDansTousLesSens.LieuApi();
        console.log("lieu 2 save:",this.lieu2Save)

        let lieu = new BackofficeDansTousLesSens.Lieu();
        lieu.nom = this.lieu2Save.nom;
        lieu.adresse = this.lieu2Save.adresse ? this.lieu2Save.adresse : "";
        lieu.codePostal = this.lieu2Save.codePostal ? this.lieu2Save.codePostal : "";
        lieu.ville = this.lieu2Save.ville ? this.lieu2Save.ville : "";
        lieu.tel = this.lieu2Save.tel ? this.lieu2Save.tel : "";
        lieu.email = this.lieu2Save.email ? this.lieu2Save.email : "";
        lieu.site = this.lieu2Save.site ? this.lieu2Save.site : "";
        lieu.horaires = this.lieu2Save.horaires?this.lieu2Save.horaires:{};
        lieu.accessibilites = this.lieu2Save.accessibilites;
        lieu.trajetDescription = this.lieu2Save.trajetDescription ? this.lieu2Save.trajetDescription : "";
        lieu.presentation = this.lieu2Save.presentation ? this.lieu2Save.presentation : "";
        lieu.personneContact = this.lieu2Save.personneContact ? this.lieu2Save.personneContact : "";
        lieu.validation= {valid:this.lieu2Save.validation.valid};
        lieu.photos = []

        console.debug("update lieu:", lieu);

        const callbackSucessPhoto = async (data) => {
            console.debug('API photo called successfully. Returned data: ', this.state.id);
            api.getOneLieu(this.state.id).then(
                function (data) {
                    updateData("Lieu modifié avec succès!", '', data)
                },
                function (error) {
                    updateData({success: "Lieu modifié avec succès!", error: ''});
                }
            );
        }
        const updateData = async (message, error, data) => {
            console.debug(data.photos);
            if (data)
                this.setState({
                    success: message,
                    error: error,
                    image: 'data:image/jpeg;base64,' + this.arrayBufferToBase64(data.photo.buffer.data)
                });
            else {
                if (message)
                    this.setState({success: message, error: error,reload:true, submitDisabled:true});
                else
                    this.setState({success: message, error: error});
            }

        }


        const callbackSucess = async (data) => {
            console.debug('API  called successfully. Returned data: ', data);
            if (this.state.lieu.photo) {
                api.uploadPhoto(this.state.id, this.state.lieu.photo).then(
                    function (data) {
                        callbackSucessPhoto(data);
                    },
                    function (error) {
                        callbackError(error);
                    });
            } else {
                this.setState({success: "Lieu modifié avec succès!", error: "", reload: true, submitDisabled: true});
            }
        }

        const callbackError = async (data) => {
            if (data.body)
                this.setState({error: 'Impossible de modifier le lieu! : ' + data.body.message, success: ''});
            else
                this.setState({error: `Impossible de modifier le lieu! : ${data.error}`, success: ''});
            console.error(data);

        }

        console.debug("id:", this.lieu2Save.id, "lieu:", lieu)

        api.updateLieu(this.lieu2Save.id, lieu).then(
            function (data) {
                callbackSucess(data);
            },
            function (error) {
                callbackError(error);
            });
    };

    selectLieu = async (lieuLight) => {
        if (lieuLight) {
            try {
                const lieu = await apiLieu.getOneLieu(lieuLight.id);
                console.debug("lieu", lieu);
                this.lieu2Save = lieu;
                this.setState({
                    lieu: lieu,
                    success: '', error: ''
                });
            } catch (error) {
                console.error(error);
                if (error.message)
                    alert(error.message)
            }
        } else {
            this.setState({
                lieu: undefined,
                success: '', error: ''
            });
        }
    }

    changeCallback = function (data) {
        console.debug("Lieu 2save:", this.lieu2Save);
        this.lieu2Save = data;
        this.setState({submitDisabled: false})
        //this.setState({"lieu":data})
    }

    checkReload = () => {
        if (this.state.reload) {
            this.setState({reload: false});
            return true;
        } else return false;
    }

    componentDidMount() {
        API.addConfSecurity();
    }

    render() {
        const {
            success, error, lieu, submitDisabled
        } = this.state;
        return (

            <Row key={"editRow"}>
                <Col sm={3} key={"editCol"}>
                    <ListLieux key={"listEditLieux"} onSelect={this.selectLieu} reload={this.checkReload()}/>
                </Col>
                {lieu && <Col sm={9} key={"colEdit"}>
                    <hr className="hr-1"></hr>
                    <Jumbotron>
                        <h1 className="display-4 font-weight-normal">Modification d'un lieu</h1>
                    </Jumbotron>

                    {success.length > 0 &&
                    <Alert key={"danger"} variant={"success"}>
                        {success}
                    </Alert>
                    }
                    {error.length > 0 &&
                    <Alert key={"dangerError"} variant={"danger"}>
                        {error}
                    </Alert>
                    }
                    <Form onSubmit={this.handleSubmit} key={`form${lieu.id}`}>

                        <FormLieu key={`fl${lieu.id}`} data={lieu} onChange={this.changeCallback.bind(this)}
                                  as={"edit"}/>
                        <Button variant="primary" type="submit" disabled={submitDisabled}>
                            Mettra à jour le lieu
                        </Button>

                    </Form>

                </Col>
                }
            </Row>

        );
    }
}