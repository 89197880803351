import React from "react";

import {Navbar, Nav, NavDropdown, Form, FormControl, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import API from "../../utils/API";
import {Redirect} from "react-router";
import logo from "../../logo.png";

export class NavigationbarUser extends React.Component {
    state = {
        redirect: null
    };

    render() {
        const {
            redirect
        } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }


        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to="/" href="/">
                    <img
                        src={logo}
                        height="30"
                        className="d-inline-block align-top"
                        alt="Dans tous les sens"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {/*<NavDropdown.Item href="/user/edit">*/}
                        {/*    <Nav.Link as={Link} to="/user/edit">Mes utilisateurs</Nav.Link>*/}
                        {/*</NavDropdown.Item>*/}
                        <NavDropdown.Divider/>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/prog/edit">Ma fiche </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/lieu/edit">Mes Lieux</Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/spec/edit">Mes spectacles</Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/evt/edit">Mes évènements</Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/evt/add">Ajouter un évènement</Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/res/view">Ressources</Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Nav.Link as={Link} to="/catalogue">Catalogue</Nav.Link>
                        </NavDropdown.Item>
                    </Nav>
                    <Form inline>
                        <Button variant="outline-danger" onClick={(e) => {
                            API.logout();
                            this.setState({redirect: "#"});
                        }}>Déconnexion ({API.getUsername()}) </Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}