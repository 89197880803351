import React from "react";

import {Navbar, Nav, NavDropdown, Form, FormControl, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import API from "../../utils/API";
import {Redirect} from "react-router";
import logo from "../../logo.png";
let BackofficeDansTousLesSens = require('@singuliers/backoffice_dans_tous_les_sens');

export class Navigationbar extends React.Component {
    state = {
        redirect: null
    };

    render() {
        const {
            redirect
        } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }


        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to="/" href="/">
                    <img
                        src={logo}
                        height="30"
                        className="d-inline-block align-top"
                        alt="Dans tous les sens"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="Programmateurs" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/user/add">
                                <Nav.Link as={Link} to="/user/add">Créer un utilisateur</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/user/edit">
                                <Nav.Link as={Link} to="/user/edit">Modifier / supprimer un
                                    utilisateur</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item href="/prog/add">
                                <Nav.Link as={Link} to="/prog/add">Créer un Programmateur</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/prog/edit">
                                <Nav.Link as={Link} to="/prog/edit">Modifier/Supprimer un
                                    Programmateur</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Lieux" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/lieu/add">
                                <Nav.Link as={Link} to="/lieu/add">Ajouter un lieu</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/lieu/edit">
                                <Nav.Link as={Link} to="/lieu/edit">Modifier / supprimer un Lieu</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Nav.Link as={Link} to="/lieu/catalogue">Catalogue</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Spectacles" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/spec/add">
                                <Nav.Link as={Link} to="/spec/add">Ajouter un spectacle</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/spec/edit">
                                <Nav.Link as={Link} to="/spec/edit">Modifier / supprimer les
                                    spectacles</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Nav.Link as={Link} to="/spec/catalogue">Catalogue</Nav.Link>
                            </NavDropdown.Item>
                            {/*<NavDropdown.Item href="/spec/createAssistant">
                                <Nav.Link as={Link} to="/spec/createAssistant">Ajout assistant</Nav.Link>
                            </NavDropdown.Item>

                            <NavDropdown.Item href="#action/3.2">Valider les spectacles</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item href="#action/3.2">Ajouter un evènement</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Modifier / Supprimer un evènement</NavDropdown.Item>*/}
                        </NavDropdown>
                        <NavDropdown title="Évènements" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/evt/add">
                                <Nav.Link as={Link} to="/evt/add">Ajouter un évènement</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/evt/edit">
                                <Nav.Link as={Link} to="/evt/edit">Modifier /supprimer un évènement</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Ressources" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/res/add">
                                <Nav.Link as={Link} to="/res/add">Ajouter une ressource</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/res/edit">
                                <Nav.Link as={Link} to="/res/edit">Modifier /supprimer une ressource</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Form inline>
                        <Button variant="outline-success" onClick={(e) => {
                            API.addConfSecurity();
                            let deployerApi = new BackofficeDansTousLesSens.DeployerApi();
                            deployerApi.deployWeb().then(
                                function (data) {
                                    alert("Déploiement en cours ....")
                                    console.log("sucess!! ----->",data);
                                },
                                function (error) {
                                    console.error("Error!! ----->",error);
                                });
                        }}>Générer site</Button>
                    </Form>
                    <Form inline>
                        <Button variant="outline-danger" onClick={(e) => {
                            API.logout();
                            this.setState({redirect: "#"});
                        }}>Déconnexion ({API.getUsername()}) </Button>
                    </Form>
                    {
                        console.debug("user", API.getUsername())
                    }
                    {/*<Form inline>
                        <FormControl type="text" placeholder="Search" className="mr-sm-2"/>
                        <Button variant="outline-success">Search</Button>
                    </Form>*/}
                </Navbar.Collapse>
            </Navbar>
        )
    }
}